import React, {Component} from 'react';

class Errors extends Component {
    render() {
        return (this.props.errors && this.props.errors.length) ? (
            <div className="validation-errors">
                {this.props.errors.map(error => <div key={error} className="validation-error">- {error};</div>)}
            </div>
        ) : "";
    }
}
 
export default Errors;