import React, {Component} from 'react';
import arrowDown from "../../../../../markup/img/arrow-down.png";
import authApiService from "../../../../../services/authorizedApiHttpService";
import userProfileService from "../../../../../services/userProfileService";

class ProjectUser extends Component {

    state = {
        showRoleMenu: false,
        roles: {
            'owner': 1,
            'collaborator': 2,
            // 'external': 3,
            'remove': 4
        }
    }

    render() {
        return this.props.projectUser ?
            (
            <div>
                <div className="mt12 flex-space-between">
                    <div className="project-user-header">
                        {/*<img className="project-user-icon" src={profile2x} alt="User"/>*/}
                        <div className="text-align-center project-user-icon project-background-color word-break">
                            {this.props.projectUser?.user?.fullName.split(" ").map((n)=>n[0]).join(".")}
                        </div>
                        <span className="ml10 project-user-name">{this.props.projectUser?.user?.fullName}</span>
                        <span className="ml10 project-user-desc">{this.getProjectRoleName(this.props.projectUser?.user?.projectRoleId)}</span>
                    </div>
                    <div className="docs-sort-by no-select mr40" onClick={this.handleShowSortMenu}>
                        {this.getRoleName(this.props.projectUser?.roleId)}
                        {this.getRoleMenu()}
                        {this.props.roleId === this.state.roles.owner && !this.isCurrentUser() ?
                            (<img className="ml4" src={arrowDown} alt="Arrow"/>)
                            :
                            (<div></div>)
                        }
                    </div>
                </div>
            </div>
            )
            :
            (
            <div>
                <div className="mt12 flex-space-between">
                    <div className="project-user-header">
                        <div className="text-align-center project-user-icon project-background-color word-break">{this.props.projectPendingUser?.email.charAt(0).toUpperCase()}</div>
                        <span className="ml10 project-user-name">{this.props.projectPendingUser?.email}</span>
                        <span className="ml10 project-user-desc">(Pending)</span>
                    </div>
                    <div className="docs-sort-by no-select mr40">
                        {this.getRoleName(this.props.projectPendingUser?.roleId)}
                        {this.props.roleId === this.state.roles.owner ?
                            (<span className="ml24 cancel-approve header-btn no-select no-outline grey-border share-btn" onClick={e => this.cancelPendingRequest(this.props.projectPendingUser)}>
                                <span className="title">Cancel</span>
                            </span>)
                            :
                            (<div></div>)
                        }
                    </div>
                </div>
            </div>
            );
    }

    isCurrentUser = () => {
        return this.props.projectUser.user.id === userProfileService.profile().id;
    }

    handleShowSortMenu = () => {
        this.setState({showRoleMenu: !this.state.showRoleMenu});
    }

    getProjectRoleName = (projectRoleId) => {
        switch (projectRoleId) {
            case 1: return '(PM)';
            case 2: return '(Designer)';
            case 3: return '(Developer)';
            case 4: return '(Client)';
            default: return '';
        }
    }

    getRoleName = (roleId) => {
        switch (roleId) {
            case 1: return 'Owner';
            case 2: return 'Collaborator';
            // case 3: return 'External';
            default: return '';
        }
    }

    getRoleMenu = () => {
        return this.state.showRoleMenu && this.props.roleId === this.state.roles.owner && !this.isCurrentUser() ?
            (<div className="doc-sort-menu">
                {/*<button type="button" className="doc-details-context-menu-item btn" onClick={e => this.handleRoleChange(this.state.roles.owner)}>*/}
                    {/*<span className="doc-add-menu-text">Owner</span>*/}
                {/*</button>*/}
                <button type="button" className="doc-details-context-menu-item btn" onClick={e => this.handleRoleChange(this.state.roles.collaborator)}>
                    <span className="doc-add-menu-text">Collaborator</span>
                </button>
                <button type="button" className="doc-details-context-menu-item-remove btn" onClick={e => this.handleRoleChange(this.state.roles.remove)}>
                    <span className="doc-add-menu-text">Remove</span>
                </button>
            </div>)
            :
            ("")
    }

    handleRoleChange = (role) => {
        if (role === this.state.roles.remove) {
            authApiService.delete(`/project/${this.props.projectUser.projectId}/user/${this.props.projectUser.user.id}`,
                (headers) => {
                    this.props.projectUsersUpdated();
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        } else {
            authApiService.put(`/project/${this.props.projectUser.projectId}/user/${this.props.projectUser.user.id}/role/${role}`, null,
                (role, headers) => {
                    this.props.projectUsersUpdated();
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        }
    }

    cancelPendingRequest = (projectPendingUser) => {
        authApiService.delete(`/invite/${projectPendingUser.id}/cancel`,
            (role, headers) => {
                this.props.projectUsersUpdated();
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }
}

export default ProjectUser;