import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './services/historyService';
import PrivateRoute from './routing/privateRoute';

import HomePage from './components/page/homePage';
import SignInPage from './components/page/signInPage';
import SignUpPage from './components/page/signUpPage';
import ForgotPasswordPage from './components/page/forgotPasswordPage';
import ResetPasswordPage from './components/page/resetPasswordPage';
import ProjectsPage from './components/page/projectsPage';
import DashboardPage from './components/page/dashboardPage';
import ApprovePage from './components/page/approvePage';
import ProjectBuilderPage from './components/page/builderPage';
import ProjectViewPage from './components/page/projectViewPage';
import ProfilePage from './components/page/profilePage';
import ForbiddenPage from './components/page/forbiddenPage';
import NotFoundPage from './components/page/notFoundPage';
import ServerErrorPage from './components/page/serverErrorPage';
import { Provider } from 'react-redux';
import store from './models/store/store';
import mixpanel from './services/mixpanelService';
import { withRouter } from 'react-router-dom';
import TaskBoardPage from "./components/page/taskBoardPage";

class App extends Component {

  render() {
    mixpanel.track("load.page", { "uri": this.props.location.pathname });

    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/signin" component={SignInPage} />
            <Route exact path="/signup" component={SignUpPage} />
            <Route exact path="/forgot-password" component={ForgotPasswordPage} />
            <Route path="/reset-password" component={ResetPasswordPage} />
            <PrivateRoute exact path="/projects" component={DashboardPage} />
            <PrivateRoute exact path="/dashboard" component={DashboardPage} />
            <PrivateRoute exact path="/dashboard/:projectId" component={DashboardPage} />
            <Route exact path="/approve/:inviteId" component={ApprovePage} />
            <PrivateRoute exact path="/builder/project/:projectId" component={ProjectBuilderPage} />
            <Route exact path="/project/:projectId" component={ProjectViewPage} />
            <PrivateRoute exact path="/dashboard/board/:projectId" component={TaskBoardPage} />
            <PrivateRoute path="/profile" component={ProfilePage} />
            <PrivateRoute exact path="/access-forbidden" component={ForbiddenPage} />
            <Route exact path="/server-error" component={ServerErrorPage} />
            <Route exact path="/not-found" component={NotFoundPage} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Router>
      </Provider>
    );
  }

}

export default withRouter(App);