class LinksCategory {

    constructor(id = "links", title = "Important Links", description = "This section contains all cloud tools used during the project lifetime applied for storage, communication, providing feedback, references or other purposes", groups = []) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.groups = groups;
    }
}

export default LinksCategory;