import React, { Component } from 'react';
import Layout from '../layout/login/layout';
import SignUpForm from '../layout/login/form/signUp';

class SignUpPage extends Component {

    render() {
        return (
            <Layout pageName="signUp">
                <SignUpForm />
            </Layout>
        );
    }
}

export default SignUpPage;