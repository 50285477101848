import React, { Component } from 'react';

class CreateProjectButton extends Component {
    render() {
        return (
            <button type="button" onClick={this.props.onClickCreateProject} className="btn btn-primary btn-basic my-2 my-sm-0" data-toggle="modal" data-target="#create-project-modal">Create project</button>
        );
    }
}
 
export default CreateProjectButton;