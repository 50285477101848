import LinksCategory from './linksCategory';
import FilesCategory from './filesCategory';

class Project {

    constructor(id = null, name = null, description = null, status = null, privacy = null, timeCreated = null, links = new LinksCategory(), files = new FilesCategory()) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.status = status;
        this.privacy = privacy;
        this.timeCreated = timeCreated;
        this.links = links;
        this.files = files;
    }
}

export default Project;