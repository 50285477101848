import React, { Component } from 'react';
import authApiService from "../../../../../services/authorizedApiHttpService";
import subscriptionService from "../../../../../services/subscriptionService";
import Info from "../../../shared/popup/info";

class ProfileSubscription extends Component {

    state = {
        downgradeInfoText: '',
        showDowngradeInfo: false,
    }

    render() {
        return (
            <div className="profile-settings">
                <div className="profile-account-actions mb20">
                    <div onClick={e => this.handlePlanChange(1)} className={this.getPlanClasses(1)}>
                        <div className="w-150">
                            <div className="profile-subscription-text-grey">Starter</div>
                            <div className="profile-subscription-price">Free</div>
                        </div>
                        <div>
                            <div className="profile-subscription-text">Create up to 3 projects.</div>
                            <div className="profile-subscription-text">Add up to 3 team members.</div>
                            <div className="profile-subscription-text">Add unlimited amount of tasks.</div>
                            <div className="profile-subscription-text">Unlimited chat messages.</div>
                            <div className="profile-subscription-text">Storage limit: 1 GB</div>
                        </div>
                    </div>

                    <div onClick={e => this.handlePlanChange(2)} className={this.getPlanClasses(2)}>
                        <div className="w-150">
                            <div className="profile-subscription-text-grey">Lite</div>
                            <div>
                                <span className="profile-subscription-price">$12</span>
                                <span className="profile-subscription-text">/mo</span>
                            </div>
                        </div>
                        <div>
                            <div className="profile-subscription-text">Create up to 15 projects.</div>
                            <div className="profile-subscription-text">Add up to 30 team members.</div>
                            <div className="profile-subscription-text">Add unlimited amount of tasks.</div>
                            <div className="profile-subscription-text">Unlimited chat messages.</div>
                            <div className="profile-subscription-text">Storage limit: 50 GB</div>
                        </div>
                    </div>

                    <div onClick={e => this.handlePlanChange(3)} className={this.getPlanClasses(3)}>
                        <div className="w-150">
                            <div className="profile-subscription-text-grey">Pro</div>
                            <div>
                                <span className="profile-subscription-price">$30</span>
                                <span className="profile-subscription-text">/mo</span>
                            </div>
                        </div>
                        <div>
                            <div className="profile-subscription-text">Create unlimited projects.</div>
                            <div className="profile-subscription-text">Add unlimited members.</div>
                            <div className="profile-subscription-text">Add unlimited amount of tasks.</div>
                            <div className="profile-subscription-text">Unlimited chat messages.</div>
                            <div className="profile-subscription-text">Storage limit: 150 GB</div>
                        </div>
                    </div>
                </div>

                <Info show={this.state.showDowngradeInfo}
                      title="Can not downgrade subscription"
                      description={this.state.downgradeInfoText}
                      onOk={this.onInfoClose}
                      onClose={this.onInfoClose}
                      okButtonTitle="Ok" />
            </div>
        );
    }

    onInfoClose = () => {
        this.setState({showDowngradeInfo: false});
    }

    handlePlanChange = (plan) => {
        if (this.props.subscriptionData?.priceIndex !== plan) {
            if (this.props.subscriptionData?.priceIndex === 1 && plan !== 1) {
                this.createNewSubscription(plan);
            } else {
                if (this.props.subscriptionData?.priceIndex > plan) {
                    let downgradeInfoText = null;
                    if (!subscriptionService.canDowngradeSubscriptionProject(this.props.subscriptionData, plan)) {
                        downgradeInfoText = 'You have reached the maximum number of projects allowed for new subscription plan. Please remove some of them to downgrade.';
                    } else if (!subscriptionService.canDowngradeSubscriptionMember(this.props.subscriptionData, plan)) {
                        downgradeInfoText = 'You have reached the maximum number of members allowed for new subscription plan. Please remove some of them to downgrade.';
                    } else if (!subscriptionService.canDowngradeSubscriptionStorage(this.props.subscriptionData, plan)) {
                        downgradeInfoText = 'You have reached the maximum storage size allowed for new subscription plan. Please remove some of the files to downgrade.';
                    }

                    if (downgradeInfoText === null) {
                        this.updateSubscription(plan);
                    } else {
                        this.setState({downgradeInfoText: downgradeInfoText, showDowngradeInfo: true});
                    }
                } else {
                    this.updateSubscription(plan);
                }
            }
        }
    }

    createNewSubscription = (plan) => {
        authApiService.post(`/stripe/create-checkout-session/${plan}`, null,
            (url, headers) => {
                window.location.replace(url)
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }

    updateSubscription = (plan) => {
        authApiService.post(`/stripe/update-subscription/${plan}`, null,
            (data, headers) => {
                this.props.handleSubscriptionChange();
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }

    getPlanClasses = (plan) => {
        return "profile-box profile-text mt8 flex-start " + (this.props.subscriptionData?.priceIndex === plan ? "profile-subscription-active" : "cursor-pointer");
    }
}

export default ProfileSubscription;