import React, { Component } from 'react';
import Sheet from './sheet/sheet';

class ProjectContent extends Component {
    render() {
        return (
            <div className="project-content view">
                <Sheet project={this.props.project}
                    readOnly={this.props.readOnly}
                    onClickAddElement={this.props.onClickAddElement}
                    onLinkEdit={this.props.onLinkEdit}
                    onLinkDelete={this.props.onLinkDelete}
                    onSubCategotyDelete={this.props.onSubCategotyDelete}
                    onFileUpdate={this.props.onFileUpdate}
                    onFileDelete={this.props.onFileDelete}
                    showFilesCategory={this.props.showFilesCategory}
                    showLinksCategory={this.props.showLinksCategory} />
            </div>
        );
    }
}

export default ProjectContent;