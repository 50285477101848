import React, { Component } from 'react';
import down2x from '../../../../../../../markup/img/down@2x.png';
import down3x from '../../../../../../../markup/img/down@3x.png';
import up2x from '../../../../../../../markup/img/up@2x.png';
import up3x from '../../../../../../../markup/img/up@3x.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { ButtonGroup } from 'react-bootstrap';
import SearchBy from '../../../../../../../models/searchBy';

class SortByFilter extends Component {
    
    state = {
        options: [
            { id: SearchBy.creationDate, title: "Creation date" },
            { id: SearchBy.name, title: "Name" },
            { id: SearchBy.status, title: "Status" }
        ],
        selectedId: SearchBy.creationDate
    }

    render() {
        const RoleToggle = React.forwardRef(({children, onClick}, ref) => (
            <div ref={ref} onClick={e => { onClick(e); }}>
              {children}
            </div>
        ));

        return (
            <div className="nav-filter">
                <span className="nav-filter-title no-select">Sort by:</span>
                <ButtonGroup>
                    <Dropdown className="sub-menu project-status-dropdown">
                        <Dropdown.Toggle as={RoleToggle}>
                            <span className="nav-filter-value no-select">{this.getSelectedOptionTitle()}</span>
                            <div className="nav-filter-dropdown-icon-wrapper">
                                <img className="dropdown-img-down" src={down2x} srcSet={`${down2x} 2x, ${down3x} 3x`} width="24" height="24" alt="Down"/>
                                <img className="dropdown-img-up hidden" src={up2x} srcSet={`${up2x} 2x, ${up3x} 3x`} width="24" height="24" alt="Up"/>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.state.options.map((option) => {
                                return <Dropdown.Item
                                        href="/#" 
                                        key={option.id} 
                                        onClick={(e) => this.handleOptionChange(e, option.id)}
                                        className={'dropdown-item no-select ' + (this.isSelectedOption(option.id) ? 'active' : '')}> 
                                        {option.title}
                                    </Dropdown.Item>
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </ButtonGroup>
            </div>
        );
    }

    isSelectedOption = (optionId) => {
        return optionId === this.state.selectedId;
    }

    getSelectedOptionTitle() {
        return this.state.options.filter(option => this.isSelectedOption(option.id)).shift().title;
    }

    handleOptionChange = (e, optionId) => {
        e.preventDefault();
        let {optoins} = this.state;
        this.setState({optoins: optoins, selectedId: optionId});
        this.props.onOptionChange(optionId);
    }
}
 
export default SortByFilter;