import React, { Component } from 'react';
import logo2x from "../../markup/img/logo@2x.png";
import { Link } from 'react-router-dom';
import mixpanel from '../../services/mixpanelService';
import userProfileService from '../../services/userProfileService';

class HomePage extends Component {

    componentDidMount() {
        mixpanel.track("load.landing.page");
    }

    getPage = () => {
        if (userProfileService.isAuthenticated()) {
            window.location.replace("/dashboard");
        } else {
            window.location.replace("https://try.pantram.co/");
        }

        return "";
    }

    render() {
        return this.getPage();
        /*
        return (
            <div className="home-page-layout-wrapper">
                <div className="header home">
                    <Link to="/">
                        <img src={logo2x} loading="lazy" width="138" alt="" className="logo" />
                    </Link>
                    {this.signHeaderButtons()}
                </div>

                <div className="section">
                    <div className="content home w-container">
                        <h1 className="h1">
                            <strong className="h1">Happier clients.<br /></strong>
                            <span className="gradient">
                                <strong className="h1">Fewer hustles.</strong>
                            </span>
                        </h1>
                        <p className="d1">Deliver all mission-critical project files and links to your clients in one place.</p>
                    </div>
                    {this.signUpCentralButton()}
                </div>

                <div className="section image w-clearfix">
                    <img src="./img/Macbook_Pro_01-p-2001.png" loading="lazy"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 92vw, (max-width: 3065px) 81vw, 2483px"
                        srcSet="./img/Macbook_Pro_01-p-500.png 500w, ./img/Macbook_Pro_01-p-800.png 800w, ./img/Macbook_Pro_01-p-1080.png 1080w, ./img/Macbook_Pro_01-p-1600.png 1600w, ./img/Macbook_Pro_01-p-2000.png 2000w, ./img/Macbook_Pro_01-p-2001.png 2483w"
                        alt="" className="image" />
                </div>

                <div className="section w-clearfix">
                    <h2 className="head-2">
                        <strong className="h2">What can you do<br />with Pantram?</strong>
                    </h2>
                    <div className="content home space w-container">
                        <div className="w-layout-grid grid">
                            <div id="w-node-b188b8a1697e-34b42f94" className="feature">
                                <img src="./img/uploads.svg" loading="lazy" alt="" className="icon" />
                                <h3 className="h3">Uploads</h3>
                                <p className="p3">Let your client know when you need a file or link as reference from
                                him.</p>
                            </div>
                            <div id="w-node-a0cf17f7f3ae-34b42f94" className="feature">
                                <img src="./img/feedback.svg" loading="lazy" alt="" className="icon" />
                                <h3 className="h3">Feedback</h3>
                                <p className="p3">Ask clients to give you feedback on specific files or links.</p>
                            </div>
                            <div id="w-node-18905d2fcdeb-34b42f94" className="feature"><img src="./img/approvals.svg" loading="lazy"
                                alt="" className="icon" />
                                <h3 className="h3">Approvals</h3>
                                <p className="p3">Ask for approval on assets from any tool or site.</p>
                            </div>
                            <div id="w-node-07520ec416fe-34b42f94" className="feature">
                                <img src="./img/collaboration.svg"
                                    loading="lazy" alt="" className="icon" />
                                <h3 className="h3">Collaboration</h3>
                                <p className="p3">Pantram encourages clients to have the right amount of involvement on
                                the project.</p>
                            </div>
                            <div id="w-node-db8f502485e7-34b42f94" className="feature">
                                <img src="./img/project-page.svg" loading="lazy" alt="" className="icon" />
                                <h3 className="h3">Project Page</h3>
                                <p className="p3">A dedicated site for the project to have everything centralised and in
                                reach.</p>
                            </div>
                            <div id="w-node-c088fc103755-34b42f94" className="feature">
                                <img src="./img/project-status.svg" loading="lazy" alt="" className="icon" />
                                <h3 className="h3">Project Status</h3>
                                <p className="p3">Let the team easily track where projects are standing.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div className="content w-container">
                        <h2 className="head-2"><strong className="h2">Consolidate<br />all your resources</strong></h2>
                        <p className="d2">Use the tools you and your client already use, making onboarding as easy as it
                        can get with no learning curve.</p>
                    </div>
                    <div className="w-layout-grid icons-grid">
                        <div id="w-node-399eea78c947-34b42f94" className="animated-icon"><img src="./img/figma.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-b271925ce262-34b42f94" className="animated-icon"><img src="./img/word.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-7a4b45b6a1a8-34b42f94" className="animated-icon"><img src="./img/notion.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-f844fd06de7a-34b42f94" className="animated-icon"><img src="./img/invision.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-7a9ec61cc19d-34b42f94" className="animated-icon"><img src="./img/coda.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-9ef902f3fb2a-34b42f94" className="animated-icon"><img src="./img/adobe-xd.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-25308b1d1937-34b42f94" className="animated-icon"><img src="./img/calendly.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-df59f303fda0-34b42f94" className="animated-icon"><img src="./img/miro.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-bea0ae69f5b3-34b42f94" className="animated-icon"><img src="./img/coda.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-9b88b4e0e1bb-34b42f94" className="animated-icon"><img src="./img/airtable.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-3ce60c910603-34b42f94" className="animated-icon"><img src="./img/dropbox.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-2075320d235e-34b42f94" className="animated-icon"><img src="./img/slack.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-1803e8800720-34b42f94" className="animated-icon"><img src="./img/trello.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-e24194249222-34b42f94" className="animated-icon"><img src="./img/google-sheet.svg"
                            loading="lazy" alt="" /></div>
                        <div id="w-node-fef6cfec83e6-34b42f94" className="animated-icon"><img src="./img/pdf.svg" loading="lazy" alt="" />
                        </div>
                        <div id="w-node-917881993f85-34b42f94" className="animated-icon"><img src="./img/icloud.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-96ed18ee1abf-34b42f94" className="animated-icon"><img src="./img/box.svg" loading="lazy" alt="" />
                        </div>
                        <div id="w-node-bb22f61ac187-34b42f94" className="animated-icon"><img src="./img/confluence.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-040a0a60e129-34b42f94" className="animated-icon"><img src="./img/adobe-illustrator.svg"
                            loading="lazy" alt="" /></div>
                        <div id="w-node-4c96543b781c-34b42f94" className="animated-icon"><img src="./img/onedrive.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-5cc59a9b41a1-34b42f94" className="animated-icon"><img src="./img/pinterest.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-570c39a562a6-34b42f94" className="animated-icon"><img src="./img/adobe-photoshop.svg"
                            loading="lazy" alt="" /></div>
                        <div id="w-node-1f539c498d8e-34b42f94" className="animated-icon"><img src="./img/excel.svg" loading="lazy"
                            alt="" /></div>
                        <div id="w-node-4178dea46a75-34b42f94" className="animated-icon"><img src="./img/powerpoint.svg" loading="lazy"
                            alt="" /></div>
                    </div>
                </div>

                <div className="section horizontal">
                    <div className="banner w-container">
                        <div className="div-block-2">
                            <h2 className="h2">Try Pantram</h2>
                            <p className="p2"></p>
                            {this.signBottomButtons()}
                        </div>

                        <img src="./img/Artboard.png" loading="lazy"
                            sizes="(max-width: 767px) 100vw, (max-width: 991px) 364px, 470px"
                            srcSet="./img/Artboard-p-500.png 500w, ./img/Artboard-p-800.png 800w, ./img/Artboard-p-1080.png 1080w, ./img/Artboard.png 1280w"
                            alt="" className="banner-image" />
                    </div>
                </div>

                <div className="footer home">
                    <div className="container-2 w-container"><img src="./img/pantram_logo.svg" loading="lazy" alt=""
                        className="logo-footer" />
                        <div className="p3">© 2021 Pantram. All rights reserved.</div>
                    </div>
                </div>
            </div>
        );*/
    }

    signHeaderButtons = () => {
        return !userProfileService.isAuthenticated()
            ? <div>
                <Link to="/signup" className="button secondary w-button home-sign-btn">Sign up for Free</Link>
                <Link to="/signin" className="button secondary w-button home-sign-btn">Sign In</Link>
            </div>
            : <Link to="/projects" className="button secondary w-button home-sign-btn">Go to my projects</Link>;
    }

    signBottomButtons = () => {
        return !userProfileService.isAuthenticated()
            ? <div>
                <Link to="/signup" className="button white w-button home-sign-btn">Sign up for Free</Link>
                <Link to="/signin" className="button white w-button home-sign-btn">Sign In</Link>
            </div>
            : <Link to="/projects" className="button white w-button home-sign-btn">Go to my projects</Link>;
    }

    signUpCentralButton = () => {
        return !userProfileService.isAuthenticated()
            ? <div>
                <Link to="/signup" className="button secondary blue w-button home-sign-btn">Sign up for Free</Link>
            </div>
            : <Link to="/projects" className="button secondary blue w-button home-sign-btn">Go to my projects</Link>;
    }
}

export default HomePage;