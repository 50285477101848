import React, { Component } from 'react';
import Confirmation from '../../../../../shared/popup/confirmation';
import deleteIcon2x from '../../../../../../../markup/img/icon-delete-big@2x.png';
import deleteIcon3x from '../../../../../../../markup/img/icon-delete-big@3x.png';

class ProjectDeletePopup extends Component {
    render() {
        return (
            <Confirmation show={this.props.show} 
                title={this.title()} 
                description={this.description()} 
                icon2x={deleteIcon2x} 
                icon3x={deleteIcon3x} 
                onConfirm={this.handleOnConfirm}
                onCancel={this.props.onCancel}
                onClose={this.props.onClose}
                attention={true} 
                confirmButtonTitle={'Delete project'} 
                cancelButtonTitle={'Cancel'}/>
        );
    }

    title = () => {
        return `Delete project "${this.props.project.name}"`;
    }

    description = () => {
        return 'Are you sure you want to delete this project?';
    }

    handleOnConfirm = (e) => {
        this.props.onConfirm(this.props.project);
    }
}
 
export default ProjectDeletePopup;