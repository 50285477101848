import React, { Component } from 'react';
import Header from './header/header'
import { withRouter } from 'react-router-dom';
import Footer from './footer/footer';
import authApiService from "../../../services/authorizedApiHttpService";

class Layout extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="login-page-wrapper">
                    <Header pageName={this.props.pageName} onSignButtonClick={this.onSignButtonClick} />
                    <div className="login-page-content-wrap">
                        <div className="login-page-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }

    onSignButtonClick = (pageName) => {
        this.props.history.push(((pageName === "signIn") ? "/signup" : "/signin") + this.getParams());
    }

    getParams = () => {
        if (this.props.location.search?.startsWith('?inviteId=')) {
            const inviteId = this.props.location.search.substring("?inviteId=".length).trim();
            return `?inviteId=${inviteId}`;
        } else if (this.props.location.search?.startsWith('?subscriptionId=')) {
            const subscriptionId = this.props.location.search.substring("?subscriptionId=".length).trim();
            return `?subscriptionId=${subscriptionId}`;
        } else {
            return '';
        }
    }
}

export default withRouter(Layout);