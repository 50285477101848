import React, { Component } from 'react';
import DocComment from "./docComment";
import authApiService from "../../../../../services/authorizedApiHttpService";
import commentSend from "../../../../../markup/img/comment-send.png";
import {produce} from "immer";

class DocComments extends Component {

    constructor() {
        super();
        this.textarea = React.createRef();
        // this.timer = setInterval(function () {
        //     this.setState(this.state);
        // }.bind(this), 3000);
    }

    state = {
        comments: [],
        form: {
            comment: ""
        },
    }

    render() {
        return (
            <div className="doc-comments-sidebar mr20">
                <div className="dashboard-docs-text">
                    Comments
                </div>
                <div className="doc-comments">
                    <div>
                    {this.state.comments.map(comment => (
                        <DocComment key={comment.id} comment={comment} projectId={this.props.doc.projectId} commentDeleted={this.commentDeleted}/>
                    ))}
                    </div>
                </div>
                <div className="doc-comment-input">
                    <textarea className="doc-comment-textarea"
                              maxLength="1024"
                              data-gramm_editor="false"
                              spellCheck="false"
                              ref={this.textarea}
                              onChange={e => this.handleCommentTextChange(e.target.value)}
                              readOnly={false}></textarea>
                    <img className="doc-comment-send-btn"
                         onClick={this.handleSendButtonClick}
                         src={commentSend}
                         width="20" height="20" alt="Send" />
                </div>
            </div>
        );
    }

    async componentDidMount() {
        authApiService.get(`/project/${this.props.doc.projectId}/file/${this.props.doc.id}/comments`,
            (comments, headers) => {
                this.setState({comments: comments});
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }

    handleCommentTextChange = (comment) => {
        this.setState(produce(this.state, draftState => {
            draftState.form.comment = comment;
        }));
    }

    handleSendButtonClick = (e) => {
        if (!this.state.form.comment) return;

        authApiService.post(`/project/${this.props.doc.projectId}/file/${this.props.doc.id}/comment`, this.state.form,
            (comment, headers) => {
                let comments = [...this.state.comments];
                comments.push(comment);
                this.setState({comments: comments})

                this.clear();
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }

    clear = () => {
        this.setState(produce(this.state, draftState => {
            draftState.form.comment = "";
        }));
        this.textarea.current.value = "";
    }

    commentDeleted = (comment) => {
        let comments = [...this.state.comments];
        comments = comments.filter(item => item.id !== comment.id);

        this.setState({comments: comments})
    }
}

export default DocComments;