import React, {Component} from 'react';

class Wrapper extends Component {

    render() {
        return (
            <div className={this.props.className ? this.props.className + " form-field-wrapper" : "form-field-wrapper"}>
                {this.props.children}
            </div>
        );
    }

}

export default Wrapper;