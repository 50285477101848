import React, { Component } from 'react';
import ProjectViewLayout from '../layout/view/projectViewLayout';
import ProjectContent from '../layout/view/content/projectContent';
import Ghosts from '../layout/view/ghosts/ghosts';
import Project from '../../models/project/project';
import Status from '../../models/project/status';
import LinksCategory from '../../models/project/linksCategory';
import FilesCategory from '../../models/project/filesCategory';
import authApiService from '../../services/authorizedApiHttpService';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import LinkPopup from '../layout/main/project/builder/popup/link/link';
import GroupDeletePopup from '../layout/main/project/builder/popup/confirmation/groupDeletePopup';
import FilePopup from '../layout/main/project/builder/popup/file/filePopup';
import LinkState from '../../models/link/link';
import { actionTypesFetched, actionStatusesFetched } from '../../models/store/enums/enums';
import { profileFetched } from '../../models/store/profile/profile';
import { projectFetched } from '../../models/store/projects/projects';
import { actionsFetched, actionUpdated } from '../../models/store/actions/actions';
import { connect } from 'react-redux';
import userProfileService from '../../services/userProfileService';
import CreateActionPopup from '../layout/main/project/builder/popup/action/CreateActionPopup';
import EditActionPopup from '../layout/main/project/builder/popup/action/EditActionPopup';
import DetailsActionPopup from '../layout/main/project/builder/popup/action/DetailsActionPopup';
import LinkDetailsPopup from '../layout/main/project/builder/popup/action/LinkDetailsPopup';

class ProjectViewPage extends Component {

    constructor() {
        super();
        this.loading = true;
        this.currentLink = new LinkState();
        this.linkGroupToDelete = { id: 0, name: '', links: [] };
        this.fileGroupToDelete = { id: 0, name: '', files: [] };
    }

    state = {
        showLinkPopup: false,
        showDeleteLinkGroupPopup: false,
        showDeleteFileGroupPopup: false,
        showFilePopup: false,
        showFilesCategory: true,
        showLinksCategory: true,
        projectLogo: null,
        project: new Project()
    }

    async componentDidMount() {
        let config = { headers: authApiService.authHeaders() };

        const [projectResponse, projectLogoResponse, projectSettingResponse, actionTypesResponse, actionStatusesResponse, projectLinkGroupsResponse, projectFileGroupsResponse, actionsResponse] = await Promise.all([
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}`, config).catch(e => e),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/logo`, config).catch(e => e),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/setting`, config).catch(e => e),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/action/types`, config).catch(e => e),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/action/statuses`, config).catch(e => e),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/links/grouped`, config).catch(e => e),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/files/grouped`, config).catch(e => e),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/actions`, config).catch(e => e)
        ]);

        if ((projectResponse instanceof Error) && projectResponse.message.includes("401")) {
            if (this.props.location.search === "?s=u") {
                this.props.history.push("/signup");
            } else {
                this.props.history.push("/signin");
            }
        } else {
            this.props.actionTypesFetched(actionTypesResponse.data.data);
            this.props.actionStatusesFetched(actionStatusesResponse.data.data);
            this.props.actionsFetched(actionsResponse.data.data);

            if (projectLogoResponse.data.data?.signedUrl) {
                this.setState({projectLogo: projectLogoResponse.data.data.signedUrl})
            }

            this.setState({
                showFilesCategory: projectSettingResponse.data.data?.showFiles === false ? false : true,
                showLinksCategory: projectSettingResponse.data.data?.showLinks === false ? false : true
            });

            let fileApproveActions = {}
            let fileApproves = {}
            actionsResponse.data.data.forEach(action => {
                if (action?.file?.id && action?.typeId === 1) {
                    fileApproveActions[action.file.id] = true;
                    if (action.statusId === 1) {
                        if (!fileApproves[action.file.id]) {
                            fileApproves[action.file.id] = [];
                        }
                        fileApproves[action.file.id].push(action);
                    }
                }
            });

            let linksCategory = new LinksCategory();
            linksCategory.groups = projectLinkGroupsResponse.data.data;
            let linkGroups = [];
            linksCategory.groups.map(group => linkGroups.push({id: group.id, name: group.name}));
            linksCategory.groups.map(group =>
                group.links.forEach(link => {
                    link.group = {id: group.id, name: group.name}
                    link.groups = linkGroups;
                })
            );

            let filesCategory = new FilesCategory();
            filesCategory.groups = projectFileGroupsResponse.data.data;
            filesCategory.groups.map(group =>
                group.files.forEach(file => {
                    file.isApproved = fileApproveActions[file.id] && (!fileApproves[file.id] || fileApproves[file.id].length === 0);
                    if (fileApproves[file.id]) {
                        file.approves = fileApproves[file.id];
                    }
                })
            );

            const state = { ...this.state };
            state.project = new Project(
                projectResponse.data.data.id,
                projectResponse.data.data.name,
                projectResponse.data.data.description,
                new Status(projectResponse.data.data.status.id, projectResponse.data.data.status.status),
                projectResponse.data.data.privacy,
                projectResponse.data.data.timeCreated,
                linksCategory,
                filesCategory
            );

            this.props.projectFetched({
                id: projectResponse.data.data.id,
                name: projectResponse.data.data.name,
                description: projectResponse.data.data.description,
                status: {
                    id: projectResponse.data.data.status.id,
                    status: projectResponse.data.data.status.status
                },
                ownerId: projectResponse.data.data.ownerId,
                privacy: projectResponse.data.data.privacy,
                timeCreated: projectResponse.data.data.timeCreated
            });

            this.loading = false;
            this.setState(state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <ProjectViewLayout project={this.state.project} projectLogo={this.state.projectLogo} readOnly={this.isReadOnly()} >
                    {this.content()}
                </ProjectViewLayout>
                <LinkPopup show={this.state.showLinkPopup} link={this.currentLink} onClose={this.handleLinkPopupClose} onAddLink={this.handleOnAddLink} onEditLink={this.handleOnEditLink} />
                <GroupDeletePopup show={this.state.showDeleteLinkGroupPopup} group={this.linkGroupToDelete} onConfirm={this.handleLinkGroupDeleteConfirm} onCancel={this.handleLinkGroupDeleteCancel} onClose={this.handleLinkGroupDeleteClose} />
                <GroupDeletePopup show={this.state.showDeleteFileGroupPopup} group={this.fileGroupToDelete} onConfirm={this.handleFileGroupDeleteConfirm} onCancel={this.handleFileGroupDeleteCancel} onClose={this.handleFileGroupDeleteClose} />
                <FilePopup show={this.state.showFilePopup} project={this.state.project} onClose={this.handleCloseFilesPopup} onAddFiles={this.handleAddFiles} />
                <CreateActionPopup onActionCreate={this.onActionCreate} />
                <EditActionPopup />
                <DetailsActionPopup project={this.state.project} onAddFiles={this.handleAddFiles} onApprove={this.handleApprove} onCommentsUpdated={this.onCommentsUpdated} onFileGroupChange={this.onFileGroupChange} />
                <LinkDetailsPopup project={this.state.project} onCommentsUpdated={this.onCommentsUpdated} onLinkGroupChange={this.onLinkGroupChange} />
                {this.authorizationPopup()}
            </React.Fragment>
        );
    }

    content = () => {
        return this.loading
            ? <Ghosts />
            : <ProjectContent
                project={this.state.project}
                readOnly={this.isReadOnly()}
                onClickAddElement={this.handleClickAddElement}
                onLinkEdit={this.handleLinkEdit}
                onLinkDelete={this.handleLinkDelete}
                onSubCategotyDelete={this.handleLinkGroupDelete}
                onFileUpdate={this.handleFileUpdate}
                onFileDelete={this.handleFileDelete}
                showFilesCategory={this.state.showFilesCategory}
                showLinksCategory={this.state.showLinksCategory} />;
    }

    authorizationPopup = () => {
        return "";
    }

    handleClickAddElement = (e, categoryId) => {
        switch (categoryId) {
            case 'links': this.showLinkPopup(true); break;
            case 'files': this.showFilePopup(true); break;
            default: ;
        }
    }

    handleOnAddLink = (link, group) => {
        this.showLinkPopup(false);
        let state = { ...this.state };
        let foundGroups = state.project.links.groups.filter(g => g.id === link.groupId);
        if (foundGroups.length) {
            foundGroups.shift().links.push(link);
        } else {
            group.links.push(link);
            state.project.links.groups.push(group);
        }
        this.setState(state);
    }

    handleOnEditLink = (link, group) => {
        this.showLinkPopup(false);
        let state = { ...this.state };

        /* Find the current link group */
        let oldGroup = state.project.links.groups.filter(g => g.links.filter(l => l.id === link.id).length).shift();
        if (oldGroup.id === link.groupId) {
            let oldLink = oldGroup.links.filter(l => l.id === link.id).shift();
            Object.assign(oldLink, link);
        } else {
            /* Remove link from old group */
            oldGroup.links = oldGroup.links.filter(l => l.id !== link.id);
            /* Check new link such group exists */
            let foundGroups = state.project.links.groups.filter(g => g.id === link.groupId);
            if (foundGroups.length) {
                /* Add link to different group */
                foundGroups.shift().links.push(link);
            } else {
                /* Add link to new group, add group to group list */
                group.links.push(link);
                state.project.links.groups.push(group);
            }
        }

        this.setState(state);
    }

    handleLinkPopupClose = (e) => {
        this.showLinkPopup(false);
    }

    /* Link group popup */
    handleLinkGroupDelete = (linkGroup) => {
        this.linkGroupToDelete = linkGroup;
        this.showDeleteLinkGroupPopup(true);
    }

    handleLinkGroupDeleteConfirm = (linkGroup) => {
        authApiService.delete(`/project/${this.props.match.params.projectId}/link/group/${linkGroup.id}`,
            (data, headers) => {
                const state = { ...this.state };
                let filteredGroups = state.project.links.groups.filter(g => g.id !== linkGroup.id);
                state.project.links.groups = filteredGroups;
                this.setState(state);
                this.showDeleteLinkGroupPopup(false);
            },
            (message, errors) => {
                /* Show popup with error */
                this.showDeleteLinkGroupPopup(false);
            });
    }

    handleLinkGroupDeleteCancel = (e) => {
        this.showDeleteLinkGroupPopup(false);
    }

    handleLinkGroupDeleteClose = (e) => {
        this.showDeleteLinkGroupPopup(false);
    }

    handleFilePopupClose = (e) => {
        this.showFilePopup(false);
    }

    handleFileGroupDelete = (fileGroup) => {
        this.fileGroupToDelete = fileGroup;
        this.showDeleteFileGroupPopup(true);
    }

    handleFileGroupDeleteConfirm = (fileGroup) => {
        authApiService.delete(`/project/${this.props.match.params.projectId}/file/group/${fileGroup.id}`,
            (data, headers) => {
                const state = { ...this.state };
                let filteredGroups = state.project.files.groups.filter(g => g.id !== fileGroup.id);
                state.project.files.groups = filteredGroups;
                this.setState(state);
                this.showDeleteFileGroupPopup(false);
            },
            (message, errors) => {
                /* Show popup with error */
                this.showDeleteFileGroupPopup(false);
            });
    }

    handleFileGroupDeleteCancel = (e) => {
        this.showDeleteFileGroupPopup(false);
    }

    handleFileGroupDeleteClose = (e) => {
        this.showDeleteFileGroupPopup(false);
    }

    handleLinkEdit = (link, group) => {
        this.currentLink.clear();
        this.currentLink.form.id = link.id;
        this.currentLink.form.name = link.name;
        this.currentLink.form.url = link.url;
        this.currentLink.form.group.id = group.id;
        this.currentLink.form.group.name = group.name;
        this.currentLink.form.description = link.description;
        this.showLinkPopup(true);
    }

    handleLinkDelete = (link, group) => {
        authApiService.delete('/project/' + this.props.match.params.projectId + '/link/' + link.id,
            (data, headers) => {
                let state = { ...this.state };
                group.links = group.links.filter(l => l.id !== link.id);
                this.setState(state);
            },
            (message, errors) => {
                /* Show popup with error */
            });
    }

    handleFileUpdate = (file) => {

    }

    handleFileDelete = (file) => {
        authApiService.delete('/project/' + this.props.match.params.projectId + '/file/' + file.id,
            (data) => {
                const state = { ...this.state };
                state.project.files.groups.forEach(group => {
                    if (group.id === file.group.id) {
                        group.files = group.files.filter(f => !(f.id === file.id));
                    }
                });
                this.setState(state);
            },
            (errors) => {
                /* Show popup with error */
            });
    }

    handleApprove = (action) => {

        if (action?.file?.id) {
            let state = { ...this.state };

            this.props.actionUpdated(action);

            let index = this.getGroupAndFileIndex(state, action.file);
            if (index.groupId !== undefined) {
                let file = state.project.files.groups[index.groupId].files[index.fileId];
                file = this.newFile(file, true, []);
                state.project.files.groups[index.groupId].files[index.fileId] = file;
            }

            this.setState(state);
        }
    }

    onCommentsUpdated = (file) => {

        if (file?.hasUnreadComments) {
            const state = { ...this.state };

            let index = this.getGroupAndFileIndex(state, file);

            if (index.groupId !== undefined) {
                let f = state.project.files.groups[index.groupId].files[index.fileId];

                f = this.newFile(file, file.isApproved, file.approves);
                f.hasUnreadComments = false;

                state.project.files.groups[index.groupId].files[index.fileId] = f;
            }

            this.setState(state);
        }
    }

    onLinkGroupChange = (link, group) => {
        const state = { ...this.state };

        let index = this.getGroupAndLinkIndex(state, link);

        if (index.groupId !== undefined) {
            let l = state.project.links.groups[index.groupId].links[index.linkId];
            l = this.newLink(l);
            l.description = link.description;

            let groupId = link.group ? link.group.id : -1;
            let linkGroupId = state.project.links.groups.map(group => group.id).indexOf(groupId);
            if (index.groupId === linkGroupId) {
                state.project.links.groups[index.groupId].links[index.linkId] = l;
            } else if (state.project.links.groups[linkGroupId]) {
                state.project.links.groups[index.groupId].links.splice(index.linkId, 1);
                state.project.links.groups[linkGroupId].links.push(l);
            } else if (group?.id) {
                state.project.links.groups[index.groupId].links.splice(index.linkId, 1);
                group.links = [];
                group.links.push(l);
                state.project.links.groups.push(group);
            }
        }

        this.setState(state);
    }

    onFileGroupChange = (file, group) => {
        const state = { ...this.state };

        let index = this.getGroupAndFileIndex(state, file);

        if (index.groupId !== undefined) {
            let f = state.project.files.groups[index.groupId].files[index.fileId];
            f = this.newFile(f, f.isApproved, f.approves);
            f.description = file.description;

            let groupId = file.group ? file.group.id : -1;
            let fileGroupId = state.project.files.groups.map(group => group.id).indexOf(groupId);

            if (index.groupId === fileGroupId) {
                state.project.files.groups[index.groupId].files[index.fileId] = f;
            } else if (state.project.files.groups[fileGroupId]) {
                state.project.files.groups[index.groupId].files.splice(index.fileId, 1);
                state.project.files.groups[fileGroupId].files.push(f);
            } else if (group?.id) {
                state.project.files.groups[index.groupId].files.splice(index.fileId, 1);
                group.files = [];
                group.files.push(f);
                state.project.files.groups.push(group);
            }
        }

        this.setState(state);
    }

    handleAddFiles = (projectFilesGroup, fileGroup) => {
        this.showFilePopup(false);
        const state = { ...this.state };
        if (fileGroup) {
            state.project.files.groups.forEach(group => {
                if (group.id === fileGroup.id) {
                    let index = group.files.map(file => file.id).indexOf(fileGroup.files[0].id);
                    group.files[index] = fileGroup.files[0];
                }
            });
        } else {
            let index = state.project.files.groups.map(group => group.id).indexOf(projectFilesGroup.id);
            if (index >= 0) {
                projectFilesGroup.files.forEach(file => state.project.files.groups[index].files.push(file) );
            } else {
                state.project.files.groups.push(projectFilesGroup);
            }
        }
        this.setState(state);
    }

    handleCloseFilesPopup = (e) => {
        this.showFilePopup(false);
    }

    showLinkPopup(show) {
        let state = { ...this.state };
        state.showLinkPopup = show;
        this.setState(state);
    }

    showFilePopup(show) {
        let state = { ...this.state };
        state.showFilePopup = show;
        this.setState(state);
    }

    showDeleteLinkGroupPopup(show) {
        let state = { ...this.state };
        state.showDeleteLinkGroupPopup = show;
        this.setState(state);
    }

    showDeleteFileGroupPopup(show) {
        let state = { ...this.state };
        state.showDeleteFileGroupPopup = show;
        this.setState(state);
    }

    isReadOnly() {
        if (this.state.project.id) {
            return this.state.project.privacy.name === 'Public' && !userProfileService.isAuthenticated();
        } else {
            return true;
        }
    }

    newFile(file, isApproved, approves) {
        return {
            id: file.id,
            name: file.name,
            description: file.description,
            group: file.group,
            signedUrlId: file.signedUrlId,
            size: file.size,
            url: file.url,
            timeFromCreated: file.timeFromCreated,
            hasUnreadComments: file.hasUnreadComments,
            isApproved: isApproved,
            approves: approves
        };
    }

    newLink(link) {
        return {
            id: link.id,
            name: link.name,
            url: link.url,
            description: link.description,
            group: link.group,
            groups: link.groups,
            timeCreated: link.timeCreated,
            hasUnreadComments: link.hasUnreadComments,
        };
    }

    getGroupAndFileIndex(state, file) {
        let fileId;
        let groupId;
        state.project.files.groups.forEach(function (group, i) {
            group.files.forEach(function (f, j) {
                if (f.id === file?.id) {
                    groupId = i;
                    fileId = j;
                }
            })
        });

        return {
            groupId: groupId,
            fileId: fileId
        };
    }

    getGroupAndLinkIndex(state, link) {
        let linkId;
        let groupId;
        state.project.links.groups.forEach(function (group, i) {
            group.links.forEach(function (l, j) {
                if (l.id === link?.id) {
                    groupId = i;
                    linkId = j;
                }
            })
        });

        return {
            groupId: groupId,
            linkId: linkId
        };
    }

    onActionCreate = (action) => {
        const state = { ...this.state };

        let index = this.getGroupAndFileIndex(state, action.file);

        if (index.groupId !== undefined) {
            let file = state.project.files.groups[index.groupId].files[index.fileId];
            let approves = file.approves ? {...file.approves} : [];

            file = this.newFile(file, false, []);
            file.approves.push(action);
            file.approves = file.approves.concat(approves);

            state.project.files.groups[index.groupId].files[index.fileId] = file;
        }

        this.setState(state);
    }
}

export default connect(
    (state) => ({
        projects: state.entities.projects
    }),
    (dispatch) => ({
        profileFetched: (profile) => dispatch(profileFetched(profile)),
        actionTypesFetched: (actionTypes) => dispatch(actionTypesFetched(actionTypes)),
        actionStatusesFetched: (actionStatuses) => dispatch(actionStatusesFetched(actionStatuses)),
        projectFetched: (project) => dispatch(projectFetched(project)),
        actionUpdated: (action) => dispatch(actionUpdated(action)),
        actionsFetched: (actions) => dispatch(actionsFetched(actions))
    })
)(withRouter(ProjectViewPage));