import React, { Component } from 'react';
import { connect } from 'react-redux';
import { profileFetched } from '../../../../../models/store/profile/profile';
import userProfileService from '../../../../../services/userProfileService';
import profileLogo from '../../../../../markup/img/user@2x.png';
import profileLogoActive from '../../../../../markup/img/user-active@2x.png';
import LogOutLogo from '../../../../../markup/img/logout@2x.png';
import LogOutLogoActive from '../../../../../markup/img/logout-active@2x.png';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import DropdownToggle from "react-bootstrap/DropdownToggle";

class ProfileImage extends Component {
    constructor() {
        super();
        this.profileImg = React.createRef();
        this.logOutImg = React.createRef();
    }

    render() {
        const RoleToggle = React.forwardRef(({ children, onClick }, ref) => (
            <div className="profile-img-wrapper" style={{cursor: "pointer"}}>
                <span className="profile-img" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
                    {userProfileService.userInitials(this.props.profile.fullName)}
                </span>
            </div>
        ));

        return (
            <div>
                <ButtonGroup>
                    <Dropdown className="sub-menu no-outline no-select">
                        <DropdownToggle as={RoleToggle} />
                        <Dropdown.Menu className="profile-image-dropdown-menu">
                            <Dropdown.Item className="no-select" href="/profile" key={"profile"}
                                           onMouseOver={ () => this.profileImg.current.src = profileLogoActive}
                                           onMouseOut={ () => this.profileImg.current.src = profileLogo}
                                           >
                                <div >
                                    <img src={profileLogo} ref={this.profileImg} height="25" width="25" alt="Profile Logo"/>
                                    <span style={{marginLeft:"10px"}}>View Profile</span>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Item className="no-select" href="/signin" key={"logOut"}
                                           onMouseOver={ () => this.logOutImg.current.src = LogOutLogoActive}
                                           onMouseOut={ () => this.logOutImg.current.src = LogOutLogo}
                                           onClick={this.handleLogoutButtonClick}>
                                <div >
                                    <img src={LogOutLogo} ref={this.logOutImg} height="25" width="25" alt="Logout Logo"/>
                                    <span style={{marginLeft:"10px"}}>LogOut</span>
                                </div>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </ButtonGroup>
            </div>
        );
    }
    handleLogoutButtonClick = () => {
        userProfileService.removeAuthToken();
    }
}

export default connect(
    (state) => ({
        profile: state.entities.profile
    }),
    (dispatch) => ({
        profileFetched: (profile) => dispatch(profileFetched(profile))
    })
)(ProfileImage);