import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Wrapper from './wrapper';
import Title from './title/title';
import EmailField from './field/email';
import Footer from './footer/footer';
import CancelButton from './button/cancel';
import Button from './button/action';
import apiService from '../../../../services/apiHttpService';
import EmailSentPopup from "../../main/profile/popup/emailSentPopup";

class ForgotPassword extends Component {

    constructor() {
        super();
        this.showEmailSentPopup = false;
    }

    state = {
        form: {
            email: ""
        },
        errors: {
            form: {
                email: []
            }
        }
    }

    render() {
        return (
            <Wrapper>
                <Title title="Forgot password"/>
                <EmailField onChange={this.handleEditEmail} errors={this.state.errors.form.email}/>
                <Footer>
                    <CancelButton onCancel={this.handleCancel}/>
                    <Button className="action" title="Reset password" onPress={this.handleChangePassword}/>
                </Footer>
                <EmailSentPopup show={this.showEmailSentPopup} onCancel={this.handleEmailSentPopupCancel} onClose={this.handleEmailSentPopupClose} />
            </Wrapper>
        );
    }

    handleCancel = (e) => {
        this.props.history.push("/signin");
    }

    handleEmailSentPopupCancel = (e) => {
        this.setShowEmailSentPopup(false);
    }

    handleEmailSentPopupClose = (e) => {
        this.setShowEmailSentPopup(false);
    }

    handlePasswordResetEmailSent = () => {
        this.setShowEmailSentPopup(true);
    }

    setShowEmailSentPopup = (show) => {
        this.showEmailSentPopup = show;
        this.forceUpdate();
    }

    handleChangePassword = (e) => {
        apiService.post("/user/password", this.state.form,
            (data, headers) => {
                this.handlePasswordResetEmailSent();
            },
            (message, errors) => {
                const {form, errors: formErrors} = this.state;
                formErrors.form = errors;
                formErrors.summary = (Object.keys(errors).length > 0) ? "" : message;
                this.setState({form: form, errors: formErrors});
            });
    }

    handleEditEmail = (email) => {
        const {form, errors} = this.state;
        form.email = email;
        errors.form.email = [];
        this.setState({form: form, errors: errors});
    }

}

export default withRouter(ForgotPassword);