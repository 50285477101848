import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import CloseButton from '../button/close';
import imgCheck2x from '../../../../../../../markup/img/icon-check@2x.png';
import imgCheck3x from '../../../../../../../markup/img/icon-check@3x.png';
import imgError2x from '../../../../../../../markup/img/icon-error@2x.png';
import imgError3x from '../../../../../../../markup/img/icon-error@3x.png';
import urlService from '../../../../../../../services/urlService';

class Slug extends Component {

    render() {
        return (
            <Modal show={this.props.show}>
                <div className="modal-content customize">
                    <Modal.Header>
                        <h5 className="modal-title">Customize project link</h5>
                        <CloseButton onClose={this.props.onClose}/>
                    </Modal.Header>
                    
                    <Modal.Body className="flex-direction-column">
                        <label className="sub-title" for="project-link-input">Project link</label>
                        
                        <div className="project-link-input-wrapper border-error">
                            <table className="project-link-input-tbl">
                                <tr>
                                    <td className="td-project-base-url">
                                        <span className="project-base-url">{urlService.baseUrl()}</span>
                                    </td>

                                    <td className="td-project-link-input">
                                        <input id="project-link-input" className="project-link no-outline" type="text" value={this.props.project.slug}/>
                                    </td>

                                    <td className="td-validation-icon">
                                        <img className="validation-icon check" src={imgCheck2x} srcSet={`${imgCheck2x} 2x, ${imgCheck3x} 3x`} width="24" height="24" alt="OK"/>
                                        <img className="validation-icon error" src={imgError2x} srcSet={`${imgError2x} 2x, ${imgError3x} 3x`} width="24" height="24" alt="Error"/>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className="validation-errors">
                            <div className="validation-error">This domain name is already in use. Please, try another one.</div>
                        </div>
                    </Modal.Body>
                    
                    <Modal.Footer className="form-footer flex-start">
                        <button type="button" className="btn btn-outline-grey" data-dismiss="modal" onClick={this.props.onClose}>Cancel</button>
                        <button type="button" className="btn">Save changes</button>
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }
}

export default Slug;