import axios from 'axios';

const apiURI = process.env.REACT_APP_API_BASE_URL;
const contentHeaders = { 'Content-Type': 'application/json' };

async function get(uri, fData, fErrors) {
    try {
        const response = await axios.get(apiURI + uri, { headers: contentHeaders });
        fData(response.data.data, response.headers);
    } catch (ex) {
        handleErrors(ex, fErrors);
    }
}

async function post(uri, data, fData, fErrors) {
    try {
        const response = await axios.post(apiURI + uri, data, { headers: contentHeaders });
        fData(response.data.data, response.headers);
    } catch (ex) {
        handleErrors(ex, fErrors);
    }
}

async function put(uri, data, fData, fErrors) {
    try {
        const response = await axios.put(apiURI + uri, data, { headers: contentHeaders });
        fData(response.data.data, response.headers);
    } catch (ex) {
        handleErrors(ex, fErrors);
    }
}

async function del(uri, fData, fErrors) {
    try {
        const response = await axios.delete(apiURI + uri, { headers: contentHeaders });
        fData(response.data.data, response.headers);
    } catch (ex) {
        handleErrors(ex, fErrors);
    }
}

function handleErrors(ex, fErrors) {
    if (axios.isCancel(ex)) return;
    const response = ex.response;

    /* Server responded with some status */
    if (response) {
        switch (response.status) {
            case 400: {
                const { message, errors } = response.data.error;
                const validationErorrs = {};
                if (errors && errors.length > 0) {
                    errors.map(e => {
                        let fieldErrors = validationErorrs[e.name];
                        if (!fieldErrors)
                            fieldErrors = validationErorrs[e.name] = [];
                        fieldErrors.push(e.message);
                        return e;
                    });
                }
                fErrors(message, validationErorrs);
            } break;
            default: {
                fErrors("Something went wrong, please contact support: hello@pantram.co", {});
            }
        }
    }
    /* No response from server */
    else {
        fErrors("Pantram servers unavailable, please try again later, or contact support: hello@pantram.co", []);
    }
}

export default {
    get,
    post,
    put,
    delete: del
}