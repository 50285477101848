import React, { Component } from 'react';
import img2x from '../../../../../../markup/img/left-arrow@2x.png';
import img3x from '../../../../../../markup/img/left-arrow@3x.png';
// import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

class Header extends Component {

    render() {
        return (
            <div className="header">
                <Link to="/projects" className="header-element-wrapper">
                    <img className="left-arrow-img" src={img2x} srcSet={`${img2x} 2x, ${img3x} 3x`} width="24" height="24" alt="Arrow"/>
                    <span className="title">Projects</span>
                </Link>
            </div>
        );
    }
}
 
export default Header;