import React, { Component } from 'react';
import pantramLogo from "../../../../markup/img/pantram-logo.png";
import {StreamChat} from "stream-chat";

class ProjectItem extends Component {

    state = {
        isActive: false,
        hasUnreadMessages: false
    }

    async componentDidMount() {

        if (this.props.isActive) {
            this.props.onProjectClick(this);
        }

        // TODO: CHAT
        const chatData = this.props.chatData;
        const chatClient = StreamChat.getInstance(chatData.key);

        const channel = chatClient.channel('team', this.props.project.id, {
            name: this.props.project.name
        });

        await channel.watch();

        channel.on(event => {
            if (event.type === 'message.new' && !this.state.isActive) {
                this.setState({hasUnreadMessages: true});
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className={this.itemClasses()} onClick={this.handleClick}>
                    {this.getLogo()}
                    <div className="dashboard-project-text">
                        <div className="dashboard-project-title dashboard-project-line-text">{this.props.project.name}</div>
                        <div className="dashboard-project-message dashboard-project-line-text">{this.props.project.description}</div>
                    </div>
                    <div className={this.getNotificationClass()}></div>
                </div>
            </React.Fragment>
        );
    }

    getLogo = () => {
        return (this.props.project.iconName || this.props.project.color ?
                <div className="dashboard-project-icon-wrapper" style={{backgroundColor: this.props.project.color}}>
                    {this.props.project.iconName ?
                    <img className="dashboard-project-icon" src={`./img/projecticons/${this.props.project.iconName}`} alt=""/>
                    :
                    <div></div>}
                </div>
                :
                <img className="dashboard-project-icon" src={pantramLogo} alt=""/>
        );
    }

    itemClasses = () => {
        return "dashboard-project-item no-select" +
            (this.state.isActive ? " dashboard-project-active" : "");
    }

    getNotificationClass = () => {
        return ((this.props.hasNotification || this.state.hasUnreadMessages) && !this.state.isActive ? "dashboard-project-item-notification" : "");
    }

    handleClick = (e) => {
        this.props.onProjectClick(this)
    }

    setActive = (isActive) => {
        this.setState({isActive: isActive, hasUnreadMessages: false});
    }
}

export default ProjectItem;