class FilesCategory {

    constructor(id = "files", title = "Attachments", description = "Project files to be shared with collaborators. This section should contain the most recent versions of project files", files = [], groups = []) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.files = files;
        this.groups = groups;
    }
}

export default FilesCategory;