import React, { Component } from 'react';
import ProjectItem from "./projectItem";
import SearchInput from "../project/list/navigation/search/searchInput";

class ProjectList extends Component {

    state = {
        activeProject: null
    }

    render() {
        return (
            <div className="dashboard-projects">
                <SearchInput onSearch={this.props.onSearch} onSearchTextChange={this.props.onSearchTextChange}/>
                { this.props.projects.map(project => (
                    <ProjectItem
                        key={project.id}
                        project={project}
                        chatData={this.props.chatData}
                        onProjectClick={this.onProjectClick}
                        isActive={project.id === this.props.projectId}
                        hasNotification={this.hasNotification(project)}
                    />
                ))
                }
            </div>
        );
    }

    hasNotification = (project) => {
        for (let i in this.props.notifications) {
            if (this.props.notifications[i].projectId === project.id) {
                return true;
            }
        }

        return false;
    }

    onProjectClick = (projectItem) => {
        if (this.activeProject) {
            this.activeProject.setActive(false);
        }
        this.activeProject = projectItem;
        this.activeProject.setActive(true);

        this.props.onProjectSelect(projectItem.props.project);
    }
}

export default ProjectList;