import React, { Component } from 'react';
import backArrow from "../../../../../markup/img/back-arrow.png";
import fileService from "../../../../../services/fileService";
import iconDownload from "../../../../../markup/img/icon-download.svg";
import iconLink from "../../../../../markup/img/icon-link.png";
import iconUpload from "../../../../../markup/img/icon-upload@2x.png";
import dotsBalack from "../../../../../markup/img/dots-black.png";
import FilePopup from "../../project/builder/popup/file/filePopup";
import authApiService from "../../../../../services/authorizedApiHttpService";
import UpdatableText from "../UpdatableText";
import DocPopup from "./docPopup";
import DropdownMenu from "../dropdownMenu";

class DocDetails extends Component {

    state = {
        showFilePopup: false,
        showDocDetailsMenu: false,
        page: null,
        pages: [],
        showFilePreview: false,
        items: [
            { key: 'create_task', name: 'Create task' },
            { key: 'approve', name: 'Ask for approve' },
            { key: 'delete', name: 'Delete', isAlert: true }
        ]
    }

    async componentDidMount() {
        if (this.props.doc.type === 'FILE') {
            this.fetchDocPreview();
        }
    }

    render() {
        return (
            <div className="doc-details">
                <div className="mr40">
                    <img className="cursor-pointer no-select" onClick={this.props.handleBackToDocs} src={backArrow} alt="Docs list icon"/>
                </div>
                <div className="mt20 mr40 dashboard-docs-text-grey">
                    Documents > {this.props.doc.type === 'FILE' ? 'File' : 'Link'}
                </div>
                <div className="mt20">
                    {this.props.isExternal ? "" : (
                        <div className="mr40 float-right no-select cursor-pointer" onClick={this.showDocDetailsMenu}>
                            <img className="cursor-pointer no-select" onClick={this.showDocDetailsMenu} src={dotsBalack} alt="Docs list icon"/>
                            <DropdownMenu show={this.state.showDocDetailsMenu} items={this.state.items} onItemClick={this.handleDetailsMenu} classNames="doc-sort-menu doc-details-menu" />
                        </div>
                    )}
                    <div className="mr40 doc-details-img no-select">
                        {
                            this.state.page ?
                                <img onClick={this.handleFilePreviewClick} className="cursor-pointer doc-details-img no-select" src={`data:image/jpeg;base64,${this.state.page}`} alt="Document" /> :
                                <img className="doc-details-img no-select" src={this.props.getIcon(this.props.doc)} alt="Document"/>
                        }
                    </div>
                    {this.getFileBtns()}
                    <div className="mr40 mt16 dashboard-docs-text">
                        {this.props.doc.type === 'FILE' ?
                            this.props.doc.name
                            :
                            (<UpdatableText text={this.props.doc.name} onTextUpdate={this.onNameUpdate} />)
                        }
                    </div>
                    <div className="mr40 mt12 dashboard-docs-text-grey">
                        {this.props.getKind(this.props.doc)}
                    </div>
                    <div className="mr40 mt32 dashboard-docs-text">
                        Information
                    </div>
                    <div className="mr40 mt16">
                        <span className="mt12 dashboard-docs-text-grey">Updated</span>
                        <span className="float-right">{this.props.getDate(this.props.doc.timeUpdated)}</span>
                    </div>
                    <div className="mt16 dashboard-line-h"></div>
                    <div className="mr40 mt32 dashboard-docs-text">Description</div>
                    <div className="mr40 mt16 dashboard-docs-text-grey">
                        <UpdatableText text={this.props.doc.description} onTextUpdate={this.onDescriptionUpdate} />
                    </div>
                    <DocPopup show={this.state.showFilePreview} pages={this.state.pages} onClose={this.handleFilePreviewClose} />
                </div>
                <br/>
            </div>
        );
    }

    handleFilePreviewClick = () => {
        this.setState({showFilePreview: true})
    }

    handleFilePreviewClose = () => {
        this.setState({showFilePreview: false})
    }

    onDescriptionUpdate = (updatableText, description) => {
        const data = {
            id: this.props.doc.id,
            docType: this.props.doc.type,
            description: description
        };

        authApiService.put(`/project/${this.props.doc.projectId}/doc`, data,
            (headers) => {
                updatableText.onTextUpdate(description);
            },
            (message, errors) => {
                /* Show error popup */
            },
            (cancelToken) => { });
    }

    onNameUpdate = (updatableText, name) => {
        const data = {
            id: this.props.doc.id,
            docType: this.props.doc.type,
            name: name
        };

        authApiService.put(`/project/${this.props.doc.projectId}/doc`, data,
            (headers) => {
                updatableText.onTextUpdate(name);
            },
            (message, errors) => {
                /* Show error popup */
            },
            (cancelToken) => { });
    }

    showDocDetailsMenu = () => {
        this.setState({showDocDetailsMenu: !this.state.showDocDetailsMenu});
    }

    handleDetailsMenu = (action) => {
        if (action === 'create_task') {

        } else if (action === 'external') {
            const data = {
                id: this.props.doc.id,
                docType: this.props.doc.type,
                external: true
            };

            authApiService.put(`/project/${this.props.doc.projectId}/doc/external`, data,
                (headers) => {
                },
                (message, errors) => {
                    /* Show error popup */
                },
                (cancelToken) => { });
        } else if (action === 'approve') {

        } else if (action === 'delete') {
            authApiService.delete(`/project/${this.props.doc.projectId}/doc/${this.props.doc.id}/${this.props.doc.type}`,
                (headers) => {
                    this.props.docDeleted(this.props.doc.id);
                },
                (message, errors) => {
                    /* Show error popup */
                },
                (cancelToken) => { });
        }

        this.setState({showDocDetailsMenu: false});
    }

    getFileBtns = () => {
        return this.props.doc.type === 'LINK' ?
            (<div className="file-detail-button-group mt16">
                <button type="button" className="btn file-detail-button" onClick={e => this.openInNewTab(this.props.doc.url)}>
                    <img className="icon mr8" src={iconLink} width="20" height="20" alt="Download"/>
                    <span className="title">Go to link</span>
                </button>
            </div>)
            :
            (<div className="file-detail-button-group mt16">
                <button type="button" className="btn file-detail-button" onClick={this.handleDownload}>
                    <img className="icon mr8" src={iconDownload} width="20" height="20" alt="Download"/>
                    <span className="title">Download</span>
                </button>
                {
                    this.props.isExternal ? "" : (
                        <button type="button" className="btn btn-outline-grey file-detail-button" onClick={this.handleUpload}>
                            <img className="icon mr8" src={iconUpload} width="20" height="20" alt="Upload"/>
                            <span className="title">Replace</span>
                        </button>
                    )
                }
                <FilePopup fileId={this.props.doc.id} show={this.state.showFilePopup} project={this.props.project} onClose={this.handleCloseFilesPopup} onAddFiles={this.handleAddFiles} />
            </div>)
    }

    openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    handleDownload = (e) => {
        authApiService.get(`/project/${this.props.doc.projectId}/file/${this.props.doc.awsS3Key}`,
            (download, headers) => {
                fileService.downloadFile(download.signedUrl, download.fileName);
            },
            (message, errors) => {
                /* Show error popup with some message */
            },
            (cancelToken) => { });
    }

    handleUpload = (e) => {
        this.setState({showFilePopup: true});
    }

    handleCloseFilesPopup = (e) => {
        this.setState({showFilePopup: false});
    }

    handleAddFiles = (projectFilesGroup, fileGroup) => {
        this.setState({showFilePopup: false});
        // TODO:
        // this.props.onAddFiles(projectFilesGroup, fileGroup);
    }

    fetchDocPreview = () => {
        authApiService.get(`/project/${this.props.doc.projectId}/doc/${this.props.doc.id}`,
            (pages, headers) => {
                if (pages?.length > 0) {
                    this.setState({page: pages[0], pages: pages});
                }
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }
}

export default DocDetails;