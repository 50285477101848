import React, { Component } from 'react';
import Layout from '../layout/login/layout';
import SigInForm from "../layout/login/form/signIn";


class SignInPage extends Component {

    render() {
        return (
            <Layout pageName="signIn">
                <SigInForm />
            </Layout>
        );
    }
}

export default SignInPage;