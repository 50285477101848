import React, {Component} from 'react';
import userProfileService from "../../services/userProfileService";
import authApiService from "../../services/authorizedApiHttpService";

class ApprovePage extends Component {

    async componentDidMount() {
        if (userProfileService.isAuthenticated()) {
            authApiService.post(`/invite/${this.props.match.params.inviteId}/approve`,
                (response, headers) => {
                    this.props.history.push('/dashboard');
                },
                (message, errors) => {
                    this.props.history.push('/dashboard');
                });
        } else {
            this.props.history.push(`/signin?inviteId=${this.props.match.params.inviteId}`);
        }
    }

    render() {
        return (
            <div>
            </div>
        );
    }

}

export default ApprovePage;