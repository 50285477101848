import React, { Component } from 'react';

class ProjectIconColor extends Component {

    state = {
        isActive: false,
        imgUrl: `../../../../../../../markup/img/projecticons/${this.props.icon}`
    }

    render() {
        return (
            <div className={this.getClasses()}>
                <img className="project-icon no-select" src={`./img/projecticons/${this.props.icon}`} onClick={this.handleClick} loading="lazy" alt="" />
            </div>
        );
    }

    getClasses = () => {
        return "project-icon-div" +
            (this.state.isActive ? " project-icon-active" : "");
    }

    handleClick = (e) => {
        this.props.onIconClick(this)
    }

    setActive = (isActive) => {
        this.setState({isActive: isActive});
    }
}

export default ProjectIconColor;