import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import DropdownToogle from  'react-bootstrap/DropdownToggle';
import DropdownMenu from  'react-bootstrap/DropdownMenu';

class Menu extends Component {

    render() {
        const RoleToogle = React.forwardRef(({ children, onClick }, ref) => (
            <span className="header-btn no-select no-outline grey-border sub-menu-btn" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
                {children}
            </span>
        ));

        return (
            <ButtonGroup>
                <Dropdown className="sub-menu no-outline no-select">
                    <DropdownToogle as={RoleToogle}>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </DropdownToogle>
                    <DropdownMenu className="dropdown-menu-right">
                        <Dropdown.Item className="no-select" href="#" onClick={e => this.props.onAddProjectLogo()}>Add logo</Dropdown.Item>
                        <Dropdown.Item className="no-select" href="#" onClick={e => this.props.onProjectDelete(this.props.project)}>Delete</Dropdown.Item>
                    </DropdownMenu>
                </Dropdown>
            </ButtonGroup>
        );
    }
}
 
export default Menu;