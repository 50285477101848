import React, { Component } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import linkService from '../../../../../../../../../../../services/linkService';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {openPopup} from "../../../../../../../../../../../models/store/popup/linkDetails";
import linkImg from "../../../../../../../../../../../markup/img/icon-link.png";
import newCommentImg from "../../../../../../../../../../../markup/img/icon-chat-new.png";
import commentImg from "../../../../../../../../../../../markup/img/icon-chat.png";

class Link extends Component {

    render() {
        return (
            <div className="block" onClick={this.handleClick}>
                <img className="icon" src={linkService.getIcon(this.props.link.url)} width="48" height="48" alt="Icon" />

                <div className="text">
                    <div className="title" style={{wordBreak: "break-all", fontSize: "18px", fontWeight: "600"}}>
                        {this.props.link.name}
                    </div>

                    <div className="file-description">
                        {this.props.link?.description}
                    </div>
                </div>
                {this.subMenu()}
                <div className="card-details">
                    <img className="icon fileDownload" src={linkImg} onClick={e => this.openInNewTab(e, this.props.link.url)} alt="go to link" />
                    <span className="card-download-text" onClick={e => this.openInNewTab(e, this.props.link.url)}>Go to link</span>
                    {this.setCommentIcon()}
                </div>
            </div>
        );
    }

    subMenu = () => {
        const Toggle = React.forwardRef(({ children, onClick }, ref) => (
            <span className="no-outline no-select" ref={ref} onClick={e => { e.preventDefault(); onClick(e); }}>
                {children}
            </span>
        ));

        return this.props.readOnly ? ''
            : <ButtonGroup onClick={e => e.stopPropagation()}>
                <Dropdown className="sub-menu">
                    <Dropdown.Toggle as={Toggle}>
                        <span className="more-icon"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-right">
                        {this.menuItems().map((item, index) =>
                            <Dropdown.Item key={index} className="no-select" href="/#" onClick={e => { e.preventDefault(); item.handler(this.props.link, this.props.group) }}>{item.title}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup >
    }

    openInNewTab = (e, url) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        window.open(url, '_blank', 'noopener,noreferrer');
    }

    menuItems() {
        return [
            { title: "Edit", handler: this.props.onEdit },
            { title: "Delete", handler: this.props.onDelete }
        ];
    }

    handleClick = (e) => {
        this.props.openPopup({ link: this.props.link });
    }

    setCommentIcon = () => {
        if (this.props?.link?.hasUnreadComments) {
            return <img className="icon fileComment" src={newCommentImg} alt="comments unread"></img>
        }
        return <img className="icon fileComment" src={commentImg} alt="comments read"></img>
    }
}

export default connect(
    (state) => ({
        createAction: state.entities.popups.createAction,
        actionTypes: state.entities.enums.actionTypes
    }),
    (dispatch) => ({
        openPopup: (file) => dispatch(openPopup(file)),
    })
)(withRouter(Link));