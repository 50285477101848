import React, { Component } from 'react';
import img2x from '../../../../../../../../markup/img/icon-eye-white@2x.png';
import { Link } from 'react-router-dom';

class Preview extends Component {
    render() {
        return (
            <Link to={this.props.uri} target="_blank" rel='noopener noreferrer'
                className="header-btn no-select no-outline preview-btn">
                <img className="icon" src={img2x} width="20" height="20" alt="Eye"/>
                <span className="title">Preview</span>
            </Link>
        );
    }
}

export default Preview;
