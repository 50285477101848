import React, { Component } from 'react';
import Wrapper from '../../../../../../shared/field/wrapper';
import authApiService from '../../../../../../../../services/authorizedApiHttpService';
import HistoryItem from "./HistoryItem";

class FileHistory extends Component {

    state = {
        fileHistory: []
    }

    async componentDidMount() {
        this.getFileHistory();
    }

    render() {
        return (
            <Wrapper>{this.fileHistory()}</Wrapper>
        );
    }

    fileHistory = () => {
        return this.state.fileHistory?.length > 0 ?
            this.state.fileHistory.map(history => (
                <HistoryItem key={history.id} historyItem={history} />
            ))
            : "";
    }

    getFileHistory = () => {
        authApiService.get(`/project/${this.props.projectId}/file/${this.props.file.id}/history`,
            (fileHistory, headers) => {
                this.setState({fileHistory: fileHistory});
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }
}

export default FileHistory;