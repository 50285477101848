
class UploadFile {

    constructor(file = null, projectId = null, loading = false, progressPercent = 0, uploadSpeed = 0, signedUrl = null, error = '', complete = false, cancelToken = null, uploadStarTimestamp = null, progressUpdateHandler = null, uploadStatusHandler = null) {
        this.file = file;
        this.projectId = projectId;
        this.loading = loading;
        this.progressPercent = progressPercent;
        this.uploadSpeed = uploadSpeed;
        this.signedUrl = signedUrl;
        this.error = error;
        this._complete = complete;
        this.cancelToken = cancelToken;
        this.uploadStarTimestamp = uploadStarTimestamp;
        this.progressUpdateHandler = progressUpdateHandler;
        this.uploadStatusHandler = uploadStatusHandler;
    }

    set complete(complete) {
        this._complete = complete;
        this.progressPercent = 100;
        this.uploadSpeed = 0;
        if (this.progressUpdateHandler)
            this.progressUpdateHandler();
    }

    get complete() {
        return this._complete;
    }

    equals = (uploadFile) => {
        return (uploadFile instanceof UploadFile)
            && (this.file.name === uploadFile.file.name)
            && (this.file.size === uploadFile.file.size)
            && (this.file.type === uploadFile.file.type)
            && (this.file.lastModified === uploadFile.file.lastModified);
    }

    updateProgressPercent = (loadedSize, totalSize) => {
        this.progressPercent = this.percentCompleted(loadedSize, totalSize);
        this.uploadSpeed = this.calculateUploadSpeed(loadedSize);
        if (this.progressUpdateHandler) {
            this.progressUpdateHandler();
        }
    }

    percentCompleted = (loadedSize, totalSize) => {
        return (loadedSize !== null && totalSize !== null)
            ? Math.round((loadedSize * 100) / totalSize)
            : 0;
    }

    calculateUploadSpeed = (loadedSize) => {
        let timeTaken = (new Date().getTime() - this.uploadStarTimestamp) / 1000;
        return (0 !== timeTaken) ? Math.round(((loadedSize * 0.0009765625) / timeTaken)) : 0;
    }

    prepareForUpload = () => {
        this.loading = true;
        this.uploadStarTimestamp = new Date().getTime();
        this.error = '';
    }

    uploadComplete = (complete, error = false) => {
        this.loading = false;
        this.complete = complete;
        this.error = error;
        if (this.uploadStatusHandler)
            this.uploadStatusHandler(this);
    }
}

export default UploadFile;