import React, { Component } from 'react';
import xClose from "../../../../markup/img/x_close.png";
import authApiService from "../../../../services/authorizedApiHttpService";

class UpdatableText extends Component {

    state = {
        editText: false,
        text: this.props.text
    }

    render() {
        return (
            <React.Fragment>
                {this.state.editText ?
                        <div>
                                   <textarea className="board-card-detail-textarea"
                                             name="text"
                                             onChange={e => {
                                                 this.onTextChange(e.target.value)
                                             }}
                                             placeholder="Add text..."
                                             defaultValue={this.state.text}></textarea>
                            <div className="button-group">
                                <button type="button" className="btn btn-blue" onClick={e => this.props.onTextUpdate(this, this.state.text)}>Save</button>
                                <img className="ml10 cursor-pointer"
                                     onClick={this.onCloseText}
                                     src={xClose}
                                     width="15" height="15" alt="Close" />
                            </div>
                        </div>
                        :
                        <div className={this.getTextClasses()} onClick={this.onEditText}>{this.state.text ? this.state.text : "Edit text"}</div>
                }
            </React.Fragment>
        );
    }

    onTextUpdate = (text) => {
        this.setState({editText: false, text: text});
    }

    onCloseText = () => {
        this.setState({editText: false});
    }

    getTextClasses = () => {
        return "cursor-pointer " +
            (this.props.text?.length === 0
                    ? " docs-text-grey-bold"
                    : ""
            );
    }

    onTextChange = (text) => {
        this.setState({text: text});
    }

    onEditText = () => {
        this.setState({editText: true});
    }
}

export default UpdatableText;