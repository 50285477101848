import React, { Component } from 'react';
import Textarea from 'react-textarea-autosize';
import authApiService from '../../../../services/authorizedApiHttpService';
import { withRouter } from 'react-router-dom';
import ValidationErrors from '../../shared/field/errors/errors';
import Content from '../../shared/wrapper/content';

class ProjectTitle extends Component {

    constructor() {
        super();
        this.titleTextarea = React.createRef();
    }

    state = {
        errors: {
            form: {
                name: [],
                description: []
            }
        }
    }

    render() {
        return (
            <div className="header">
                <Content>
                    <h2 className="title">
                        <Textarea className={this.classNames()} maxLength="100" ref={this.titleTextarea} data-gramm_editor="false" onKeyDown={this.handleKeyDown} onKeyUp={this.handleTitleKeyUp} spellCheck="false" minRows={1} maxRows={10} onBlur={e => this.handleTitleBlur(e.target.value)} defaultValue={this.props.project?.name} readOnly={this.props.readOnly} />
                        <ValidationErrors errors={this.state.errors.form.name} />
                    </h2>
                    <div className="description">
                        <Textarea className={this.classNames()} maxLength="255" ref={this.descriptionTextarea} data-gramm_editor="false" onKeyDown={this.handleKeyDown} onKeyUp={this.handleDescriptionKeyUp} spellCheck="false" minRows={1} maxRows={10} onBlur={e => this.handleDescriptionBlur(e.target.value)} defaultValue={this.props.project?.description} readOnly={this.props.readOnly} />
                        <ValidationErrors errors={this.state.errors.form.description} />
                    </div>
                </Content>
            </div>
        );
    }

    classNames = () => {
        let classes = ['noshadow'];
        if (this.props.readOnly)
            classes.push('read-only');
        return classes.join(' ');
    }

    handleKeyDown = (e) => {
        /* Prevent to enter Enter key */
        if (e.which === 13) {
            e.preventDefault();
        }
    }

    handleTitleKeyUp = (e) => {
        this.clearTitleErrors();
    }

    handleDescriptionKeyUp = (e) => {
        this.clearDescriptionErrors();
    }

    handleTitleBlur = (title) => {
        if (!title || title.length === 0) {
            this.titleTextarea.current.focus();
        }
        /* No sense to send to server existing value */
        else if (title !== this.props.project.name) {
            authApiService.put('/project/' + this.props.match.params.projectId + '/name', { name: title },
                (data, headers) => {
                    this.props.project.name = title;
                    this.clearTitleErrors();
                    this.forceUpdate();
                },
                (message, errors) => {
                    const state = { ...this.state };
                    state.errors.form = errors;
                    this.setState(state);
                    this.titleTextarea.current.focus();
                });
        }
    }

    handleDescriptionBlur = (description) => {
        /* No sense to send to server existing value */
        if (description !== this.props.project.description) {
            authApiService.put('/project/' + this.props.match.params.projectId + '/description', { description: description },
                (data, headers) => {
                    this.props.project.description = description;
                    this.clearDescriptionErrors();
                    this.forceUpdate();
                },
                (message, errors) => {
                    const state = { ...this.state };
                    state.errors.form = errors;
                    this.setState(state);
                    this.descriptionTextarea.current.focus();
                });
        }
    }

    clearTitleErrors = () => {
        const state = { ...this.state };
        state.errors.form.name = [];
        this.setState(state);
    }

    clearDescriptionErrors = () => {
        const state = { ...this.state };
        state.errors.form.description = [];
        this.setState(state);
    }
}

export default withRouter(ProjectTitle);