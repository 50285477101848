import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'comments',
    initialState: {},
    reducers: {
        commentsFetched: (comments, action) => {
            comments[action.payload.actionId] = action.payload.comments;
            return comments;
        },
        commentCreated: (comments, action) => {
            if (!comments[action.payload.actionId]) {
                comments[action.payload.actionId] = [];
            }
            comments[action.payload.actionId].unshift(action.payload.comment);
            return comments;
        },
        commentUpdated: (comments, action) => {
            if (comments[action.payload.actionId]) {
                let comment = comments[action.payload.actionId]
                    .filter(comment => comment.id !== action.payload.comment.id).shift();
                Object.assign(comment, action.payload.comment);
            }
            return comments;
        },
        commentDeleted: (comments, action) => {
            if (comments[action.payload.actionId]) {
                comments[action.payload.actionId] = comments[action.payload.actionId].filter(comment => comment.id !== action.payload.comment.id);
            }
            return comments;
        }
    }
});

export default slice.reducer;
export const { commentsFetched, commentCreated, commentUpdated, commentDeleted } = slice.actions;