import React, { Component } from 'react';
import $ from 'jquery';
import ProjectTitle from './ProjectTitle';
import icon2x from '../../../../markup/img/icon-user@2x.png';
import icon3x from '../../../../markup/img/icon-user@3x.png';
import { Link } from 'react-router-dom';
import userProfileService from '../../../../services/userProfileService';
import projectService from '../../../../services/projectService';
import { withRouter } from 'react-router-dom';
import Logo from '../../shared/header/logo';

class Header extends Component {
    render() {
        return (
            <div className={this.classNames()}>
                <nav className="navbar justify-content-between">
                    <Logo projectLogo={this.props.projectLogo} />
                    <div className="form-inline">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <span className="nav-link no-select" onClick={e => this.handleLinksClick('about-project')}>About project</span>
                            </li>
                            <li className="nav-item">
                                <span className="nav-link no-select" onClick={e => this.handleLinksClick('links')}>Important links</span>
                            </li>
                            <li className="nav-item">
                                <span className="nav-link no-select" onClick={e => this.handleLinksClick('files')}>Attachments</span>
                            </li>
                        </ul>

                        {this.profileLink()}
                    </div>
                </nav>

                <ProjectTitle project={this.props.project} readOnly={this.props.readOnly} />
            </div>
        );
    }

    classNames = () => {
        return "page-header " + projectService.backgroundGradientClass(this.props.match.params.projectId);
    }

    profileLink = () => {
        return userProfileService.isAuthenticated()
            ? (
                <Link to="/profile">
                    <div className="view-page-avatar-wrapper">
                        <img src={icon2x} srcSet={`${icon2x} 2x, ${icon3x} 3x`} width="24" height="24" alt="Profile" />
                    </div>
                </Link>
            ) : "";
    }

    handleLinksClick = (id) => {
        $("html, body").animate({ scrollTop: $('#' + id).offset().top - 80 }, 300);
    }
}

export default withRouter(Header);