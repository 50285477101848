import React, {Component} from "react";
import icon2x from '../../../../../markup/img/icon-google@2x.png'
import icon3x from '../../../../../markup/img/icon-google@3x.png'

class Google extends Component {

    render() {
        return (
            <button onClick={e => this.props.onPress(e)} type="button" className="btn w-220 google">
                <img className="google" src={icon2x}
                     srcSet={`${icon2x} 2x, ${icon3x} 3x`} width="16" height="16" alt="Google"/>
                    Google
            </button>
        );
    }

}

export default Google;