import React, { Component } from 'react';
import img2x from '../../../../../../../../markup/img/icon-share@2x.png';

class Share extends Component {
    render() { 
        return (
            <span className="file-button header-btn no-select no-outline grey-border share-btn" onClick={e => this.props.onShare(e)}>
                <img className="icon" src={img2x} width="20" height="20" alt="Eye"/>
                <span className="title">Share</span>
            </span>
        );
    }
}
 
export default Share;