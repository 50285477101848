import React, { Component } from 'react';
import ValidationErrors from '../../../../shared/field/errors/errors';

class Password extends Component {

    state = {
        passwordVisisble: false
    }

    render() {
        const { errors } = this.props;
        return (
            <React.Fragment>
                <div className="form-label">{this.props.title ? this.props.title : "Password *"}</div>
                <div className="icon-input-wrapper flex-direction-row">
                    <input onChange={e => { this.props.onChange(e.target.value) }} className={"eyed-password " + (errors && errors.length > 0 ? "border-error" : "")} type={this.state.passwordVisisble ? "text" : "password"} name="link" placeholder="Password" />
                    <span className="password-icon" onClick={e => this.handleClickIcon(e.target)} />
                </div>
                <ValidationErrors errors={errors} />
            </React.Fragment>
        );
    }

    handleClickIcon(icon) {
        const { passwordVisisble } = this.state;
        this.setState({ passwordVisisble: !passwordVisisble });
        if (passwordVisisble)
            icon.classList.remove("show");
        else
            icon.classList.add("show");
    }
}

export default Password;