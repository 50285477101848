import React, { Component } from 'react';
import Layout from '../layout/main/layout';
import ErrorForbidden from '../layout/shared/error/server/errorForbidden';

class ForbiddenPage extends Component {

    render() {
        return (
            <Layout>
                <ErrorForbidden />
            </Layout>
        );
    }
}

export default ForbiddenPage;