import React, { Component } from 'react';
import Layout from '../layout/login/layout';
import ResetPasswordForm from "../layout/login/form/resetPassword";

class ResetPasswordPage extends Component {

    render() {
        return (
            <Layout pageName="signIn">
                <ResetPasswordForm />
            </Layout>
        );
    }
}

export default ResetPasswordPage;