function clone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

function idObjectToArray(idObject) {
    return Object.keys(idObject)
        .map(id => idObject[id]);
}

export default {
    clone,
    idObjectToArray
};