import UploadFile from './uploadFile';
import authApiHttpService from '../../../../../../../../services/authorizedApiHttpService';
import httpService from '../../../../../../../../services/httpService';
import SignedUrl from './signedUrl';
import axios from 'axios';
import mixpanel from '../../../../../../../../services/mixpanelService';
import userProfileService from '../../../../../../../../services/userProfileService';

class UploadFileProcessor {

    constructor(uploadFiles = [], maxLoadingCount = 3) {
        this.uploadFiles = uploadFiles;
        this.maxLoadingCount = maxLoadingCount;
        this.timer = setInterval(this.uploadWaitingFiles, 100);
        this.loadingCount = 0;
    }

    scheduleUpload(file, projectId) {
        let uploadFile = new UploadFile(file, projectId);
        if (this.uploadFiles.filter(uf => uf.equals(uploadFile)).length === 0) {
            this.uploadFiles.push(uploadFile);
            this.uploadWaitingFiles();
        }
    }

    uploadWaitingFiles = () => {
        let loadingCount = this.loadingCount;
        let maxLoadingCount = this.maxLoadingCount;
        if (loadingCount < maxLoadingCount) {
            let pendingFiles = this.pendingFiles();
            for (let i = 0; i < (maxLoadingCount - loadingCount); i++) {
                if (pendingFiles.length)
                    this.startUpload(pendingFiles.shift());
            }
        }
    }

    startUpload = (uploadFile) => {
        const errorMessage = 'Cannot upload file because of error, please try again or contact support';
        uploadFile.prepareForUpload();
        this.requestSignedUrl(uploadFile,
            (data) => {
                uploadFile.signedUrl = data.signedUrl;
                this.uploadToS3Directly(uploadFile,
                    (data) => {
                        mixpanel.track("file.uploaded", {
                            userId: userProfileService.profile().id,
                            name: uploadFile.file.name,
                            type: uploadFile.file.type,
                            size: uploadFile.file.size
                        });
                        this.fileUploadComplete(uploadFile, true);
                    },
                    (exception) => {
                        this.fileUploadComplete(uploadFile, false, true);
                    });
            },
            (message, errors) => {
                this.fileUploadComplete(uploadFile, false, errorMessage);
            });
    }

    requestSignedUrl = (uploadFile, successHandler, errorHandler) => {
        const signedUrl = new SignedUrl(uploadFile.file.name, uploadFile.file.size, uploadFile.file.type);
        authApiHttpService.post('/project/' + uploadFile.projectId + '/file/signed/url', signedUrl,
            (data, headers) => {
                successHandler(data);
            },
            (message, errors) => {
                errorHandler(message, errors);
            },
            (cancelToken) => { });
    }

    uploadToS3Directly = (uploadFile, successHandler, errorHandler) => {
        uploadFile.cancelToken = axios.CancelToken.source();

        /* Options for direct S3 upload */
        const options = {
            headers: {
                'Content-Type': uploadFile.file.type
            },
            cancelToken: uploadFile.cancelToken.token,
            onUploadProgress: (progressEvent) => {
                uploadFile.updateProgressPercent(progressEvent.loaded, progressEvent.total);
            }
        };

        /* Upload file directly to S3 */
        httpService.put(uploadFile.signedUrl, uploadFile.file, options,
            (data) => {
                successHandler(data);
            },
            (exception) => {
                errorHandler(exception);
            });
    }

    fileUploadComplete = (uploadFile, successfully, error = false) => {
        uploadFile.uploadComplete(successfully, error);
        this.uploadWaitingFiles();
    }

    isAllFilesUploaded = () => {
        return this.uploadFiles.length && !this.uploadFiles.filter(file => !file.complete).length;
    }

    sendConfirmationAboutUploadFiles = (projectId, fileId, successCallback, errorCallback) => {
        const signedURLs = this.uploadFiles
            .filter(uploadFile => uploadFile.complete)
            .map(uploadFile => uploadFile.signedUrl);

        let url = fileId ? '/project/' + projectId + '/files/' + fileId : '/project/' + projectId + '/files';

        authApiHttpService.post(url, { signedURLs: signedURLs },
            (data, headers) => {
                if (fileId) {
                    successCallback(null, data);
                } else {
                    successCallback(data, null);
                }
            },
            (message, errors) => {
                errorCallback(message, errors);
            },
            (cancelToken) => { });
    }

    pendingFiles = () => {
        return this.uploadFiles.filter(uf => (uf.loading === false) && (uf.complete === false) && (uf.error === ''));
    }

    clear = () => {
        this.uploadFiles.forEach(uploadFile => {
            if (uploadFile.loading)
                uploadFile.cancelToken.cancel();
        });
        this.uploadFiles = [];
    }
}

export default UploadFileProcessor;