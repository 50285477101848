import React, { Component } from 'react';
import DashboardMenuItem from "./dashboardMenuItem";
import pantramLogo from "../../../../markup/img/pantram-logo.png";
import dotsIcon from "../../../../markup/img/3dots.png";
import authApiService from "../../../../services/authorizedApiHttpService";
import DropdownMenu from "./dropdownMenu";
import userProfileService from "../../../../services/userProfileService";

class DashboardHeader extends Component {

    state = {
        externalRoleId: 3,
        activeMenuItem: null,
        showProjectMenu: false,
        items: [
            // { key: 'share_link', name: 'Share invite link' },
            // { key: 'leave', name: 'Leave project' },
            { key: 'delete', name: 'Delete project', isAlert: true }
        ],
        users: []
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.project.id !== this.props.project.id) {
            this.fetchProjectUsers();
        }
    }

    async componentDidMount() {
        this.fetchProjectUsers();
    }

    render() {
        return (
            <div className="dashboard-header flex-center">
                <div className="placeholder-block flex-space-between mt24">
                    <div className="dashboard-header-name no-select flex-start align-items-center">
                        {this.getLogo()}
                        <div className="dashboard-header-title ml8 mr8 cursor-pointer" onClick={this.props.handleProjectSettings}>{this.props.project.name}</div>
                        <div className="dashboard-header-project-details text-align-right no-select cursor-pointer" onClick={this.showProjectMenu}>
                            <img className="dashboard-dots-menu no-select cursor-pointer" onClick={this.showProjectMenu} src={dotsIcon} alt="Extra menu"/>
                            <DropdownMenu show={this.state.showProjectMenu} items={this.state.items} onItemClick={this.handleProjectMenu} classNames="doc-sort-menu doc-project-menu" />
                        </div>
                    </div>
                    {
                        this.state.users ?
                            (<div className="dashboard-header-members" style={this.getMembersStyle()}>
                                {this.getProjectMembers()}
                                <div className="dashboard-header-member-count">{this.state.users.length}</div>
                            </div>)
                            :
                            ""
                    }

                </div>
                <div className="flex-start mt16 mb16">
                    {
                        this.props.project.userRoleId === this.state.externalRoleId ?
                            (
                                <React.Fragment>
                                    <DashboardMenuItem
                                        visible={false}
                                        isActive={true}
                                        itemId="external"
                                        onMenuItemClick={this.onMenuItemClick}
                                        hasNotification={true}
                                        projectId={this.props.project.id}>
                                    </DashboardMenuItem>
                                </React.Fragment>
                            )
                            :
                            (
                            <React.Fragment>
                                <DashboardMenuItem projectId={this.props.project.id} visible={true} itemId="chat" onMenuItemClick={this.onMenuItemClick} hasNotification={this.props.notification.chat}>Chat</DashboardMenuItem>
                                <DashboardMenuItem projectId={this.props.project.id} visible={true} itemId="task" onMenuItemClick={this.onMenuItemClick} hasNotification={this.props.notification.task}>Task</DashboardMenuItem>
                                <DashboardMenuItem isActive={true} projectId={this.props.project.id} visible={true} itemId="docs" onMenuItemClick={this.onMenuItemClick} hasNotification={this.props.notification.docs}>Documents & Links</DashboardMenuItem>
                                <DashboardMenuItem projectId={this.props.project.id} visible={false} itemId="external" onMenuItemClick={this.onMenuItemClick} hasNotification={this.props.notification.external}>External</DashboardMenuItem>
                            </React.Fragment>
                            )
                    }
                </div>
            </div>
        );
    }

    getLogo = () => {
        return (this.props.project.iconName || this.props.project.color ?
                <div className="dashboard-header-icon ml24 cursor-pointer" onClick={this.props.handleProjectSettings} style={{backgroundColor: this.props.project.color}}>
                    {this.props.project.iconName ?
                        <img className="dashboard-project-icon-header" src={`./img/projecticons/${this.props.project.iconName}`} alt=""/>
                        :
                        <div></div>}
                </div>
                :
                <img className="dashboard-header-icon cursor-pointer" onClick={this.props.handleProjectSettings} src={pantramLogo} alt=""/>
        );
    }

    showProjectMenu = () => {
        this.setState({showProjectMenu: !this.state.showProjectMenu});
    }

    handleProjectMenu = (action) => {
        if (action === 'preview') {

        } else if (action === 'share_link') {

        } else if (action === 'leave') {

        } else if (action === 'delete') {
            authApiService.delete(`/project/${this.props.project.id}`,
                (data, headers) => {
                    window.location.reload(false);
                },
                (message, errors) => {
                    /* Show popup with error */
                });
        }

        this.setState({showProjectMenu: false});
    }

    getMembersStyle = () => {
        let userWidth = 16;
        if (this.state.users.length === 2) {
            userWidth = 32;
        } else if (this.state.users.length > 2) {
            userWidth = 48;
        }

        const memberStyle = {
            width: ((userWidth + 54) + 'px')
        };

        return memberStyle;
    }

    onMenuItemClick = (menuItem) => {
        if (this.activeMenuItem) {
            this.activeMenuItem.setActive(false);
        }
        this.activeMenuItem = menuItem;
        this.activeMenuItem.setActive(true);

        this.props.onMenuItemSelect(menuItem);
    }

    getProjectMembers = () => {
        let members = [];
        if (this.state.users?.length > 0) {
            members = this.state.users.slice(0, 3);
        }

        return this.state.users?.length > 0 ?
            members.map((user, index) => (
                <div key={user.id} className={this.getMemberClasses(index + 1)}>{userProfileService.userInitials(user?.user?.fullName)}</div>
            ))
            : "";
    }

    getMemberClasses = (index) => {
        return "dashboard-header-member dashboard-header-member" + index;
    }

    fetchProjectUsers = () => {
        if (this.props.project) {
            authApiService.get(`/project/${this.props.project.id}/user`,
                (users, headers) => {
                    this.setState({users: users?.projectUsers});
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        }
    }
}

export default DashboardHeader;