import React, { Component } from 'react';
import FieldWrapper from '../../../../../shared/field/wrapper';
import ValidationErrors from '../../../../../shared/field/errors/errors';

class Name extends Component {
    render() { 
        const {errors} = this.props;
        return (
            <FieldWrapper>
                <div className="form-label">Project name *</div>
                <input onChange={e => {this.props.onChange(e.target.value)}} className={'project-name ' + (errors && errors.length > 0 ? 'border-error' : '')} type="text" name="project-name" placeholder="Title" value={this.props.value}/>
                <ValidationErrors errors={this.props.errors}/>
            </FieldWrapper>
        );
    }
}
 
export default Name;