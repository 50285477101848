import React, {Component} from 'react'
import PropTypes from 'prop-types'
import comment from "../../../../../markup/img/comment.png";

import { MovableCardWrapper} from 'react-trello/dist/styles/Base'
import DeleteButton from 'react-trello/dist/widgets/DeleteButton'

class Card extends Component {
  onDelete = e => {
    this.props.onDelete()
    e.stopPropagation()
  }

  render()  {
    const {
      showDeleteButton,
      style,
      tagStyle,
      onClick,
      onDelete,
      onChange,
      className,
      id,
      title,
      commentCount,
      label,
      description,
      tags,
      cardDraggable,
      editable,
      editableCardContent,
      t
    } = this.props

    const updateCard = (card) => {
      onChange({...card, id})
    }

    return (
      <MovableCardWrapper
        data-id={id}
        onClick={onClick}
        style={style}
        className={className + " board-card"}
      >
          <div className="board-card-title">
              {title}
          </div>
          <div className="board-card-description mt20">
              {description}
          </div>
          <div className="board-card-comment">
              <img src={comment} width="20" height="20" alt="Send" />
              <span className="board-card-comment-count ml10">{commentCount ? commentCount : 0}</span>
          </div>
          {showDeleteButton && <DeleteButton onClick={this.onDelete} />}
      </MovableCardWrapper>
      )
  }
}

Card.propTypes = {
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tagStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
}

Card.defaultProps = {
  showDeleteButton: true,
  onDelete: () => {},
  onClick: () => {},
  style: {},
  tagStyle: {},
  title: 'no title',
  description: '',
  label: '',
  tags: [],
  className: ''
}

export default Card
