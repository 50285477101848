import React, { Component } from 'react';
import LinksCategory from '../../../main/project/builder/content/sheet/category/links/links';
import FilesCategory from '../../../main/project/builder/content/sheet/category/files/files';
import ActionsCategory from '../../../main/project/builder/content/sheet/category/actions/actions';

class Sheet extends Component {
    render() {
        return (
            <div id="about-project" className="project-sheet">
                <ActionsCategory readOnly={this.props.readOnly} />

                {this.props.showFilesCategory ?
                    <FilesCategory category={this.props.project.files} readOnly={this.props.readOnly}
                                   onClickAddElement={this.props.onClickAddElement}
                                   onFileUpdate={this.props.onFileUpdate} onFileDelete={this.props.onFileDelete}
                                   onSubCategoryDelete={this.props.onFilesSubCategoryDelete}/>
                    : ""}

                {this.props.showLinksCategory ?
                    <LinksCategory category={this.props.project.links} readOnly={this.props.readOnly}
                                   onClickAddElement={this.props.onClickAddElement} onLinkEdit={this.props.onLinkEdit}
                                   onLinkDelete={this.props.onLinkDelete}
                                   onSubCategotyDelete={this.props.onLinksSubCategotyDelete}/> : ""}
            </div>
        );
    }
}

export default Sheet;