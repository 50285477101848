import React, { Component } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import projectService from '../../../../../../services/projectService';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { profileFetched } from '../../../../../../models/store/profile/profile';

class Grid extends Component {

    render() {
        const RoleToggle = React.forwardRef(({ children, onClick }, ref) => (
            <div ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} className="project-card-submenu-icon no-select">
                {children}
            </div>
        ));

        return (
            <div className="project-list-content">
                { this.props.projects.map(project => (
                    <div className="card project-card" key={project.id} onClick={e => this.handleCardClick(this.createProgectURI(project))}>
                        <div className="profile-status table">
                            <span className={'status-badge ' + projectService.getStatusClass(project.status)}></span>
                            <span className="status-title">{project.status.status}</span>
                        </div>

                        <ButtonGroup className="project-card-submenu-wrapper" onClick={this.handleSubMenuclick}>
                            <Dropdown className="sub-menu">
                                <Dropdown.Toggle as={RoleToggle}>
                                    <div className="submenu-dot"></div>
                                    <div className="submenu-dot"></div>
                                    <div className="submenu-dot"></div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-right">
                                    <Dropdown.Item className="no-select" key={"delete"} href="#" onClick={e => this.handleDelete(e, project)}>Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ButtonGroup>

                        <div className={"card-img-top " + projectService.backgroundGradientClass(project.id)} />

                        <div className="card-body">
                            <h5 className="project-title">
                                <Link to={this.createProgectURI(project)}>{project.name}</Link>
                            </h5>
                            <span className="project-date">{this.getDate(project.timeCreated)}</span>
                            {this.collaboratorIcon(project)}
                        </div>
                    </div>
                ))
                }
            </div>
        );
    }

    collaboratorIcon(project) {
        return !this.isOwner(project) ? <span className="collaborator-icon" /> : '';
    }

    createProgectURI(project) {
        return this.isOwner(project)
            ? '/builder/project/' + project.id
            : '/project/' + project.id;
    }

    isOwner(project) {
        return (project.ownerId === this.props.profile.id);
    }

    getDate = (timestamp) => {
        var date = new Date(timestamp);
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit' });
        const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
        return `${day} ${month} ${year}`;
    }

    handleDelete = (e, project) => {
        e.preventDefault();
        this.props.onDelete(project);
    }

    handleCardClick = (uri) => {
        this.props.history.push(uri);
    }

    handleSubMenuclick = (e) => {
        e.stopPropagation();
    }
}

export default connect(
    (state) => ({
        profile: state.entities.profile
    }),
    (dispatch) => ({
        profileFetched: (profile) => dispatch(profileFetched(profile))
    })
)(withRouter(Grid));