import React, { Component } from 'react';
import Block from '../../../../../../shared/ghost/block';

class Header extends Component {
    render() {
        return (
            <div className="header">
                <div className="header-btn-wrapper">
                    <Block className="w160 h40"/>
                </div>
                <div className="header-btn-wrapper last">
                    <Block className="w111 h40"/>
                    <Block className="w96 h40"/>
                    <Block className="w40 h40"/>
                </div>
            </div>
        );
    }
}
 
export default Header;