import React, {Component} from 'react';
import FieldWrapper from '../../../shared/field/wrapper';

class Footer extends Component {

    render() {
        return (
            <FieldWrapper className={this.props.className ? this.props.className + " form-footer" : "form-footer"}>
                {this.props.children}
            </FieldWrapper>
        );
    }

}

export default Footer;