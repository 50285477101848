import React, { Component } from 'react';
import FieldWrapper from '../../../shared/field/wrapper';
import ValidationErrors from '../../../shared/field/errors/errors';

class Email extends Component {

    render() {
        const { errors } = this.props;
        return (
            <FieldWrapper>
                <div className="form-label">Email *</div>
                <input defaultValue={this.props.email ? this.props.email : ''} onChange={e => { this.props.onChange(e.target.value) }} className={(errors && errors.length > 0) ? "border-error" : ""} type="text" name="email" placeholder="Email" />
                <ValidationErrors errors={errors} />
            </FieldWrapper>
        );
    }
}

export default Email;