import React, { Component } from 'react';
import StatusDropdown from './dropdown/status';
import PreviewButton from './button/preview';
import ShareButton from './button/share';
import Menu from './dropdown/menu';
import urlService from '../../../../../../../services/urlService';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import userProfileService from '../../../../../../../services/userProfileService';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

class Header extends Component {

    render() {
        return (
            <div className="header">
                <div className="header-btn-wrapper">
                    <StatusDropdown onChange={this.props.onStatusChange} status={this.props.project.status} />
                    <div className="members-wrapper">
                        {this.members()}
                        {this.membersDropdown()}
                    </div>
                </div>

                <div className="header-btn-wrapper">
                    <PreviewButton uri={urlService.projectUri(this.props.match.params.projectId)} />
                    <ShareButton onShare={this.props.onShare} />
                    <Menu project={this.props.project} onProjectDelete={this.props.onProjectDelete} onAddProjectLogo={this.props.onAddProjectLogo} />
                </div>
            </div>
        );
    }

    members = () => {
        if (this.props.members.length === 0) return "";

        const projectId = this.props.match.params.projectId;
        let members = [];
        if (this.props.members.list[projectId].length > 3) {
            for (var i = 0; i < 3; i++) {
                members[i] = this.props.members.list[projectId][i];
            }
        } else {
            members = this.props.members.list[projectId];
        }

        return members.map(member => (
            <span className="member-icon" key={member.id}>
                <span className="profile-img">
                    <span className="initials">{userProfileService.userInitials(member.fullName)}</span>
                </span>
            </span>
        ));
    }

    membersDropdown = () => {
        const projectId = this.props.match.params.projectId;
        if (this.props.members.list[projectId].length === 0 || this.props.members.list[projectId].length <= 3) return "";

        const Toggle = React.forwardRef(({ children, onClick }, ref) => (
            <span className="profile-img" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
                <span className="initials">+{this.props.members.list[projectId].length - 2}</span>
            </span>
        ));

        let members = this.props.members.list[projectId].slice(2, this.props.members.list[projectId].length);

        return <ButtonGroup className="member-dropdown-wrapper sub-menu">
            <Dropdown>
                <span className="member-icon counter">
                    <Dropdown.Toggle as={Toggle} />
                </span>
                <Dropdown.Menu className="dropdown-menu-left">
                    {members.map(member => (
                        <div className="dropdown-item" key={member.id}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="profile-img">{userProfileService.userInitials(member.fullName)}</span>
                                        </td>
                                        <td>
                                            <span className="member-full-name">{member.fullName}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </ButtonGroup>;
    }
}

export default connect(
    (state) => ({
        members: state.entities.members
    }),
    (dispatch) => ({})
)(withRouter(Header));