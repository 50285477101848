import React, { Component } from 'react';
import FieldWrapper from '../../../../../shared/field/wrapper';
import ProjectIconColor from './projectIconColor';
import ProjectIconSymbol from './projectIconSymbol';

class ProjectIcon extends Component {

    state = {
        colors: ['#FDF5CF', '#E5F7FE', '#E8ECFD', '#FBEBF6', '#FADFCD', '#F1FFD0', '#F2E8FC'],
        activeColor: null,
        activeIcon: null,
        icons: [
            '109-strawberry.png',
            '110-spider.png',
            '111-pawprints.png',
            '112-pizza.png',
            '113-ice-cream.png',
            '114-speech-bubble.png',
            '115-full-battery.png',
            '116-balloon.png',
            '117-rollerskate.png',
            '118-car.png',
            '119-slingshot.png',
            '120-pacifier.png',
            '121-worm.png',
            '122-bunny-1.png',
            '123-meteorite.png',
            '124-tile.png',
            '125-tile-1.png',
            '126-sprout.png',
            '127-palette.png',
            '128-button.png',
            '129-barbecue.png',
            '130-bell-pepper.png',
            '131-burning.png',
            '132-green-energy.png',
            '133-beach-ball.png',
            '134-avocado.png',
            '135-flamingo.png',
            '136-sloth.png',
            '137-toucan.png',
            '138-shirt-1.png',
            '139-squid.png',
            '001-rocket.png',
            '002-hot-air-balloon.png',
            '003-idea.png',
            '004-ufo.png',
            '005-earth-globe.png',
            '006-planet.png',
            '007-sun.png',
            '008-alien.png',
            '009-alien-1.png',
            '010-astronaut.png',
            '011-planet-1.png',
            '012-flag.png',
            '013-magnetism.png',
            '014-dog.png',
            '015-cow.png',
            '016-bee.png',
            '017-fox.png',
            '018-tiger.png',
            '019-elephant.png',
            '020-frog.png',
            '021-bunny.png',
            '022-octopus.png',
            '023-monkey.png',
            '024-hen.png',
            '025-ladybug.png',
            '026-koala.png',
            '027-mouse.png',
            '028-parrot.png',
            '029-owl.png',
            '030-hamster.png',
            '031-panda.png',
            '032-giraffe.png',
            '033-penguin.png',
            '034-american-football.png',
            '035-football.png',
            '036-basketball.png',
            '037-pencil.png',
            '038-computer.png',
            '039-calculator.png',
            '040-bow-tie.png',
            '041-glasses.png',
            '042-tent.png',
            '043-shirt.png',
            '044-unicorn.png',
            '045-bicycle.png',
            '046-mustache.png',
            '047-guitar.png',
            '048-coffee-cup.png',
            '049-pipe.png',
            '050-smartphone.png',
            '051-helm.png',
            '052-headphones.png',
            '053-skateboard.png',
            '054-petard.png',
            '055-sound-mixer.png',
            '056-donut.png',
            '057-airplane.png',
            '058-smartphone-1.png',
            '059-map.png',
            '060-boat.png',
            '061-piano.png',
            '062-crayons.png',
            '063-duck.png',
            '064-heart-rate.png',
            '065-carrot.png',
            '066-trophy.png',
            '067-smartwatch.png',
            '068-juice.png',
            '069-target.png',
            '070-push-pin.png',
            '071-flag-1.png',
            '072-compass.png',
            '073-satellite.png',
            '074-shuttle.png',
            '075-flight.png',
            '076-helicopter.png',
            '077-rowing.png',
            '078-radio-tower.png',
            '079-space-suit.png',
            '080-french-bulldog.png',
            '081-beagle.png',
            '082-chihuahua.png',
            '083-penguin-1.png',
            '084-graphic.png',
            '085-analytics.png',
            '086-parachute.png',
            '087-walkie-talkie.png',
            '088-tank.png',
            '089-music-player.png',
            '090-long-play.png',
            '091-leisure.png',
            '092-rgb.png',
            '093-leaf.png',
            '094-mountain.png',
            '095-hot-drink.png',
            '096-line-graph.png',
            '097-compass-1.png',
            '098-campfire.png',
            '099-hamburger.png',
            '100-hot-air-balloon-1.png',
            '101-orange.png',
            '102-crown.png',
            '103-sweet.png',
            '104-mask.png',
            '105-money-bag.png',
            '106-contract.png',
            '107-bell.png',
            '108-apple.png'
        ]
    }

    render() {
        return (
            <FieldWrapper>
                <div className="form-label">Project Icon</div>
                <div className="dashboard-docs-text-grey ml24 mt12">Background</div>
                <div className="project-background-wrapper mt12">
                    { this.state.colors.map(color => (
                        <ProjectIconColor key={color} onColorClick={this.onColorClick} color={color} />
                    ))}
                </div>
                <div className="dashboard-docs-text-grey ml24 mt12">Symbol</div>
                <div className="project-icon-wrapper mt12 no-select">
                    { this.state.icons.map(icon => (
                        <ProjectIconSymbol key={icon} onIconClick={this.onIconClick} icon={icon} />
                    ))}
                </div>
                <div></div>
            </FieldWrapper>
        );
    }

    onColorClick = (color) => {
        if (this.state.activeColor) {
            this.state.activeColor.setActive(false);
        }
        color.setActive(true);
        this.setState({activeColor: color})

        this.props.onColorChange(color.props.color)
    }

    onIconClick = (icon) => {
        if (this.state.activeIcon) {
            this.state.activeIcon.setActive(false);
        }
        icon.setActive(true);
        this.setState({activeIcon: icon})

        this.props.onIconChange(icon.props.icon)
    }
}

export default ProjectIcon;