import React, {Component} from 'react';
import Logo from '../../shared/header/logo'

class Header extends Component {

    render() {
        return (
            <div className="login-page-header">
                <nav className="navbar login justify-content-between">
                    <Logo/>
                    <div className="form-inline">
                        <span className="login-header-text">{this.getSignText(this.props.pageName)}</span>
                        <button onClick={() => this.props.onSignButtonClick(this.props.pageName)} type="button" className="btn btn-outline-primary">
                            {this.getSignButtonText(this.props.pageName)}
                        </button>
                    </div>
                </nav>
            </div>
        );
    }

    getSignText(pageName) {
        return pageName === "signIn" ? "Don't have an account?" : "Already have an account?";
    }

    getSignButtonText(pageName) {
        return pageName === "signIn" ? "Sign up" : "Sign in";
    }
}

export default Header;