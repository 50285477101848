import React, {Component} from 'react';
import FieldWrapper from '../wrapper';

class Summary extends Component {

    render() {
        return (
            <FieldWrapper>
                <div className="validation-errors summary">
                    <div className="validation-error">
                        {this.props.message}
                    </div>
                </div>
            </FieldWrapper>
        );
    }

}

export default Summary;