import React, { Component } from 'react';
import Confirmation from '../../../shared/popup/confirmation';
import successIcon2x from '../../../../../markup/img/icon-check-big@2x.png';
import successIcon3x from '../../../../../markup/img/icon-check-big@3x.png';

class EmailSentPopup extends Component {
    render() {
        return (
            <Confirmation show={this.props.show}
                title={this.title()}
                description={this.description()}
                icon2x={successIcon2x}
                icon3x={successIcon3x}
                onConfirm={this.props.onClose}
                onCancel={this.props.onCancel}
                onClose={this.props.onClose}
                attention={false}
                confirmButtonTitle={'OK'}
                cancelButtonTitle={null} />
        );
    }

    title = () => {
        return 'Reset password email sent';
    }

    description = () => {
        return 'Please check your email and press password recovery link.';
    }
}

export default EmailSentPopup;