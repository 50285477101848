import React, {Component} from 'react';
import Wrapper from './wrapper';
import Title from './title/title';
import Footer from './footer/footer';
import LoginButton from './button/login';
import GoogleButton from './button/google';
import EmailField from './field/email';
import SignInPassword from './field/password/signIn';
import ValidationSummary from '../../shared/field/errors/summary';
import apiService from '../../../../services/apiHttpService';
import userProfileService from '../../../../services/userProfileService';
import $ from 'jquery';
import GoogleSignIn from './button/googleSignIn';
import {withRouter} from 'react-router-dom';
import mixpanel from "../../../../services/mixpanelService";
import authApiService from "../../../../services/authorizedApiHttpService";

class SignIn extends Component {

    state = {
        form: {
            email: "",
            password: ""
        },
        errors: {
            form: {
                email: [],
                password: []
            },
            summary: ""
        }
    }

    render() {
        return (
            <Wrapper>
                <Title title="Login" />
                <EmailField onChange={this.handleEditEmail} errors={this.state.errors.form.email} />
                <SignInPassword onChange={this.handleEditPassword} errors={this.state.errors.form.password} />
                {(this.state.errors.summary && this.state.errors.summary.length > 0) && <ValidationSummary message={this.state.errors.summary} />}
                <Footer className="flex-space-between">
                    <LoginButton onPress={this.handleClickLoginButton} title="Login" />
                    <GoogleButton onPress={this.handleClickGoogleButton} />
                </Footer>
                <GoogleSignIn onSuccess={this.onGoogleSignInSuccess} onFailure={this.onGoogleSignInFailure} />
            </Wrapper>
        );
    }

    handleEditEmail = (email) => {
        const { form, errors } = this.state;
        form.email = email;
        errors.form.email = [];
        errors.summary = "";
        this.setState({ form: form, errors: errors });
    }

    handleEditPassword = (password) => {
        const { form, errors } = this.state;
        form.password = password;
        errors.form.password = [];
        errors.summary = "";
        this.setState({ form: form, errors: errors });
    }

    handleClickLoginButton = (e) => {
        apiService.put("/user/email", this.state.form,
            (profile, headers) => {
                userProfileService.setAuthToken(headers["x-auth-token"]);
                mixpanel.track("sign.in", { type: "email", email: profile.email });
                this.onSignIn();
            },
            (message, errors) => {
                const { form, errors: formErrors } = this.state;
                formErrors.form = errors;
                formErrors.summary = (Object.keys(errors).length > 0) ? "" : message;
                this.setState({ form: form, errors: formErrors });
            });
    }

    handleClickGoogleButton = (e) => {
        $('.google-button').click();
    }

    /* https://developers.google.com/identity/sign-in/web/backend-auth */
    onGoogleSignInSuccess = (googleUser) => {
        apiService.post("/user/google", { idToken: googleUser.getAuthResponse().id_token },
            (profile, headers) => {
                userProfileService.setAuthToken(headers["x-auth-token"]);
                this.onSignIn();
            },
            (message, errors) => {
                const { form, errors: formErrors } = this.state;
                formErrors.form = errors;
                formErrors.summary = (Object.keys(errors).length > 0) ? "" : message;
                this.setState({ form: form, errors: formErrors });
            });
    }

    onSignIn = () => {
        if (this.props.location.search?.startsWith('?inviteId=')) {
            const inviteId = this.props.location.search.substring("?inviteId=".length).trim();
            authApiService.post(`/invite/${inviteId}/approve`,
                (response, headers) => {
                    this.props.history.push('/dashboard');
                },
                (message, errors) => {
                    this.props.history.push('/dashboard');
                });
        } else if (this.props.location.search?.startsWith('?subscriptionId=')) {
            const subscriptionId = this.props.location.search.substring("?subscriptionId=".length).trim();
            if (subscriptionId === 2 || subscriptionId === 3) {
                authApiService.post(`/stripe/create-checkout-session/${subscriptionId}`, null,
                    (url, headers) => {
                        window.location.replace(url)
                    },
                    (message, errors) => {
                        /* Show error popup here */
                    });
            } else {
                this.props.history.push("/projects");
            }
        } else {
            this.props.history.push("/projects");
        }
    }

    onGoogleSignInFailure = (error) => {
        const { form, errors: formErrors } = this.state;
        formErrors.form = {};
        formErrors.summary = "Cannot SignIn with Google, please try again";
        this.setState({ form: form, errors: formErrors });
    }
}

export default withRouter(SignIn);