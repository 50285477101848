import UploadFile from './uploadFile';
import authApiHttpService from '../../../../../../../../services/authorizedApiHttpService';
import httpService from '../../../../../../../../services/httpService';
import SignedUrl from './signedUrl';
import axios from 'axios';
import mixpanel from '../../../../../../../../services/mixpanelService';
import userProfileService from '../../../../../../../../services/userProfileService';

class UserLogoUploadProcessor {

    constructor() {
        this.uploadFile = null;
        this.timer = setInterval(this.uploadWaitingFile, 100);
    }

    scheduleUpload(file, projectId) {
        let uploadFile = new UploadFile(file, projectId);

        uploadFile.progressUpdateHandler = this.uploadFile?.uploadStatusHandler;
        uploadFile.uploadStatusHandler = this.uploadFile?.onUploadStatusUpdate;

        this.uploadFile = uploadFile;
        this.uploadWaitingFile();
    }

    uploadWaitingFile = () => {
        if (this.uploadFile && (this.uploadFile.loading === false) && (this.uploadFile.complete === false) && (this.uploadFile.error === '')) {
            this.startUpload(this.uploadFile);
        }
    }

    startUpload = (uploadFile) => {
        const errorMessage = 'Cannot upload file because of error, please try again or contact support';
        if (uploadFile.file.type !== 'image/png' &&
            uploadFile.file.type !== 'image/jpeg' &&
            uploadFile.file.type !== 'image/svg+xml') {
            this.fileUploadComplete(uploadFile, false, "Please upload only PNG, JPEG or SVG file formats");
            return;
        }

        uploadFile.prepareForUpload();
        this.requestSignedUrl(uploadFile,
            (data) => {
                uploadFile.signedUrl = data.signedUrl;
                this.uploadToS3Directly(uploadFile,
                    (data) => {
                        mixpanel.track("file.uploaded", {
                            userId: userProfileService.profile().id,
                            name: uploadFile.file.name,
                            type: uploadFile.file.type,
                            size: uploadFile.file.size
                        });
                        this.fileUploadComplete(uploadFile, true);
                    },
                    (exception) => {
                        this.fileUploadComplete(uploadFile, false, true);
                    });
            },
            (message, errors) => {
                this.fileUploadComplete(uploadFile, false, errorMessage);
            });
    }

    requestSignedUrl = (uploadFile, successHandler, errorHandler) => {
        const signedUrl = new SignedUrl(uploadFile.file.name, uploadFile.file.size, uploadFile.file.type);
        authApiHttpService.post('/user/file/signed/url', signedUrl,
            (data, headers) => {
                successHandler(data);
            },
            (message, errors) => {
                errorHandler(message, errors);
            },
            (cancelToken) => { });
    }

    uploadToS3Directly = (uploadFile, successHandler, errorHandler) => {
        uploadFile.cancelToken = axios.CancelToken.source();
        /* Options for direct S3 upload */
        const options = {
            headers: {
                'Content-Type': uploadFile.file.type
            },
            cancelToken: uploadFile.cancelToken.token,
            onUploadProgress: (progressEvent) => {
                uploadFile.updateProgressPercent(progressEvent.loaded, progressEvent.total);
            }
        };

        /* Upload file directly to S3 */
        httpService.put(uploadFile.signedUrl, uploadFile.file, options,
            (data) => {
                successHandler(data);
            },
            (exception) => {
                errorHandler(exception);
            });
    }

    fileUploadComplete = (uploadFile, successfully, error = false) => {
        uploadFile.uploadComplete(successfully, error);
        this.uploadWaitingFile();
    }

    isFileUploaded = () => {
        return this.uploadFile?.complete;
    }

    sendConfirmationAboutUploadFiles = (projectId, successCallback, errorCallback) => {
        const signedURL = this.uploadFile.signedUrl;

        authApiHttpService.post('/user/logo', { signedURL: signedURL },
            (data, headers) => {
                successCallback(data);
            },
            (message, errors) => {
                errorCallback(message, errors);
            },
            (cancelToken) => { });
    }

    clear = () => {
        if (this.uploadFile?.cancelToken) {
            this.uploadFile.cancelToken.cancel();
            this.uploadFile = null;
        }
    }
}

export default UserLogoUploadProcessor;