import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class Confirmation extends Component {
    render() {
        return (
            <Modal show={this.props.show}>
                <div className="modal-content">

                    <Modal.Header className="flex-direction-column flex-center">
                        <img className="icon-72" src={this.props.icon2x} srcSet={`${this.props.icon2x} 2x, ${this.props.icon3x} 3x`} width="72" height="72" alt="Delete" />
                        <h5 className="modal-title">{this.props.title}</h5>
                        <div className="modal-sub-title">{this.props.description}</div>

                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => this.props.onClose(e)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>

                    <Modal.Footer className="form-footer flex-center">
                        {this.cancelButton()}
                        <button type="button" className={this.props.attention ? 'btn-delete' : 'btn'} onClick={this.props.onConfirm}>{this.props.confirmButtonTitle}</button>
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }

    cancelButton = () => {
        return this.props.cancelButtonTitle
            ? <button type="button" className="btn btn-outline-grey" onClick={this.props.onCancel}>{this.props.cancelButtonTitle}</button>
            : '';
    }
}

export default Confirmation;