import { combineReducers } from 'redux';
import profileReducer from './profile/profile';
import popupsReducer from './popups';
import enumsReducer from './enums/enums';
import projectsReducer from './projects/projects';
import actionsReducer from './actions/actions';
import commentsReducer from './comments/comments';
import membersReducer from './members/members';

export default combineReducers({
    profile: profileReducer,
    popups: popupsReducer,
    enums: enumsReducer,
    projects: projectsReducer,
    actions: actionsReducer,
    comments: commentsReducer,
    members: membersReducer
});