import React, { Component } from 'react';

class Login extends Component {

    render() {
        return (
            <button onClick={e => this.props.onPress(e)} type="button" className={'w-220 btn ' + this.props.className}>{this.props.title}</button>
        );
    }

}

export default Login;