import React, { Component } from 'react';
import Header from './header/header';
import LinksCategory from './category/links/links';
import FilesCategory from './category/files/files';
import ActionsCategory from './category/actions/actions';

class Sheet extends Component {

    render() {
        return (
            <div className="project-sheet">
                <Header project={this.props.project} readOnly={false} />

                <ActionsCategory readOnly={false} />

                {this.props.showFilesCategory ?
                    <FilesCategory category={this.props.project.files}
                        readOnly={false}
                        onClickAddElement={this.props.onClickAddElement}
                        onFileDelete={this.props.onFileDelete}
                        onFilesSubCategoryDelete={this.props.onFilesSubCategoryDelete} /> : ""}

                {this.props.showLinksCategory ?
                    <LinksCategory category={this.props.project.links}
                        readOnly={false}
                        onClickAddElement={this.props.onClickAddElement}
                        onLinkEdit={this.props.onLinkEdit}
                        onLinkDelete={this.props.onLinkDelete}
                        onSubCategotyDelete={this.props.onLinksSubCategotyDelete} /> : ""}

            </div>
        );
    }

}

export default Sheet;