import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import NewPassword from './field/newPassword';
import RepeatPassword from './field/repeatPassword';
import CloseButton from '../../project/builder/popup/button/close';
import { produce } from 'immer';
import authHttpService from '../../../../../services/authorizedApiHttpService';
import ValidationSummary from '../../../shared/field/errors/summary';

class ChangePasswordPopup extends Component {

    state = {
        form: {
            password: '',
            confirmPassword: ''
        },
        errors: {
            form: {
                password: [],
                confirmPassword: []
            },
            sunmmary: ''
        }
    }

    render() {
        return (
            <Modal show={this.props.show}>
                <Modal.Header>
                    <h5 className="modal-title" id="exampleModalLabel">Change password</h5>
                    <CloseButton onClose={this.props.onClose} />
                </Modal.Header>

                <Modal.Body>
                    <NewPassword onChange={this.handleNewPasswordChange} errors={this.state.errors.form.password} />
                    <RepeatPassword onChange={this.handleRepeatPasswordChange} errors={this.state.errors.form.confirmPassword} />
                    {(this.state.errors.summary && this.state.errors.summary.length > 0) && <ValidationSummary message={this.state.errors.summary} />}
                </Modal.Body>

                <Modal.Footer className="form-footer flex-start">
                    <button type="button" className="btn btn-outline-grey" data-dismiss="modal" onClick={this.props.onClose}>Cancel</button>
                    <button type="button" className={this.actionButtonClasses()} onClick={this.handleChangePassword}>Change</button>
                </Modal.Footer>
            </Modal>
        );
    }

    actionButtonClasses = () => {
        let classes = ['btn'];
        if (!this.canRequestChange())
            classes.push('disabled');
        return classes.join(' ');
    }

    canRequestChange = () => {
        const newPassword = this.state.form.password;
        const repeatPassword = this.state.form.confirmPassword;
        return newPassword && newPassword.length > 5
            && repeatPassword && repeatPassword === newPassword;
    }

    handleNewPasswordChange = (password) => {
        this.setState(produce(this.state, draftState => {
            draftState.form.password = password;
            draftState.errors.form.password = [];
            draftState.errors.summary = '';
        }));
    }

    handleRepeatPasswordChange = (password) => {
        this.setState(produce(this.state, draftState => {
            draftState.form.confirmPassword = password;
            draftState.errors.form.confirmPassword = [];
            draftState.errors.summary = '';
        }));
    }

    handleChangePassword = (e) => {
        let form = produce(this.state.form, draftForm => {
            draftForm.recoveryCode = this.resetPasswordCodeParamValue();
        });
        authHttpService.put("/user/password", form,
            (data, headers) => {
                this.props.onClose();
                this.props.history.push('/profile');
            },
            (message, errors) => {
                this.setState(produce(this.state, draftState => {
                    draftState.errors.form = errors;
                    if (draftState.errors.form.recoveryCode) {
                        draftState.errors.summary = 'Recovery link is expired, please close popup and click "Change password" to reaquest new password recovery link.';
                    } else {
                        draftState.errors.summary = (Object.keys(errors).length > 0) ? "" : message;
                    }
                }));
            });
    }

    resetPasswordCodeParamValue = () => {
        let resetCode = new URL(window.location.href).searchParams.get('code');
        return resetCode ? resetCode : null;
    }
}

export default withRouter(ChangePasswordPopup);