import React, { Component } from 'react';
import DashboardHeader from "./dashboardHeader";
import Docs from "./docs/docs";
import ProjectSettings from "./settings/projectSettings";
import TaskBoard from "./task/taskBoard";
import ChatBoard from "./chat/chatBoard";

class Content extends Component {

    state = {
        itemId: null,
        showProjectSettings: false
    }

    render() {
        return (
            <React.Fragment>
                {this.props.project
                    ?
                    (
                    this.state.showProjectSettings ?
                    <ProjectSettings project={this.props.project} subscriptionData={this.props.subscriptionData} onUpdateProjectName={this.props.onUpdateProjectName} handleBackToContent={this.handleBackToContent} />
                    :
                    <React.Fragment>
                        <DashboardHeader onMenuItemSelect={this.onMenuItemSelect} handleProjectSettings={this.handleProjectSettings} project={this.props.project} notification={this.props.notification} />
                            { this.state.itemId === 'chat' ? <ChatBoard project={this.props.project} profile={this.props.profile} /> : null }
                            { this.state.itemId === 'task' ? <TaskBoard project={this.props.project} /> : null }
                            { this.state.itemId === 'docs' ? <Docs project={this.props.project} subscriptionData={this.props.subscriptionData} isExternal={false} notification={this.props.notification} /> : null }
                            { this.state.itemId === 'external' ? <Docs project={this.props.project} isExternal={true} /> : null }
                    </React.Fragment>
                    )
                    :
                    <div></div>
                }

            </React.Fragment>
        );
    }

    handleBackToContent = () => {
        this.setState({showProjectSettings: false})
    }

    onMenuItemSelect = (menuItem) => {
        this.setState({itemId: menuItem.props.itemId});
    }

    handleProjectSettings = () => {
        this.setState({showProjectSettings: !this.state.showProjectSettings})
    }
}

export default Content;