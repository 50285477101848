import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import CloseButton from '../../project/builder/popup/button/close';
import {withRouter} from 'react-router-dom';
import leftArrow from "../../../../../markup/img/left-arrow@3x.png";
import rightArrow from "../../../../../markup/img/right-arrow@3x.png";

class DocPopup extends Component {

    state = {
        currentPage: 0
    }

    render() {
        return (
            <Modal className="request-subject edit-action" show={this.props.show}>
                <div className="modal-content w650">
                    <Modal.Header>
                        <div className="flex-start">
                            <img onClick={this.onLeft} className="cursor-pointer" src={leftArrow} width="24" height="24" alt="Left"/>
                            <h6 className="modal-title ml10">{this.state.currentPage + 1} / {this.props.pages.length}</h6>
                            <img onClick={this.onRight} className="cursor-pointer ml10" src={rightArrow} width="24" height="24" alt="Right"/>
                        </div>
                        <CloseButton onClose={this.onClose} />
                    </Modal.Header>

                    <Modal.Body className="flex-direction-column">
                        <img className="w100p h100p" src={`data:image/jpeg;base64,${this.props.pages[this.state.currentPage]}`} alt="Document" />
                    </Modal.Body>
                </div>
            </Modal>
        );
    }

    onLeft = () => {
        if (this.state.currentPage === 0) {
            this.setState({currentPage: this.props.pages.length - 1});
        } else {
            this.setState({currentPage: this.state.currentPage - 1});
        }
    }

    onRight = () => {
        this.setState({currentPage: (this.state.currentPage + 1) % this.props.pages.length});
    }

    onClose = () => {
        this.setState({currentPage: 0});
        this.props.onClose();
    }
}

export default withRouter(DocPopup);