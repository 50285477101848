import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import NameField from './field/name';
import DescriptionField from './field/description';
import ValidationSummary from '../../../../shared/field/errors/summary';
import authApiService from '../../../../../../services/authorizedApiHttpService';
import ProjectIcon from './field/projectIcon';
import mixpanel from '../../../../../../services/mixpanelService';

class CreateProject extends Component {

    state = {
        privacy: {
            options: [
                { id: 1, name: 'Private' },
                { id: 2, name: 'Public' }
            ]
        },
        form: {
            name: '',
            description: '',
            color: '',
            iconName: '',
            privacyId: 2
        },
        errors: {
            form: {
                name: [],
                description: [],
                projectIcon: [],
                privacyId: []
            },
            summary: ''
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show !== this.props.show) {
            this.reset();
        }
    }

    render() {
        return (
            <Modal show={this.props.show}>
                <Modal.Header>
                    <h5 className="modal-title" id="exampleModalLabel">Create new project</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <div className="tab-content" id="new-project-tabs-content">
                        <div className="tab-pane fade show active" id="about-project" role="tabpanel" aria-labelledby="home-tab">
                            <NameField onChange={this.handleNameChange} errors={this.state.errors.form.name} />
                            <DescriptionField onChange={this.handleDescriptionChange} errors={this.state.errors.form.description} />
                            <ProjectIcon onColorChange={this.handleColorChange} onIconChange={this.handleIconChange} errors={this.state.errors.form.projectIcon} />
                        </div>
                        {(this.state.errors.summary && this.state.errors.summary.length > 0) && <ValidationSummary message={this.state.errors.summary} />}
                    </div>
                </Modal.Body>

                <Modal.Footer className="form-footer">
                    <button type="button" className="btn btn-outline-grey" data-dismiss="modal" onClick={this.props.onClose}>Cancel</button>
                    <button type="button" className="btn" onClick={this.handleCreateProject}>Create</button>
                </Modal.Footer>
            </Modal>
        );
    }

    handleNameChange = (name) => {
        const state = { ...this.state };
        state.form.name = name;
        state.errors.form.name = [];
        state.errors.summary = "";
        this.setState(state);
    }

    handleDescriptionChange = (description) => {
        const state = { ...this.state };
        state.form.description = description;
        state.errors.form.description = [];
        state.errors.summary = "";
        this.setState(state);
    }

    handleColorChange = (color) => {
        const state = { ...this.state };
        state.form.color = color;
        state.errors.summary = "";
        this.setState(state);
    }

    handleIconChange = (icon) => {
        const state = { ...this.state };
        state.form.iconName = icon;
        state.errors.summary = "";
        this.setState(state);
    }

    reset = () => {
        const state = { ...this.state };
        state.form.name = '';
        state.form.description = '';
        state.form.color = '';
        state.form.iconName = '';

        state.errors.form.name = [];
        state.errors.summary = "";
        this.setState(state);
    }

    handleCreateProject = () => {
        authApiService.post("/project", this.state.form,
            (project, headers) => {
                this.props.onProjectCreated(project);
                mixpanel.track("project.created", project);
                this.props.onClose();
            },
            (message, errors) => {
                const state = { ...this.state };
                state.errors.form = errors;
                state.errors.summary = (Object.keys(errors).length > 0) ? "" : message;
                this.setState(state);
            },
            (cancelToken) => { });
    }

    handleEditProject = () => {

    }

    handlePrivacySelect = (privacy) => {
        const state = { ...this.state };
        state.privacy.selected = privacy;
        state.form.privacyId = privacy.id;
        this.setState(state);
    }
}

export default CreateProject;