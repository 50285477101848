import React, { Component } from 'react';
import Wrapper from '../../../../../shared/field/wrapper';
import userProfileService from '../../../../../../../services/userProfileService';

class Members extends Component {
    render() {
        return (
            <Wrapper>
                <div className="member-icon-wrapper">
                    {this.props.members.map((member, index) => (
                        <span className="member-icon" key={index}>
                            <span className="profile-img">
                                <span className="initials">{userProfileService.userInitials(member.fullName)}</span>
                            </span>
                            <span className="delete-icon-wrapper">
                                <span className="delete-icon" onClick={e => this.props.onDelete(member)}></span>
                            </span>
                        </span>
                    ))}
                </div>
            </Wrapper>
        );
    }
}

export default Members;