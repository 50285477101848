import React, { Component } from 'react';
import Layout from '../layout/main/layout';
import Form from '../layout/main/profile/form/form';
import ChangePasswordPopup from '../layout/main/profile/popup/changePasswordPopup';
import EmailSentPopup from '../layout/main/profile/popup/emailSentPopup';

class ProfilePage extends Component {

    constructor() {
        super();
        this.resetPasswordCode = this.resetPasswordCodeParamValue();
        this.showChangePasswordPopup = this.resetPasswordCode != null;
        this.showEmailSentPopup = false;
    }

    render() {
        return (
            <Layout>
                <Form onPasswordResetEmailSent={this.handlePasswordResetEmailSent} />
                <EmailSentPopup show={this.showEmailSentPopup} onCancel={this.handleEmailSentPopupCancel} onClose={this.handleEmailSentPopupClose} />
                <ChangePasswordPopup show={this.showChangePasswordPopup} onClose={this.handleChangePasswordPopupClose} />
            </Layout>
        );
    }

    handlePasswordResetEmailSent = () => {
        this.setShowEmailSentPopup(true);
    }

    handleEmailSentPopupCancel = (e) => {
        this.setShowEmailSentPopup(false);
    }

    handleEmailSentPopupClose = (e) => {
        this.setShowEmailSentPopup(false);
    }

    handleChangePasswordPopupClose = (e) => {
        this.showChangePasswordPopup = false;
        this.forceUpdate();
    }

    resetPasswordCodeParamValue = () => {
        let resetCode = new URL(window.location.href).searchParams.get('code');
        return resetCode ? resetCode : null;
    }

    setShowEmailSentPopup = (show) => {
        this.showEmailSentPopup = show;
        this.forceUpdate();
    }
}

export default ProfilePage;