function baseUrl() {
    return process.env.REACT_APP_BASE_URL;
}

function apiBaseUrl() {
    return process.env.REACT_APP_API_BASE_URL;
}

function projectUrl(projectId) {
    return baseUrl() + '/project/' + projectId;
}

function projectUri(projectId) {
    return '/project/' + projectId;
}

export default {
    baseUrl,
    apiBaseUrl,
    projectUrl,
    projectUri
}