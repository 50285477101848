import React, { Component } from 'react';
import Error from '../error';
import errorIcon2x from '../../../../../markup/img/img-404-error@2x.png';


class ErrorNotFound extends Component {
    render() {
        return (
            <Error img={errorIcon2x} title="Page not found" subTitle="Cannot find page with specified URL" />
        );
    }
}

export default ErrorNotFound;