import React, { Component } from 'react';
import Category from '../category';
import FileSubCategory from "./fileSubcategory";

class Files extends Component {
    render() {
        return (
            <Category category={this.props.category} readOnly={this.props.readOnly} onClickAddElement={this.props.onClickAddElement}>
                {this.groups()}
            </Category>
        );
    }

    groups() {
        if (this.props.category.groups.length) {
            return this.props.category.groups.filter(group => group.files.length > 0)
                .map(group => (
                    <FileSubCategory subcategory={group} key={group.id} readOnly={this.props.readOnly} onSubCategoryDelete={this.props.onFilesSubCategoryDelete} onDelete={this.props.onFileDelete} />
                ));
        }
        return '';
    }
}

export default Files;