import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'editAction',
    initialState: {
        show: false,
        action: null
    },
    reducers: {
        popupShown: (popup, action) => {
            popup.show = true;
            popup.action = action.payload;
            return popup;
        },
        popupClosed: (popup, action) => {
            popup.show = false;
            return popup;
        },
        popupExited: (popup, action) => {
            popup.action = null;
            return popup;
        },
        popupActionUpdated: (popup, action) => {
            popup.action = action.payload;
            return popup;
        }
    }
});

export default slice.reducer;
export const { popupShown, popupClosed, popupExited, popupActionUpdated } = slice.actions;