import React, { Component } from 'react';
import Textarea from 'react-textarea-autosize';
import ValidationErrors from '../../../../../../shared/field/errors/errors';
import Wrapper from '../../../../../../shared/field/wrapper';
import sendIcon2x from '../../../../../../../../markup/img/icon-send-message@2x.png';
import sendIcon3x from '../../../../../../../../markup/img/icon-send-message@3x.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { produce } from 'immer';
import authApiService from '../../../../../../../../services/authorizedApiHttpService';
import { commentsFetched, commentCreated, commentUpdated, commentDeleted } from '../../../../../../../../models/store/comments/comments';
import CommentItem from './CommentItem';

class Comments extends Component {

    constructor() {
        super();
        this.textarea = React.createRef();
        this.timer = setInterval(function () {
            this.setState(this.state);
        }.bind(this), 3000);
    }

    state = {
        form: {
            comment: ""
        },
        errors: {
            form: {
                comment: []
            },
            summary: ""
        }
    }

    async componentDidMount() {
        if (this.props.action) {
            authApiService.get(`/project/${this.props.match.params.projectId}/action/${this.props.action.id}/comments`,
                (comments, headers) => {
                    this.props.commentsFetched({actionId: this.props.action.id, comments: comments});
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        } else if (this.props.file) {
            authApiService.get(`/project/${this.props.match.params.projectId}/file/${this.props.file.id}/comments`,
                (comments, headers) => {
                    this.props.commentsFetched({actionId: this.props.file.id, comments: comments});
                    this.props.onCommentsUpdated(this.props.file);
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        } else if (this.props.link) {
            authApiService.get(`/project/${this.props.match.params.projectId}/link/${this.props.link.id}/comments`,
                (comments, headers) => {
                    this.props.commentsFetched({actionId: this.props.link.id, comments: comments});
                    this.props.onCommentsUpdated(this.props.link);
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
    }

    render() {
        return (
            <Wrapper>
                <div className="comments-wrapper">
                    <div className="form-label">Comments</div>

                    <div className="comments-textarea-wrapper">
                        <Textarea className="comments-textarea noshadow"
                            maxLength="1024"
                            data-gramm_editor="false"
                            spellCheck="false"
                            minRows={2}
                            maxRows={4}
                            ref={this.textarea}
                            onChange={e => this.handleCommentTextChange(e.target.value)}
                            readOnly={false} />

                        <div className="comments-send-btn" onClick={this.handleSendButtonClick}>
                            <img className="send-icon" src={sendIcon2x} srcSet={`${sendIcon2x} 2x, ${sendIcon3x} 3x`} width="20" height="20" alt="Send" />
                        </div>
                    </div>
                    <ValidationErrors errors={this.state.errors.form.comment} />

                    <div className="comments-list-wrapper">
                        {this.comments().map(comment => (
                            <CommentItem key={comment.id} comment={comment} readOnly={this.props.readOnly} />
                        ))}
                    </div>

                </div>
            </Wrapper>
        );
    }

    comments = () => {
        if (this.props.action) {
            return this.props.comments[this.props.action.id]
                ? this.props.comments[this.props.action.id]
                : [];
        } else if (this.props.file) {
            return this.props.comments[this.props.file.id]
                ? this.props.comments[this.props.file.id]
                : [];
        } else if (this.props.link) {
            return this.props.comments[this.props.link.id]
                ? this.props.comments[this.props.link.id]
                : [];
        }
    }

    handleCommentTextChange = (comment) => {
        this.setState(produce(this.state, draftState => {
            draftState.form.comment = comment;
        }));
    }

    handleSendButtonClick = (e) => {
        if (!this.state.form.comment) return;

        if (this.props.action) {
            authApiService.post(`/project/${this.props.match.params.projectId}/action/${this.props.action.id}/comment`, this.state.form,
                (comment, headers) => {
                    this.props.commentCreated({actionId: comment.actionId, comment: comment});
                    this.clear();
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        } else if (this.props.file) {
            authApiService.post(`/project/${this.props.match.params.projectId}/file/${this.props.file.id}/comment`, this.state.form,
                (comment, headers) => {
                    this.props.commentCreated({actionId: comment.fileId, comment: comment});
                    this.clear();
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        } else if (this.props.link) {
            authApiService.post(`/project/${this.props.match.params.projectId}/link/${this.props.link.id}/comment`, this.state.form,
                (comment, headers) => {
                    this.props.commentCreated({actionId: comment.productId, comment: comment});
                    this.clear();
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        }
    }

    clear = () => {
        this.setState(produce(this.state, draftState => {
            draftState.form.comment = "";
            draftState.errors.form.comment = [];
            draftState.errors.summary = '';
        }));
        this.textarea.current.value = "";
    }
}

export default connect(
    (state) => ({
        comments: state.entities.comments
    }),
    (dispatch) => ({
        commentsFetched: (comments) => dispatch(commentsFetched(comments)),
        commentCreated: (comment) => dispatch(commentCreated(comment)),
        commentUpdated: (comment) => dispatch(commentUpdated(comment)),
        commentDeleted: (comment) => dispatch(commentDeleted(comment))
    })
)(withRouter(Comments));