import React, {Component} from 'react';

class Footer extends Component {

    render() {
        return (
            <div className="login-page-footer"></div>
        );
    }
}

export default Footer;