import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import CloseButton from '../button/close';
import { popupClosed, popupExited } from '../../../../../../../models/store/popup/detailsAction';
import { connect } from 'react-redux';
import authApiService from '../../../../../../../services/authorizedApiHttpService';
import {withRouter} from 'react-router-dom';
import {actionCreated, actionUpdated} from '../../../../../../../models/store/actions/actions';
import FilePopupHeader from '../file/filePopupHeader';
import iconDownload from "../../../../../../../markup/img/icon-download.svg";
import iconUpload from "../../../../../../../markup/img/icon-upload@2x.png";
import iconActions from "../../../../../../../markup/img/icon-actions-black@2x.png";
import Comments from './comments/Comments';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import FileHistory from './history/FileHistory'
import fileService from "../../../../../../../services/fileService";
import actionService from "../../../../../../../services/actionService";
import {popupFileUpdated} from "../../../../../../../models/store/popup/detailsAction";
import FilePopup from '../file/filePopup';

class DetailsActionPopup extends Component {

    constructor(props) {
        super(props);
        this.filePopupHeader = React.createRef();
        this.fileHistory = React.createRef();
    }

    state = {
        description: null,
        actionId: -1,
        showFilePopup: false,
        form: {
            additionalInstructions: "",
            fileId: null,
            memberIds: []
        },
        errors: {
            form: {
                additionalInstructions: [],
                fileId: [],
                memberIds: []
            },
            summary: ""
        },
        members: []
    }

    render() {
        return (
            <Modal className="request-subject edit-action" show={this.props.popup.show} onExited={this.props.popupExited}>
                <div className="modal-content request-subject edit-action">
                    <Modal.Header>
                        {this.fileHeader()}
                        <CloseButton onClose={this.popupClosed} />
                    </Modal.Header>

                    <Modal.Body className="flex-direction-column action-modal-body">
                        <div className="form-field-wrapper">
                            <div className="form-label">Description</div>
                            <textarea className="description"
                                      name="description"
                                      onChange={e => { this.onDescriptionChange(e.target.value) }}
                                      placeholder="Enter link description"
                                      defaultValue={this.getDescription()}></textarea>
                        </div>
                        <div className="file-detail-button-group">
                            <button type="button" className="btn file-detail-button" onClick={this.handleDownload}>
                                <img className="icon mr8" src={iconDownload} width="20" height="20" alt="Download"/>
                                <span className="title">Download</span>
                            </button>
                            <button type="button" className="btn btn-outline-grey file-detail-button" onClick={this.handleUpload}>
                                <img className="icon mr8" src={iconUpload} width="20" height="20" alt="Upload"/>
                                <span className="title">Replace</span>
                            </button>
                            <button id={this.state.actionId} type="button" className={this.approveButtonClasses()} onClick={this.handleApprove}>
                                <img className="icon mr8" src={iconActions} width="20" height="20" alt="Approve"/>
                                <span className="title">Approve</span>
                            </button>
                            <FilePopup fileId={this.props.popup.file?.id} show={this.state.showFilePopup} project={this.props.project} onClose={this.handleCloseFilesPopup} onAddFiles={this.handleAddFiles} />
                        </div>

                        <div>
                            <Tabs className="file-details-tab" defaultActiveKey="comments" transition={false}>
                                <Tab eventKey="comments" title="Comments">
                                    <div className="scrollable-themes-wrapper mt24 mh320">
                                        <Comments file={this.props.popup.file} onCommentsUpdated={this.onCommentsUpdated} />
                                    </div>
                                </Tab>
                                <Tab eventKey="history" title="File history">
                                    <div className="scrollable-themes-wrapper mt24 mh320">
                                        <FileHistory ref={this.fileHistory} projectId={this.props.project.id} file={this.props.popup.file} onFileUpload={this.onFile} />
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="form-footer flex-start">
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }

    approveButtonClasses = () => {
        return "btn file-detail-button" +
            (this.props.popup?.file?.approves && !actionService.isComplete(this.props.popup.file.approves[0].statusId) && this.state.actionId === -1
                    ? " btn-outline-grey"
                    : " action bordered disabled"
            );
    }

    onCommentsUpdated = (file) => {
        this.props.onCommentsUpdated(file);
    }

    onFileGroupChange = (file, group) => {
        this.props.onFileGroupChange(file, group);
    }

    handleDownload = (e) => {
        authApiService.get(this.props.popup.file.url,
            (download, headers) => {
                fileService.downloadFile(download.signedUrl, download.fileName);
            },
            (message, errors) => {
                /* Show error popup with some message */
            },
            (cancelToken) => { });
    }

    handleUpload = (e) => {
        this.showFilePopup(true);
    }

    handleApprove = (e) => {
        if (this.props.popup?.file?.approves && !actionService.isComplete(this.props.popup.file.approves[0].statusId) && this.state.actionId === -1) {

            this.props.popup.file.approves.forEach(approve => {
                let statusComplete = actionService.statusId("COMPLETE");
                authApiService.put(`/project/${this.props.match.params.projectId}/action/${approve.id}/status/${statusComplete}`, null,
                    (action, headers) => {
                        this.setState({actionId: action.id})

                        this.props.onApprove(action);
                    },
                    (message, errors) => {
                        /* Show error popup */
                    },
                    (cancelToken) => {
                    });
            });
        }
    }

    actionUpdated = (action) => {
        this.props.actionUpdated(action);
        this.props.popupFileUpdated(action);
    }

    fileHeader = () => {
        return this.props.popup.file ? <FilePopupHeader ref={this.filePopupHeader} onFileGroupChange={this.onFileGroupChange} file={this.props.popup.file} projectId={this.props.match.params.projectId} /> : "";
    }

    popupClosed = (popup, action) => {
        this.filePopupHeader.current.popupClosed(this.state.description);
        this.props.popupClosed(popup, action);
    }

    getDescription = () => {
        if (this.props.popup.file) {
            this.state.description = this.props.popup.file.description;
            return this.props.popup.file.description;
        }

        return "";
    }

    onDescriptionChange = (description) => {
        this.state.description = description;
    }

    handleAddFiles = (projectFilesGroup, fileGroup) => {
        this.showFilePopup(false);
        this.props.onAddFiles(projectFilesGroup, fileGroup);

        if (fileGroup) {
            this.fileHistory.current.getFileHistory();
            this.props.popupFileUpdated(fileGroup.files[0]);
        }
    }

    handleCloseFilesPopup = (e) => {
        this.showFilePopup(false);
    }

    showLinkPopup(show) {
        let state = { ...this.state };
        state.showLinkPopup = show;
        this.setState(state);
    }

    showFilePopup(show) {
        let state = { ...this.state };
        state.showFilePopup = show;
        this.setState(state);
    }
}

export default connect(
    (state) => ({
        popup: state.entities.popups.detailsAction
    }),
    (dispatch) => ({
        popupClosed: () => dispatch(popupClosed()),
        popupExited: () => dispatch(popupExited()),
        actionUpdated: (action) => dispatch(actionUpdated(action)),
        actionCreated: (action) => dispatch(actionCreated(action)),
        popupFileUpdated: (action) => dispatch(popupFileUpdated(action))
    })
)(withRouter(DetailsActionPopup));