import React, { Component } from 'react';
import { requestedForFile } from '../../../../../../../../models/store/popup/createAction';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Header extends Component {
    render() {
        return (
            <React.Fragment>
                <div id={this.props.category.id} className="panel">
                    <h3 className="title">{this.props.category.title}</h3>
                    <span className="action-buttons-wrapper">
                        {this.addButton()}
                        {this.fileUploadButton()}
                    </span>
                </div>
                <div className="description">{this.props.category.description}</div>
            </React.Fragment>
        );
    }

    fileUploadButton = () => {
        return (!this.props.readOnly && this.props.category.id === 'files')
            ? <span className="add-btn file-upload" onClick={e => this.handleFileUpload()}>
                <span className="file-upload-icon" />
                 Request file
               </span>
            : "";
    }

    handleFileUpload = () => {
        let fileUpload = this.props.actionTypes.filter(type => type.name === "FILE_UPLOAD").shift();
        this.props.requestedForFile({ actionType: fileUpload, file: null });
    }

    addButton = () => {
        return (this.props.readOnly || this.props.category.id === 'actions')
            ? ''
            : <span className="add-btn" onClick={e => this.props.onClickAddElement(e, this.props.category.id)}>
                + Add {this.props.category.id === 'links' ? 'Link' : 'File'}
            </span>;
    }
}


export default connect(
    (state) => ({
        popup: state.entities.popups.createAction,
        actionTypes: state.entities.enums.actionTypes
    }),
    (dispatch) => ({
        requestedForFile: (file) => dispatch(requestedForFile(file))
    })
)(withRouter(Header));