import React, { Component } from 'react';
import Header from './header';
import $ from 'jquery';

class Sidebar extends Component {

    render() {
        return (
            <div className="project-sidebar">
                <Header />

                <div className="content">

                    <div className="menu-group">

                        <div className="menu-btn no-select" onClick={e => this.handleLinksClick('actions')}>
                            <div className="menu-btn-icon icon-actions"></div>
                            <span className="menu-btn-title">Actions</span>
                        </div>

                        <div className="menu-btn no-select" onClick={e => this.handleLinksClick('files')}>
                            <div className="menu-btn-icon icon-files"></div>
                            <span className="menu-btn-title">Attachments</span>
                            <div className='custom-switch' onClick={this.preventDefault}>
                                <input
                                    type='checkbox'
                                    className='custom-control-input'
                                    id='attachmentsToggle'
                                    onChange={this.handleToggle}
                                    checked={this.props.showFilesCategory}
                                />
                                <label
                                    className='custom-control-label' htmlFor='attachmentsToggle'>

                                </label>
                            </div>
                        </div>

                        <div className="menu-btn no-select" onClick={e => this.handleLinksClick('links')}>
                            <div className="menu-btn-icon icon-links-tools"></div>
                            <span className="menu-btn-title">Important links</span>
                            <div className='custom-switch' onClick={this.preventDefault}>
                                <input
                                    type='checkbox'
                                    className='custom-control-input'
                                    id='linksToggle'
                                    onChange={this.handleToggle}
                                    checked={this.props.showLinksCategory}
                                />
                                <label
                                    className='custom-control-label' htmlFor='linksToggle'>

                                </label>
                            </div>
                        </div>

                    </div>

                    <div className="date">Created on: {this.props.project ? this.getProjectCreationDate(this.props.project.timeCreated) : ''}</div>
                </div>

            </div>
        );
    }

    preventDefault = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    handleToggle = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.props.onActionToggleChange(e.target.id);
    }

    handleLinksClick = (id) => {
        if($("#" + id).length !== 0) {
            $("html, body").animate({ scrollTop: $('#' + id).offset().top }, 300);
        }
    }

    getProjectCreationDate(timestamp) {
        var date = new Date(timestamp);
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' });
        const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
        return `${month} ${day} ${year}`;
    }

}

export default Sidebar;