import axios from 'axios';
import userProfileService from './userProfileService';
import history from './historyService.js';

const apiURI = process.env.REACT_APP_API_BASE_URL;

async function get(uri, fData, fErrors, fCancelToken = null) {
    try {
        let cancelToken = axios.CancelToken.source();
        if (fCancelToken) {
            fCancelToken(cancelToken);
        }
        const response = await axios.get(apiURI + uri, { headers: authHeaders(), cancelToken: cancelToken.token });
        fData(response.data.data, response.headers);
    } catch (ex) {
        handleErrors(uri, ex, fErrors);
    }
}

async function post(uri, data, fData, fErrors, fCancelToken = null) {
    try {
        let cancelToken = axios.CancelToken.source();
        if (fCancelToken) {
            fCancelToken(cancelToken);
        }
        const response = await axios.post(apiURI + uri, data, { headers: authHeaders(), cancelToken: cancelToken.token });
        fData(response.data.data, response.headers);
    } catch (ex) {
        handleErrors(uri, ex, fErrors);
    }
}

async function put(uri, data, fData, fErrors) {
    try {
        const response = await axios.put(apiURI + uri, data, { headers: authHeaders() });
        /* Redirect to /signin if user get 401 error */
        fData(response.data.data, response.headers);
    } catch (ex) {
        handleErrors(uri, ex, fErrors);
    }
}

async function del(uri, fData, fErrors) {
    try {
        const response = await axios.delete(apiURI + uri, { headers: authHeaders() });
        /* Redirect to /signin if user get 401 error */
        fData(response.data.data, response.headers);
    } catch (ex) {
        handleErrors(uri, ex, fErrors);
    }
}

function authHeaders() {
    return { 'Content-Type': 'application/json', 'X-Auth-Token': userProfileService.getAuthToken() };
}

function handleErrors(uri, ex, fErrors) {
    if (axios.isCancel(ex)) return;

    const response = ex.response;
    if (response) {
        console.log('URI: ' + uri + ', error, status: ' + response.status);
        console.log(response);

        switch (response.status) {
            case 400: {
                const { message, errors } = response.data.error;
                const validationErrors = {};
                if (errors && errors.length > 0) {
                    errors.map(field => {
                        let fieldErrors = validationErrors[field.name];
                        if (!fieldErrors)
                            fieldErrors = validationErrors[field.name] = [];
                        fieldErrors.push(field.message);
                        return field;
                    });
                }
                console.log('URI: ' + uri + ', error status:' + response.status + ', message: ' + message);
                console.log(validationErrors);
                fErrors(message, validationErrors);
            } break;
            case 401: history.push("/signin"); break;
            case 403: history.push("/access-forbidden"); break;
            case 404: history.push("/not-found"); break;
            case 500: history.push("/server-error"); break;
            default: {
                fErrors("Something went wrong, please contact support: hello@pantram.co", {});
            }
        }
    }
    /* No response from server */
    else {
        fErrors("Pantram servers unavailable, please try again later, or contact support: hello@pantram.co", []);
    }
}

export default {
    get,
    post,
    put,
    delete: del,
    authHeaders,
    apiURI
}