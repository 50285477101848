import React, { Component } from 'react';

class SearchInput extends Component {

    render() {
        return (
            <input className="form-control project-search" onChange={e => this.props.onSearchTextChange(e.target.value)} type="text" placeholder="Search project" />
        );
    }
}

export default SearchInput;