import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'createAction',
    initialState: {
        show: false,
        actionType: {}, //APPROVAL/FEEDBACK/FILE_UPLOAD
        file: null,
        link: null
    },
    reducers: {
        requestedForFile: (popup, action) => {
            popup.actionType = action.payload.actionType;
            popup.file = action.payload.file;
            popup.link = null;
            popup.show = true;
            return popup;
        },
        requestedForLink: (popup, action) => {
            popup.actionType = action.payload.actionType;
            popup.link = action.payload.link;
            popup.file = null;
            popup.show = true;
            return popup;
        },
        popupClosed: (popup, action) => {
            popup.show = false;
            return popup;
        },
        popupExited: (popup, action) => {
            popup.link = null;
            popup.file = null;
            return popup;
        }
    }
});

export default slice.reducer;
export const { requestedForFile, requestedForLink, popupClosed, popupExited } = slice.actions;