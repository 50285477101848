import React, {Component} from 'react';
import FieldWrapper from '../../../../shared/field/wrapper';
import Password from './password';

class SignUp extends Component {

    render() {
        return (
            <FieldWrapper>
                <Password onChange={this.props.onChange} errors={this.props.errors}/>
                {(this.props.errors && this.props.errors.lenght) && 
                    <div className="field-info-wrapper">
                        <div className="field-info">Must contain at least 6 symbols including uppercase and numbers</div>
                    </div>
                }
            </FieldWrapper>
        );
    }

}

export default SignUp;