import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import CloseButton from '../../project/builder/popup/button/close';
import { withRouter } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ProfileAccount from './profileAccount'
import ProfileSubscription from "./profileSubscription";
import ProfileLogoPopup from "../../project/builder/popup/file/profileLogoPopup";

class ProfilePopup extends Component {

    state = {
        showEmailSentPopup: false,
        showProfileLogoPopup: false,
    }

    render() {
        return (
            <Modal className="request-subject edit-action" show={this.props.show}>
                <div className="modal-content w650">
                    <div className="profile">
                        <div className="profile-header">Profile</div>
                        <div className="flex-start mt24 cursor-pointer" onClick={this.handleProfileLogoChange}>
                            <img className="profile-image" src={this.props.profilePictureUrl} alt="Profile"/>
                            <div className="ml24">
                                <div className="profile-name text-crop-dots mt6">{this.props.profile?.fullName}</div>
                                <div className="profile-email text-crop-dots">{this.props.profile?.email}</div>
                            </div>
                        </div>
                    </div>

                    <Tabs className="file-details-tab" defaultActiveKey={this.props.defaultActiveTab} transition={false}>
                        <Tab eventKey="account" title="Account">
                            <div>
                                <ProfileAccount profile={this.props.profile} />
                            </div>
                        </Tab>
                        <Tab eventKey="subscription" title="Subscription">
                            <div className="mt24">
                                <ProfileSubscription subscriptionData={this.props.subscriptionData} handleSubscriptionChange={this.props.handleSubscriptionChange} project={this.state.project} profile={this.props.profile} />
                            </div>
                        </Tab>
                    </Tabs>
                    <div className="profile-close">
                        <CloseButton onClose={this.props.onClose} />
                    </div>
                </div>

                <ProfileLogoPopup show={this.state.showProfileLogoPopup} project={this.state.project} onClose={this.handleCloseProfileLogoPopup} onAddUserLogo={this.handleAddProfileLogo} />
            </Modal>
        );
    }

    handleProfileLogoChange = () => {
        this.showProfileLogoPopup(true);
    }

    handleCloseProfileLogoPopup = (e) => {
        this.showProfileLogoPopup(false);
    }

    handleAddProfileLogo = (profileLogo) => {
        this.props.handleAddProfileLogo(profileLogo);
        this.showProfileLogoPopup(false);
    }

    showProfileLogoPopup(show) {
        this.setState({showProfileLogoPopup: show});
    }
}

export default withRouter(ProfilePopup);