import React, { Component } from 'react';
import Layout from '../layout/login/layout';
import ForgotPasswordForm from "../layout/login/form/forgotPassword";

class ForgotPasswordPage extends Component {

    render() {
        return (
            <Layout pageName="signIn">
                <ForgotPasswordForm />
            </Layout>
        );
    }
}

export default ForgotPasswordPage;