import React, { Component } from 'react';
import Header from './file/header';
import File from './file/file';

class SubCategory extends Component {
    render() {
        return (
            <div className="group-wrapper">
                <Header subcategory={this.props.subcategory} readOnly={this.props.readOnly} onDelete={this.props.onSubCategoryDelete} />
                <div className="links">
                    {this.files()}
                </div>
            </div>
        );
    }

    files() {
        if (this.props.subcategory.files.length) {
            return this.props.subcategory.files.map(file => (
                <File file={file} key={file.id} onDelete={this.props.onDelete} readOnly={this.props.readOnly} />
            ));
        }
    }
}

export default SubCategory;