import mixpanel from 'mixpanel-browser';

mixpanel.init('fed50c8ef9e8fc817aa1a307f1aace66');

let isProd = process.env.NODE_ENV === 'production';

let actions = {
    identify: (id) => {
        if (isProd) mixpanel.identify(id);
    },
    alias: (id) => {
        if (isProd) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (isProd) {
            mixpanel.track(name, props);
        }
    },
    people: {
        set: (props) => {
            if (isProd) mixpanel.people.set(props);
        },
    },
    identifyUser: (profile) => {
        actions.identify(profile.id);
        actions.people.set({
            "$email": profile.email,
            "$first_name": profile.firstName,
            "$last_name": profile.lastName,
            "$name": profile.fullName
        });
    }
};

export default actions;