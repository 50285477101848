import React, { Component } from "react";

class Cancel extends Component {

    render() {
        return (
            <button onClick={this.props.onCancel} type="button" className="btn btn-outline-grey">Cancel</button>
        );
    }

}

export default Cancel;