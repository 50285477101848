import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import authHttpService from "../../../../../services/authorizedApiHttpService";
import EmailSentPopup from "../../profile/popup/emailSentPopup";

import userProfileService from "../../../../../services/userProfileService";

class ProfileAccount extends Component {

    state = {
        showEmailSentPopup: false,
        showProfileLogoPopup: false,
        profileLogo: null
    }

    render() {
        return (
            <div className="profile-settings">
                <div className="profile-display-name">
                    <div className="profile-text-header mt32">Display name</div>
                    <div className="profile-box profile-text mt8">{this.props.profile.fullName}</div>
                    <div className="profile-text-desc mt8">This could be your first name, or a nickname — however you’d like people to refer to you in Pantram.</div>
                </div>
                <div className="dashboard-line-h mt24"></div>

                <div className="profile-account-actions">
                    <div className="profile-box profile-text mt8 flex-start flex-space-between">
                        <div>
                            <div className="profile-text-header">Password</div>
                            <div className="profile-text-normal">Change password</div>
                        </div>
                        <button className="profile-btn-white profile-text  mtb-auto" onClick={this.handleForgotPassword}>Reset password</button>
                    </div>
                </div>

                <div className="profile-account-actions">
                    <div className="profile-box profile-text mt8 flex-start flex-space-between">
                        <div>
                            <div className="profile-text-header">Support</div>
                            <div className="profile-text-normal">Report a problem or ask a question.Terms of use</div>
                        </div>
                        <a className="profile-btn-white profile-text mtb-auto" href="mailto:hello@pantram.co">Contact us</a>
                    </div>
                </div>

                <div className="profile-account-actions mb50">
                    <div className="profile-box profile-text mt8 flex-start flex-space-between">
                        <div>
                            <div className="profile-text-header">Account</div>
                            <div className="profile-text-normal">{this.props.profile.email}</div>
                        </div>
                        <button className="profile-btn-white profile-text  mtb-auto profile-account-log-out" onClick={this.handleLogoutButtonClick}>Log Out</button>
                    </div>
                </div>

                <EmailSentPopup show={this.state.showEmailSentPopup} onCancel={this.handleEmailSentPopupCancel} onClose={this.handleEmailSentPopupClose} />
            </div>
        );
    }

    handleForgotPassword = (e) => {
        /* Send recovery password Email */
        authHttpService.post(`/user/profile/password`, { email: this.props.profile.email },
            (data, headers) => {
                /* Show success popup */
                this.setShowEmailSentPopup(true);
            },
            (message, errors) => {
                /* Show error popup */
            });
    }

    handleEmailSentPopupCancel = (e) => {
        this.setShowEmailSentPopup(false);
    }

    handleEmailSentPopupClose = (e) => {
        this.setShowEmailSentPopup(false);
    }

    setShowEmailSentPopup = (show) => {
        this.setState({showEmailSentPopup: show})
    }

    handleLogoutButtonClick = (e) => {
        userProfileService.removeAuthToken();
        this.props.history.push('/signin');
    }
}

export default withRouter(ProfileAccount);