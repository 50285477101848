import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import Wrapper from '../../../../../../shared/field/wrapper';
import ValidationErrors from '../../../../../../shared/field/errors/errors';
import { withRouter } from 'react-router-dom';


class Group extends Component {

    constructor() {
        super();
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        this.props.onDidMount();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    render() {
        const { errors } = this.props.group;
        return (
            <Wrapper>
                <div className="form-label">Group *</div>
                <div className="btn-group dropdown-wrapper">
                    <input type="text"
                        name="group-name"
                        ref={ref => (this.inputRef = ref)}
                        value={this.props.group.name}
                        onFocus={e => this.props.onBecomeFocused(true)}
                        onChange={e => { this.props.onSearchByName(e.target.value); }}
                        className='group-input'
                        autoComplete="off"
                        placeholder="Select or write a new group" />
                    <span className="icon-dropdown" onClick={e => this.handleArrowClick(e)}></span>
                    {this.dropdownMenu()}
                </div>
                <ValidationErrors errors={errors} />
            </Wrapper>
        );
    }

    dropdownMenu() {
        if (this.props.list.length) {
            return <div className={this.props.focused ? 'dropdown-menu dropdown-menu-left db-imp' : 'dropdown-menu dropdown-menu-left'}>
                {this.props.list.map(group => (
                    <Dropdown.Item className="no-select" href="/#" key={group.id} onClick={e => { e.preventDefault(); e.stopPropagation(); this.props.onGroupSelect(group); }}>{group.name}</Dropdown.Item>
                ))}
            </div>;
        } else {
            return '';
        }
    }

    handleClickOutside = event => {
        const domNode = this.inputRef;
        if (!domNode || !domNode.contains(event.target)) {
            this.props.onBecomeFocused(false);
        }
    }

    handleArrowClick = (e) => {
        if (!this.props.focused)
            this.props.onBecomeFocused(true);
    }
}

export default withRouter(Group);