import React, { Component } from 'react';
import Header from './header/header';
import Footer from '../shared/footer/footer';

class Layout extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="page-wrapper">
                    <Header projectLogo={this.props.projectLogo} />
                    <div className="page-content-wrap">
                        <div className="page-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default Layout;


