import React, { Component } from 'react';
import DocDetails from "./docDetails";
import DocComments from "./docComments";

class Doc extends Component {

    render() {
        return (
            <div className="dashboard-doc">
                <DocDetails doc={this.props.doc}
                            project={this.props.project}
                            handleBackToDocs={this.props.handleBackToDocs}
                            docDeleted={this.props.docDeleted}
                            getIcon={this.props.getIcon}
                            getKind={this.props.getKind}
                            getDate={this.props.getDate}
                            isExternal={this.props.isExternal} />
                <div className="dashboard-line"></div>
                <DocComments doc={this.props.doc} />
            </div>
        );
    }
}

export default Doc;