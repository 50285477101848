import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'actions',
    initialState: {},
    reducers: {
        actionsFetched: (actions, action) => {
            actions = {};
            action.payload.forEach(a => actions[a.id] = a);
            return actions;
        },
        actionCreated: (actions, action) => {
            actions[action.payload.id] = action.payload;
            return actions;
        },
        actionUpdated: (actions, action) => {
            actions[action.payload.id] = action.payload;
            return actions;
        },
        actionArchived: (actions, action) => {
            delete actions[action.payload.id];
            return actions;
        }
    }
});

export default slice.reducer;
export const { actionsFetched, actionCreated, actionUpdated, actionArchived } = slice.actions;