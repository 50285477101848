import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

class NewLaneForm extends Component {

    render () {
        const {onCancel, t} = this.props
        const handleAdd = () => this.props.onAdd({title: this.inputRef.value})
        const setInputRef = (ref) => this.inputRef = ref

        return (
            <div>
                <div className="dorpdown-menu-bkg" onClick={this.onBkgClick}></div>
                <div className="input-group mb-3 board-lane-new">
                    <input ref={setInputRef} type="text" className="form-control" placeholder="New list" aria-label="New list" aria-describedby="button-addon2" />
                    <div className="input-group-append">
                        <button onClick={handleAdd} className="btn btn-outline-secondary" type="button" id="button-addon2">Add</button>
                    </div>
                </div>
            </div>
        )
    }

    onBkgClick = () => {
        this.props.onCancel();
    }
}

export default withRouter(NewLaneForm);