import store from '../models/store/store';

function setAuthToken(authToken) {
    if (localStorage) {
        localStorage.setItem('authToken', authToken);
    }
}

function getAuthToken() {
    if (localStorage) {
        return localStorage.getItem('authToken');
    }
    return null;
}

function removeAuthToken() {
    if (localStorage) {
        localStorage.removeItem('authToken');
    }
}

function isAuthenticated() {
    return getAuthToken() !== null;
}

function userInitials(fullName) {
    return fullName
        ? fullName.split(" ", 2).map(name => name.substring(0, 1)).join(" ")
        : "";
}

function profile() {
    return store.getState().entities.profile;
}

export default {
    setAuthToken,
    getAuthToken,
    removeAuthToken,
    isAuthenticated,
    userInitials,
    profile
}