import React, { Component } from 'react';
import Wrapper from '../../../../../shared/field/wrapper';

class RequestSubject extends Component {
    render() {
        return (
            <Wrapper>
                <div className="form-label">{this.props.label}</div>
                {this.props.children}
            </Wrapper>
        );
    }
}

export default RequestSubject;