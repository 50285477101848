import React, { Component } from 'react';
import Wrapper from '../../../../../shared/field/wrapper';
import userProfileService from '../../../../../../../services/userProfileService';
import authApiService from '../../../../../../../services/authorizedApiHttpService';
import { withRouter } from 'react-router-dom';
import { produce } from 'immer';

class MemberSearch extends Component {

    constructor() {
        super();
        this.cancelToken = null;
        this.searchName = "";
        this.inputRef = React.createRef();
        this.dropdownRef = React.createRef();
    }

    state = {
        foundMembers: []
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if ((this.inputRef && !this.inputRef.current.contains(event.target))
            && (this.dropdownRef && !this.dropdownRef.current.contains(event.target))) {
            this.setState(produce(this.state, draftState => {
                draftState.foundMembers = [];
            }));
        }
    }

    render() {
        return (
            <Wrapper>
                <div className="member-search-wrapper sub-menu">
                    <div className="form-label">{this.labelText()}</div>
                    <input type="text"
                        ref={this.inputRef}
                        name="member-search"
                        placeholder={this.props.placeholder}
                        defaultValue={this.searchName}
                        className={(this.props.errors && this.props.errors.length > 0) ? "border-error" : ""}
                        onChange={e => { this.handleOnEdit(e.target.value) }}
                        onFocus={this.handleOnFocus}
                    />
                    <div className="field-info-wrapper">
                        <div className="field-info">Share the project with collaborators to be able to select them</div>
                    </div>

                    {
                        <div className="dropdown-menu" ref={this.dropdownRef}>
                            {this.state.foundMembers.map(member => (
                                <div className="dropdown-item" key={member.id} onClick={e => this.handleMemberSelect(member)}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className="profile-img">{userProfileService.userInitials(member.fullName)}</span>
                                                </td>
                                                <td>
                                                    <span className="member-full-name">{member.fullName}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </Wrapper>
        );
    }

    labelText = () => {
        let labelText = this.props.label ? this.props.label : "";
        if (labelText.length)
            labelText += this.props.required ? " *" : "";
        return labelText;
    }

    handleOnEdit = (searchName) => {
        if (this.cancelToken)
            this.cancelToken.cancel();

        this.searchName = searchName;

        /* Get IDs of all existing members */
        let existingMemberIds = this.props.members.map(m => m.id);

        /* Request project collaborators that are not selected yet */
        authApiService.post(`/project/${this.props.match.params.projectId}/members`,
            { name: searchName, excludeIds: existingMemberIds },
            (members, headers) => {
                this.setState(produce(this.state, draftState => {
                    draftState.foundMembers = members;
                }));
            },
            (message, errors) => { },
            (cancelToken) => {
                this.cancelToken = cancelToken;
            });
    }

    handleOnFocus = (e) => {
        this.handleOnEdit(this.searchName ? this.searchName : "");
    }

    handleMemberSelect = (member) => {
        this.props.onSelect(member);
        this.searchName = "";
        this.setState(produce(this.state, draftState => {
            draftState.foundMembers = [];
        }));
    }
}

export default withRouter(MemberSearch);