import React, {Component, useState} from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, useChatContext, Channel, ChannelHeader, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';

import 'stream-chat-react/dist/css/index.css';
import authApiService from "../../../../../services/authorizedApiHttpService";
import {ChannelContainer} from "./uikit/components/ChannelContainer/ChannelContainer";

class ChatBoard extends Component {

    state = {
        chatClient: null,
        channel: null
    }

    componentDidUpdate(prevProps) {
        if (prevProps.project.id !== this.props.project.id) {
            this.setState({chatClient: null});
            this.getChat();
        }
    }

    render() {
        return (
            this.state.chatClient ?
                <Chat client={this.state.chatClient} theme='team light'>

                    <ChannelContainer
                        channel={this.state.channel}
                        {...{
                            createType: '',
                            isCreating: false,
                            isEditing: false,
                            setIsCreating: false,
                            setIsEditing: false,
                            channel: this.state.channel,
                            projectId: this.props.project.id
                        }}
                    />
                </Chat>
                :
                <div></div>
        );
    }

    async componentDidMount() {
        this.getChat();
    }

    getChat = () => {
        // TODO: CHAT
        authApiService.get(`/chat/token`,
            (chatData, headers) => {
                const chatClient = StreamChat.getInstance(chatData.key);
                const channel = chatClient.channel('team', this.props.project.id, {
                    // image: 'https://www.drupal.org/files/project-images/react.png',
                    name: this.props.project.name
                });
                //useChatContext();//.setActiveChannel(channel);

                this.setState({chatClient: chatClient, channel: channel});
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }
}

export default ChatBoard;