import React, { Component } from 'react';
import icon2x from '../../../../../../../../../../markup/img/link-icon-unknown@2x.png';
import icon3x from '../../../../../../../../../../markup/img/link-icon-unknown@3x.png';

class Empty extends Component {
    render() {
        return (
            <div className="group-wrapper">
                <table className="empty">
                    <tbody>
                        <tr>
                            <td>
                                <img src={icon2x} srcSet={`${icon2x} 2x, ${icon3x} 3x`} width="72" height="72" alt="Links"/>
                            </td>
                            <td>
                                <div className="text">
                                    Add link to see it here. <br/>
                                    Empty state description.
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
 
export default Empty;