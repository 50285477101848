import React, { Component } from 'react';
import NavigationGhosts from './navigationGhosts';
import GridGhost from './gridGhosts';

class ProjectsGhosts extends Component {
    render() { 
        return (
            <React.Fragment>
                <NavigationGhosts/>
                <GridGhost/>
            </React.Fragment>
        );
    }
}
 
export default ProjectsGhosts;