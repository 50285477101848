import adobeIllustrator from '../markup/img/file-icon-adobe-illustrator@2x.png';
import adobePhotoshop from '../markup/img/file-icon-adobe-photoshop@2x.png';
import adobeXd from '../markup/img/file-icon-adobe-xd@2x.png';
import eps from '../markup/img/file-icon-eps@2x.png';
import xls from '../markup/img/file-icon-excel@2x.png';
import figma from '../markup/img/file-icon-figma@2x.png';
import jpg from '../markup/img/file-icon-jpg@2x.png';
import pdf from '../markup/img/file-icon-pdf@2x.png';
import png from '../markup/img/file-icon-png@2x.png';
import sketch from '../markup/img/file-icon-sketch@2x.png';
import ppt from '../markup/img/file-icon-powerpoint@2x.png';
import svg from '../markup/img/file-icon-svg@2x.png';
import word from '../markup/img/file-icon-word@2x.png';
import unknown from '../markup/img/file-icon-unknown@2x.png';

function fileIcon(name) {
    name = name.toString().toLocaleLowerCase();
    if (/\.ai$/.test(name))
        return adobeIllustrator;
    if (/\.psd$/.test(name))
        return adobePhotoshop;
    if (/\.xd$/.test(name))
        return adobeXd;
    if (/\.eps$/.test(name))
        return eps;
    if (/(\.xls|\.xlsx)$/.test(name))
        return xls;
    if (/\.fig$/.test(name))
        return figma;
    if (/(\.jpg|\.jpeg)$/.test(name))
        return jpg;
    if (/\.pdf$/.test(name))
        return pdf;
    if (/\.png/.test(name))
        return png;
    if (/(\.ppt|\.pptx)$/.test(name))
        return ppt;
    if (/\.sketch$/.test(name))
        return sketch;
    if (/\.svg$/.test(name))
        return svg;
    if (/\.doc/.test(name))
        return word;
    return unknown;
}

function textSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function isFile(file) {
    return (file) ? /\.[0-9a-z]+$/i.test(file.name) : false;
}

function downloadFile(url, name) {
    var tag = document.createElement('a');
    tag.href = url;
    tag.download = name;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
}

export default {
    fileIcon,
    textSize,
    isFile,
    downloadFile
}