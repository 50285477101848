import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class Info extends Component {
    render() {
        return (
            <Modal show={this.props.show}>
                <div className="modal-content">

                    <Modal.Header className="flex-direction-column flex-center">
                        <h5 className="modal-title">{this.props.title}</h5>
                        <div className="modal-sub-title">{this.props.description}</div>

                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => this.props.onClose(e)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>

                    <Modal.Footer className="form-footer flex-center">
                        {this.okButton()}
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }

    okButton = () => {
        return this.props.okButtonTitle
            ? <button type="button" className="btn btn-outline-grey" onClick={this.props.onOk}>{this.props.okButtonTitle}</button>
            : '';
    }
}

export default Info;