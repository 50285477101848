import React, { Component } from 'react';
import Header from './header/header';
import Sheet from './sheet/sheet';

class Content extends Component {

    state = {
        readOnly: false
    }

    render() {
        return (
            <div className="project-content light">
                <Header
                    status = {this.props.project.status}
                    project={this.props.project}
                    onStatusChange={this.props.onStatusChange}
                    onShare={this.props.onShare}
                    onAddProjectLogo={this.props.onAddProjectLogo}
                    onProjectDelete={this.props.onProjectDelete}
                    readOnly={this.state.readOnly} />

                <div className="content">
                    <Sheet
                        project={this.props.project}
                        onClickAddElement={this.props.onClickAddElement}
                        onLinksSubCategotyDelete={this.props.onLinksSubCategotyDelete}
                        onFilesSubCategoryDelete={this.props.onFilesSubCategoryDelete}
                        onLinkEdit={this.props.onLinkEdit}
                        onLinkDelete={this.props.onLinkDelete}
                        onFileDelete={this.props.onFileDelete}
                        showFilesCategory={this.props.showFilesCategory}
                        showLinksCategory={this.props.showLinksCategory}/>
                </div>
            </div>
        );
    }
}

export default Content;