import React, { Component } from 'react';
import Header from './header/header';
import Block from '../../../../../shared/ghost/block';

class ContentGhosts extends Component {
    render() { 
        return (
            <div className="project-content light">
                <Header/>
                <div className="content">
                    <div className="project-sheet">
                        <div className="header">
                            <div className="content">
                                <Block className="w184 h44 dp"/>
                                <Block className="w90p h18 mt16 dp"/>
                                <Block className="w100p h18 mt6 dp"/>
                                <Block className="w80p h18 mt6 dp"/>
                                
                                <div className="mt32">
                                    <Block className="w106 h32 mr8"/>
                                    <Block className="w106 h32 mr8"/>
                                    <Block className="w106 h32"/>
                                </div>

                                <Block className="w182 h32 mt88"/>
                                <Block className="w90p h18 mt16 dp"/>
                                <Block className="w80p h18 mt6 dp"/>
                                
                                <Block className="w150 h28 mt32"/>
                                <div className="mt16">
                                    <Block className="w274 h72 mr27"/>
                                    <Block className="w274 h72 mr27"/>
                                    <Block className="w274 h72"/>
                                </div>

                                <Block className="w150 h28 mt32"/>
                                <div className="mt16">
                                    <Block className="w274 h72 mr27"/>
                                    <Block className="w274 h72 mr27"/>
                                    <Block className="w274 h72"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ContentGhosts;