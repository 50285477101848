import React, { Component } from 'react';
import ErrorServer from '../layout/shared/error/server/errorServer';
import LoginLayout from '../layout/login/layout';
import MainLayout from '../layout/main/layout';
import { connect } from 'react-redux';

class ServerErrorPage extends Component {

    render() {
        return this.layout();
    }

    layout() {
        return this.props.profile.id
            ? <MainLayout><ErrorServer /></MainLayout>
            : <LoginLayout><ErrorServer /></LoginLayout>;
    }
}

export default connect(
    (state) => ({
        profile: state.entities.profile
    }),
    (dispatch) => ({

    })
)(ServerErrorPage);