import React, { Component } from 'react';
import Wrapper from '../../../../../shared/field/wrapper';
import ValidationErrors from '../../../../../shared/field/errors/errors';

class TextAreaField extends Component {
    render() {
        return (
            <Wrapper>
                <div className="form-label">{this.labelText()}</div>
                <textarea
                    className={this.textareaClasses()}
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.text}
                    onChange={e => { this.props.onChange(e.target.value) }}
                    value={this.props.description} />
                <ValidationErrors errors={this.props.errors} />
            </Wrapper>
        );
    }

    labelText = () => {
        return this.props.label + (this.props.required ? " *" : "");
    }

    textareaClasses = () => {
        return (this.props.errors && this.props.errors.length > 0 ? 'border-error' : '') + ' description';
    }
}

export default TextAreaField;