import React, { Component } from 'react';
import ProjectCardGhost from './projectCardGhost';

class GridGhosts extends Component {
    render() { 
        return (
            <div className="project-list-content">
                <ProjectCardGhost/>
                <ProjectCardGhost/>
                <ProjectCardGhost/>
                <ProjectCardGhost/>
                <ProjectCardGhost/>
                <ProjectCardGhost/>
                <ProjectCardGhost/>
            </div>
        );
    }
}
 
export default GridGhosts;