
class LinkState {
    
    constructor() {
        this.clear();
    }

    clear() {
        this.form = {
            id: null,
            name: '',
            url: '',
            group: {
                id: null,
                name: ''
            },
            description: '',
        };
        this.errors = {
            form: {
                name: [],
                url: [],
                group: {
                    id: [],
                    name: []
                },
                description: []
            },
            summary: ''
        };
        this.group = {
            focused: false,
            list: []
        };
    }
}

export default LinkState;