import React, { Component } from 'react';
import logo2x from "../../../../markup/img/logo@2x.png";
import logo3x from "../../../../markup/img/logo@3x.png";
import profile2x from "../../../../markup/img/profile-img@2x.png";
import ProfilePopup from "./profile/profilePopup.jsx";

class ProjectsHeader extends Component {

    state = {
        showProfilePopup: false,
        profilePictureUrl: this.props.profile?.profilePictureUrl,
        defaultActiveTab: "account"
    }

    render() {
        return (
            <div className="dashboard-profile-box flex-start mt24">
                <img className="dashboard-profile" onClick={this.handleShowProfile} src={this.state.profilePictureUrl} alt="Profile"/>
                <div onClick={this.handleShowProfile} className="cursor-pointer">
                    <div className="dashboard-profile-name text-crop-dots mt5">{this.props.profile?.fullName}</div>
                    <div className="dashboard-profile-email text-crop-dots">{this.props.profile?.email}</div>
                </div>

                <ProfilePopup subscriptionData={this.props.subscriptionData} handleSubscriptionChange={this.props.handleSubscriptionChange} defaultActiveTab={this.state.defaultActiveTab} handleAddProfileLogo={this.handleAddProfileLogo} profile={this.props.profile} profilePictureUrl={this.state.profilePictureUrl} show={this.state.showProfilePopup} onClose={this.handleCloseProfilePopup} />
            </div>
        );
    }

    handleAddProfileLogo = (profileLogo) => {
        this.setState({profilePictureUrl: profileLogo.signedUrl})
    }

    handleShowProfile = () => {
        this.setState({showProfilePopup: true})
    }

    handleCloseProfilePopup = () => {
        this.setState({showProfilePopup: false, defaultActiveTab: "account"})
    }
}

export default ProjectsHeader;