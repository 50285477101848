import React, { Component } from 'react';

class Block extends Component {
    render() {
        return (
            <div className={'no-select no-outline ghost-btn ' + this.props.className}/>
        );
    }
}
 
export default Block;