import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

class DropdownMenu extends Component {

    state = {
        currentPage: 0
    }

    render() {
        return (
            this.props.show ?
                (<React.Fragment>
                    <div className="dorpdown-menu-bkg" onClick={this.onBkgClick}></div>
                    <div className={this.getClassNames()}>
                        {this.props.items.map(item => (
                            <button key={item.key} type="button" className="doc-details-context-menu-item btn"
                                    onClick={e => this.props.onItemClick(item.key)}>
                                <span className={this.getItemClassNames(item)}>{item.name}</span>
                            </button>
                        ))}
                    </div>
                </React.Fragment>)
                :
                ("")
        );
    }

    getClassNames = () => {
        return "dashboard-dropdown-menu " + this.props.classNames;
    }

    getItemClassNames = (item) => {
        return item.isAlert ? "doc-details-context-menu-item-remove btn" : "doc-details-context-menu-item btn";
    }

    onBkgClick = () => {
    }
}

export default withRouter(DropdownMenu);