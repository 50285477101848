import React, { Component } from 'react';
import img2x from '../../../../../../../../markup/img/down@2x.png';
import img3x from '../../../../../../../../markup/img/down@3x.png';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import DropdownToogle from  'react-bootstrap/DropdownToggle';
import projectService from '../../../../../../../../services/projectService';

class Status extends Component {
    state = {
        statuses: [
            {id: 1, status: 'Not Started'},
            {id: 2, status: 'In Progress'},
            {id: 3, status: 'On Hold'},
            {id: 4, status: 'Complete'}
        ]
    }

    render() {
        const RoleToggle = React.forwardRef(({ children, onClick }, ref) => (
            <span className="header-btn status-btn grey-border" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
                {children}
            </span>
        ));
        
        return (
            <ButtonGroup>
                <Dropdown className="sub-menu no-outline no-select">
                    <DropdownToogle as={RoleToggle}>
                         <span className={'status-circle ' + projectService.getStatusClass(this.props.status)}></span>
                         <span className="status-title">{this.props.status ? this.props.status.status : ''}</span>
                         <img className="dropdown-img-down" src={img2x} srcSet={`${img2x} 2x, ${img3x} 3x`} width="24" height="24" alt="Down"/>
                    </DropdownToogle>

                    <Dropdown.Menu>
                        {this.state.statuses.map(status => (
                            <Dropdown.Item className="no-select" href="#" key={status.id} onClick={e => this.props.onChange(status)}>
                                <span className={'status-circle ' + projectService.getStatusClass(status)}></span>
                                <span className="status-title">{status ? status.status : ''}</span>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup>
        );
    }
}
 
export default Status;