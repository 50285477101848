import React, { Component } from 'react';
import ProjectsHeader from "./projectsHeader";
import ProjectList from "./projectList";
import Content from "./content";
import authApiService from "../../../../services/authorizedApiHttpService";
import Info from "../../shared/popup/info";
import subscriptionService from "../../../../services/subscriptionService";

class Dashboard extends Component {

    constructor() {
        super();
        this.timer = setInterval(function () {
            this.getNotifications();
        }.bind(this), 3000);
    }

    state = {
        project: null,
        notifications: [],
        projectNotification: {
            task: false,
            docs: false,
            external: false,
            docIdList: []
        },
        showProjectCreateWarning: false
    }

    render() {
        return (
            <div className="dashboard">
                <div className="dashboard-sidebar">
                    <ProjectsHeader profile={this.props.profile} subscriptionData={this.props.subscriptionData} handleSubscriptionChange={this.props.handleSubscriptionChange} />
                    <ProjectList projectId={this.props.projectId} notifications={this.state.notifications} projects={this.props.projects} chatData={this.props.chatData} onProjectSelect={this.onProjectSelect} onSearchTextChange={this.props.onSearchTextChange} />
                    <div className="dashboard-new-project">
                        <button type="button"
                                onClick={this.onCreateProject}
                                className="btn dashboard-new-project-btn"
                                data-toggle="modal"
                                data-target="#create-project-modal">New project</button>
                    </div>
                </div>
                <div className="dashboard-line"></div>
                <div className="content-box h100p w100p">
                    <Content project={this.state.project} subscriptionData={this.props.subscriptionData} onUpdateProjectName={this.onUpdateProjectName} notification={this.state.projectNotification} profile={this.props.profile} />
                </div>

                <Info show={this.state.showProjectCreateWarning}
                      title="Project Creation Limit"
                      description="Please upgrade subscription to increase number of projects you can create"
                      onOk={this.onInfoClose}
                      onClose={this.onInfoClose}
                      okButtonTitle="Ok" />
            </div>
        );
    }

    onCreateProject = () => {
        if (subscriptionService.canCreateProject(this.props.subscriptionData)) {
            this.props.onClickCreateProject();
        } else {
            this.setState({showProjectCreateWarning: true});
        }
    }

    onInfoClose = () => {
        this.setState({showProjectCreateWarning: false});
    }

    onUpdateProjectName = (name) => {
        let project = { ...this.state.project };
        project.name = name;
        this.setState({project: project});
    }

    onProjectSelect = (project) => {
        this.setProjectNotification(project, this.state.notifications);
        this.setState({project: project});
    }

    getNotifications = () => {
        authApiService.get(`/notification`,
            (data, headers) => {
                this.setProjectNotification(this.state.project, data);
                this.setState({notifications: data});
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }

    setProjectNotification = (project, notifications) => {
        if (project) {
            let projectNotification = {
                docIdList: []
            };

            for (let i in notifications) {
                if (notifications[i].projectId === project.id) {
                    if (notifications[i].typeId === 1) {
                        projectNotification.task = true;
                    } else if (notifications[i].typeId === 2) {
                        projectNotification.docs = true;
                        projectNotification.docIdList.push(notifications[i].productId);
                    } else if (notifications[i].typeId === 3) {
                        projectNotification.external = true;
                    }
                }
            }

            this.setState({projectNotification: projectNotification})
        }
    }
}

export default Dashboard;