import React, { Component } from 'react';
import backArrow from "../../../../../markup/img/back-arrow.png";
import ProjectUser from "./projectUser";
import editIcon from "../../../../../markup/img/edit-icon.png";
import authApiService from "../../../../../services/authorizedApiHttpService";
import InvitePopup from "./invitePopup";
import pantramLogo from "../../../../../markup/img/pantram-logo.png";
import ProjectIcon from "../../project/list/popup/field/projectIcon";
import mixpanel from "../../../../../services/mixpanelService";
import xClose from "../../../../../markup/img/x_close.png";
import Info from "../../../shared/popup/info";
import subscriptionService from "../../../../../services/subscriptionService";

class ProjectSettings extends Component {

    constructor() {
        super();
        this.textarea = React.createRef();
    }

    state = {
        owner: 1,
        activeMenuItem: null,
        showProjectMenu: false,
        users: [],
        showInvitePopup: false,
        showProjectIconPopup: false,
        form: {
            color: '',
            iconName: '',
            projectId: this.props?.project?.id
        },
        editProjectName: false,
        projectName: this.props?.project?.name,
        showMemberInviteInfo: false
    }

    render() {
        return (
            <div>
                <div className="mt40 ml40">
                    <img className="cursor-pointer no-select" onClick={this.props.handleBackToContent} src={backArrow} alt="Docs list"/>
                </div>
                <div className="mt24 dashboard-settings-header-name no-select cursor-pointer">
                    <div style={{position: "relative"}} onClick={this.handleShowProjectIconPopup}>
                        {this.getLogo()}
                        <div className="edit-btn no-select">
                            <img className="margin-auto" src={editIcon} alt="edit"/>
                        </div>
                    </div>

                    <div className="dashboard-header-text">
                        {
                            this.state.editProjectName ?
                            <div>
                           <textarea className="editable-textarea"
                                     name="projectName"
                                     onChange={e => {
                                         this.onProjectNameChange(e.target.value)
                                     }}
                                     placeholder="Add project name"
                                     defaultValue={this.props.project.name}></textarea>
                                <button type="button" className="btn btn-primary" onClick={e => this.onSaveProjectName()}>Save</button>
                                <img className="ml10 cursor-pointer"
                                     onClick={this.onCloseProjectName}
                                     src={xClose}
                                     width="15" height="15" alt="Close" />
                            </div>
                            :
                            <div className="project-settings-title" onClick={this.onEditProjectName}>{this.props.project.name}</div>
                        }
                        <div className="project-settings-desc" onClick={this.handleShowProjectIconPopup}>Update project image</div>
                    </div>
                </div>
                {this.getProjectIconPopup()}
                <div className="flex-space-between">
                    <div className="mt40 w100p">
                        {this.state.users?.projectUsers?.map(projectUser => (
                            <ProjectUser key={projectUser.id + "_user"} projectUser={projectUser} projectUsersUpdated={this.projectUsersUpdated} roleId={this.state.users?.userRoleId} />
                        ))}
                        {this.state.users?.projectPendingUsers?.map(projectUser => (
                            <ProjectUser key={projectUser.id + "_pending"} projectPendingUser={projectUser} projectUsersUpdated={this.projectUsersUpdated} roleId={this.state.users?.userRoleId} />
                        ))}
                    </div>
                    <div className="project-users-desc">
                        <div className="project-role-name">Owner</div>
                        <div className="project-role-desc mt8">All features <u>including</u> adding members, erasing project</div>

                        <div className="project-role-name mt24">Collaborator</div>
                        <div className="project-role-desc mt8">All features <u>except</u> adding members, erasing project</div>
                    </div>
                </div>
                {this.state.users?.userRoleId === this.state.owner ?
                    (<div className="project-invite-user">
                        <button type="button"
                                onClick={this.handleInviteUser}
                                className="mt12 btn dashboard-new-project-btn"
                                data-toggle="modal"
                                data-target="#create-project-modal">Invite User
                        </button>
                    </div>)
                    :
                    (<div></div>)
                }
                <InvitePopup project={this.props.project} show={this.state.showInvitePopup} onClose={this.onInvitePopupClose} projectUsersUpdated={this.projectUsersUpdated} />
                <Info show={this.state.showMemberInviteInfo}
                      title="Member Limit"
                      description="Please upgrade subscription to invite more members"
                      onOk={this.onInfoClose}
                      onClose={this.onInfoClose}
                      okButtonTitle="Ok" />
            </div>
        );
    }

    onInfoClose = () => {
        this.setState({showMemberInviteInfo: false});
    }

    handleShowProjectIconPopup = () => {
        this.setState({showProjectIconPopup: !this.state.showProjectIconPopup})
    }

    projectUsersUpdated = () => {
        this.fetchProjectUsers();
    }

    async componentDidMount() {
        this.fetchProjectUsers();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.project.id !== this.props.project.id) {
            this.fetchProjectUsers();
        }
    }

    handleInviteUser = () => {
        if (subscriptionService.canInviteMember(this.props.subscriptionData)) {
            this.setState({showInvitePopup: true});
        } else {
            this.setState({showMemberInviteInfo: true});
        }
    }

    onInvitePopupClose = () => {
        this.setState({showInvitePopup: false});
    }

    getLogo = () => {
        return (this.props.project.iconName || this.props.project.color ?
                <div className="dashboard-project-setting-icon" style={{backgroundColor: this.props.project.color}}>
                    {this.props.project.iconName ?
                        <img className="dashboard-project-icon" src={`./img/projecticons/${this.props.project.iconName}`} alt=""/>
                        :
                        <div></div>}
                </div>
                :
                <img className="dashboard-project-setting-icon" src={pantramLogo} alt=""/>
        );
    }

    getProjectIconPopup = () => {
        return this.state.showProjectIconPopup && this.state.users?.userRoleId === this.state.owner ?
            (
                <div className="project-icon-popup">
                    <ProjectIcon onColorChange={this.handleColorChange} onIconChange={this.handleIconChange} />
                    <button type="button"
                            onClick={this.handleProjectIconChange}
                            className="mt12 btn project-icon-update-btn"
                            data-toggle="modal"
                            data-target="#create-project-modal">Update
                    </button>
                </div>
            )
            :
            ("")
    }

    handleColorChange = (color) => {
        const state = { ...this.state };
        state.form.color = color;
        this.setState(state);
    }

    handleIconChange = (icon) => {
        const state = { ...this.state };
        state.form.iconName = icon;
        this.setState(state);
    }

    handleProjectIconChange = () => {
        authApiService.put(`/project/${this.props.project.id}/icon`, this.state.form,
            (project, headers) => {
                this.setState({showProjectIconPopup: false})
                mixpanel.track("project.icon.update", project);
            },
            (message, errors) => {
            },
            (cancelToken) => { });
    }

    onProjectNameChange = (projectName) => {
        this.setState({projectName: projectName});
    }

    onCloseProjectName = () => {
        this.setState({editProjectName: false});
    }

    onEditProjectName = () => {
        this.setState({editProjectName: true});
    }

    onSaveProjectName = () => {
        let projectNameDto = { name: this.state.projectName };
        authApiService.put(`/project/${this.props.project.id}/name`, projectNameDto,
            (data, headers) => {
                this.setState({editProjectName: false});
                this.props.onUpdateProjectName(this.state.projectName);
            },
            (message, errors) => {
            });
    }

    fetchProjectUsers = () => {
        authApiService.get(`/project/${this.props.project.id}/user`,
            (users, headers) => {
                this.setState({users: users});
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }
}

export default ProjectSettings;