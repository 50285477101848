import React, { Component } from 'react';
import FullName from '../../../login/form/field/fullName';
import EmailField from '../../../login/form/field/email';
import RoleField from '../../../login/form/field/role';
import ForgotPassord from '../form/forgotPassword';
import Footer from '../../../login/form/footer/footer';
import CancelButton from '../../../login/form/button/cancel';
import Button from '../../../login/form/button/action';
import authHttpService from '../../../../../services/authorizedApiHttpService';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { profileFetched } from '../../../../../models/store/profile/profile';
import { produce } from 'immer';
import mixpanel from '../../../../../services/mixpanelService';

class Form extends Component {

    constructor() {
        super();
        this.loading = true;
    }

    state = {
        form: {
            fullName: '',
            email: '',
            roleId: null
        },
        errors: {
            form: {
                fullName: [],
                email: [],
                roleId: []
            },
            summary: ''
        }
    }

    async componentDidMount() {
        await authHttpService.get('/user/profile',
            (profile, headers) => {
                this.updateProfile(profile);
            },
            (message, errors) => {
                /* Show error popup */
            });

        this.loading = false;
    }

    render() {
        return (
            <div className="profile-form mx-auto border-8-shadow">
                <FullName name={this.state.form.fullName} onChange={this.handleEditFullName} errors={this.state.errors.form.fullName} />
                <EmailField email={this.state.form.email} onChange={this.handleEditEmail} errors={this.state.errors.form.email} />
                <RoleField onChange={this.handleEditRole} selectedId={this.state.form.roleId} />
                <ForgotPassord onClick={this.handleForgotPassword} />

                <Footer>
                    <CancelButton onCancel={this.handleCancel} />
                    <Button className={this.actionButtonClasses()} title="Save changes" onPress={this.handleSaveChanges} />
                </Footer>
            </div >
        );
    }

    handleEditFullName = (fullName) => {
        this.setState(produce(this.state, draftState => {
            draftState.form.fullName = fullName;
            draftState.errors.form.fullName = [];
            draftState.errors.summary = '';
        }));
    }

    handleEditEmail = (email) => {
        this.setState(produce(this.state, draftState => {
            draftState.form.email = email;
            draftState.errors.form.email = [];
            draftState.errors.summary = '';
        }));
    }

    handleEditRole = (roleId) => {
        this.setState(produce(this.state, draftState => {
            draftState.form.roleId = roleId;
            draftState.errors.form.roleId = [];
            draftState.errors.summary = '';
        }));
    }

    handleCancel = (e) => {
        this.props.history.goBack();
    }

    handleSaveChanges = (e) => {
        authHttpService.post(`/user/profile`, this.state.form,
            (data, headers) => {
                this.updateProfile(data);
            },
            (message, errors) => {
                this.setState(produce(this.state, draftState => {
                    draftState.form = errors;
                    draftState.errors.summary = (Object.keys(errors).length > 0) ? '' : message;;
                }));
            });
    }

    handleForgotPassword = (e) => {
        /* Send recovery password Email */
        authHttpService.post(`/user/profile/password`, { email: this.props.profile.email },
            (data, headers) => {
                /* Show success popup */
                this.props.onPasswordResetEmailSent();
            },
            (message, errors) => {
                /* Show error popup */
            });
    }

    actionButtonClasses = () => {
        return this.isChanged() ? '' : 'disabled';
    }

    isChanged = () => {
        if (this.props.profile.id) {
            return (this.props.profile.fullName !== this.state.form.fullName
                || this.props.profile.email !== this.state.form.email
                || this.props.profile.projectRoleId !== this.state.form.roleId);
        } else {
            return false;
        }
    }

    updateProfile = (profile) => {
        this.setState(produce(this.state, draftState => {
            draftState.form.fullName = profile.fullName;
            draftState.form.email = profile.email;
            draftState.form.roleId = profile.projectRoleId;
        }));
        this.props.profileFetched(profile);

        /* Identify user in Mixpanel */
        mixpanel.identifyUser(profile);
    }
}

export default connect(
    (state) => ({
        profile: state.entities.profile
    }),
    (dispatch) => ({
        profileFetched: (profile) => dispatch(profileFetched(profile))
    })
)(withRouter(Form));