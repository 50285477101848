import React, { Component } from 'react';
import CloseButton from '../button/close';
import { Modal } from 'react-bootstrap';
import TitleField from './field/title';
import LinkField from './field/link';
import DescriptionField from './field/description';
import GroupField from './field/group';
import ValidationSummary from '../../../../../shared/field/errors/summary';
import authApiService from '../../../../../../../services/authorizedApiHttpService';
import { withRouter } from 'react-router-dom';
import LinkGroup from '../../../../../../../models/link/group/linkGroup';
import mixpanel from '../../../../../../../services/mixpanelService';
import userProfileService from '../../../../../../../services/userProfileService';

class LinkPopup extends Component {

    constructor() {
        super();
        this.groupsSearchCancelToken = null;
    }

    render() {
        return (
            <Modal show={this.props.show} onExited={() => this.clear()}>
                <div className="modal-content">
                    <Modal.Header>
                        <h5 className="modal-title">Add new link</h5>
                        <CloseButton onClose={this.props.onClose} />
                    </Modal.Header>

                    <Modal.Body className="flex-direction-column">
                        <TitleField title={this.props.link.form.name} onNameChange={this.handleChangeName} errors={this.props.link.errors.form.name} />
                        <LinkField link={this.props.link.form.url} onChange={this.handleChangeUrl} errors={this.props.link.errors.form.url} />
                        <GroupField group={this.props.link.form.group}
                            focused={this.props.link.group.focused}
                            list={this.props.link.group.list}
                            onGroupSelect={this.handleGroupSelect}
                            onSearchByName={this.handleGroupSearchByName}
                            onBecomeFocused={this.handleGroupBecomeFocused}
                            onDidMount={this.handleGroupMounted}
                            errors={this.props.link.errors.form.group ? this.props.link.errors.form.group.name : []} />
                        <DescriptionField description={this.props.link.form.description} onChange={this.handleChangeDescription} errors={this.props.link.errors.form.description} />
                        {(this.props.link.errors.summary && this.props.link.errors.summary.length > 0) && <ValidationSummary message={this.props.link.errors.summary} />}
                    </Modal.Body>

                    <Modal.Footer className="form-footer flex-start">
                        <button type="button" className="btn btn-outline-grey" data-dismiss="modal" onClick={this.props.onClose}>Cancel</button>
                        <button type="button" className="btn" onClick={e => {
                            if (this.props.link.form.id) this.handleOnClickSaveLink(e);
                            else this.handleOnClickAddLink(e);
                        }}
                        >
                            {this.props.link.form.id ? 'Save changes' : 'Add link'}
                        </button>
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }

    handleChangeName = (name) => {
        this.props.link.form.name = name;
        this.props.link.errors.form.name = [];
        this.forceUpdate();
    }

    handleChangeUrl = (url) => {
        this.props.link.form.url = url;
        this.props.link.errors.form.url = [];
        this.forceUpdate();
    }

    handleChangeDescription = (description) => {
        this.props.link.form.description = description;
        this.props.link.errors.form.description = [];
        this.forceUpdate();
    }

    handleOnClickAddLink = (e) => {
        authApiService.post('/project/' + this.props.project.id + '/link', this.props.link.form,
            (link, headers) => {
                mixpanel.track("link.created", { userId: userProfileService.profile().id, link: this.props.link.form });
                this.props.onAddLink(link, new LinkGroup(link.groupId, this.props.link.form.group.name));
            },
            (message, errors) => {
                this.props.link.errors.form = errors;
                this.props.link.errors.form.summary = (Object.keys(errors).length > 0) ? "" : message;
                this.forceUpdate();
            },
            (cancelToken) => { });
    }

    handleOnClickSaveLink = (e) => {
        authApiService.put('/project/' + this.props.project.id + '/link/' + this.props.link.form.id, this.props.link.form,
            (link, headers) => {
                this.props.onEditLink(link, new LinkGroup(link.groupId, this.props.link.form.group.name));
            },
            (message, errors) => {
                this.props.link.errors.form.summary = (Object.keys(errors).length > 0) ? "" : message;
                this.forceUpdate();
            });
    }

    clear() {
        this.props.link.clear();
    }


    /* Handle Group changes */
    handleGroupMounted = () => {
        this.requestLinkGroups('');
    }

    handleGroupBecomeFocused = (focused) => {
        this.props.link.group.focused = focused;
        this.forceUpdate();
    }

    handleGroupSelect = (group) => {
        this.props.link.form.group.id = group.id;
        this.props.link.form.group.name = group.name;
        this.props.link.errors.form.group.id = [];
        this.props.link.errors.form.group.name = [];
        this.forceUpdate();
        this.requestLinkGroups(group.name);
    }

    handleGroupSearchByName = (searchName) => {
        this.props.link.form.group.id = null;
        this.props.link.form.group.name = searchName;
        this.forceUpdate();
        this.requestLinkGroups(searchName);
    }

    requestLinkGroups(searchName) {
        if (this.groupsSearchCancelToken) {
            this.groupsSearchCancelToken.cancel();
        }

        authApiService.get('/project/' + this.props.project.id + '/link/groups?s=' + (searchName ? searchName : ''),
            (groups, headers) => {
                this.props.link.group.list = groups.map(group => new LinkGroup().assign(group));
                this.forceUpdate();
            },
            (message, errors) => {
                this.props.link.errors.form.group.id = [];
                this.props.link.errors.form.group.name = [];
                this.props.link.errors.form.summary = (Object.keys(errors).length > 0) ? "" : message;
                this.forceUpdate();
            },
            (cancelToken) => {
                this.groupsSearchCancelToken = cancelToken;
            });
    }
}

export default withRouter(LinkPopup);