import React, { Component } from 'react';
import ErrorNotFound from '../layout/shared/error/client/errorNotFound';
import LoginLayout from '../layout/login/layout';
import MainLayout from '../layout/main/layout';
import { connect } from 'react-redux';

class NotFoundPage extends Component {

    render() {
        return this.layout();
    }

    layout = () => {
        return this.props.profile.id
            ? <MainLayout><ErrorNotFound /></MainLayout>
            : <LoginLayout><ErrorNotFound /></LoginLayout>;
    }
}

export default connect(
    (state) => ({
        profile: state.entities.profile
    }),
    (dispatch) => ({

    })
)(NotFoundPage);