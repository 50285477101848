const MB = 1048576;
const GB = 1073741824;

function canCreateProject(subscriptionData) {
    return subscriptionData.projectCount < getProjectLimit(subscriptionData.priceIndex);
}

function canDowngradeSubscriptionProject(subscriptionData, priceIndex) {
    return subscriptionData.projectCount <= getProjectLimit(priceIndex);
}

function canDowngradeSubscriptionMember(subscriptionData, priceIndex) {
    return subscriptionData.memberCount <= getMemberLimit(priceIndex);
}

function canDowngradeSubscriptionStorage(subscriptionData, priceIndex) {
    return subscriptionData.usedStorageSize <= getStorageLimit(priceIndex);
}

function canInviteMember(subscriptionData) {
    return subscriptionData.memberCount < getMemberLimit(subscriptionData.priceIndex);
}

function canUploadFile(subscriptionData) {
    return subscriptionData.usedStorageSize < getStorageLimit(subscriptionData.priceIndex);
}

function byteToMb(b) {
    return b ? Math.floor(b / MB) : 0;
}

function getLeftMB(subscriptionData) {
    let totalSize = getStorageLimit(subscriptionData.priceIndex);

    return Math.ceil((totalSize - subscriptionData.usedStorageSize) / MB);
}

function getProjectLimit(priceIndex) {
    if (priceIndex == 1) {
        return 3;
    } if (priceIndex == 2) {
        return 15;
    } else {
        return 999999999;
    }
}

function getStorageLimit(priceIndex) {
    if (priceIndex == 1) {
        return GB;
    } if (priceIndex == 2) {
        return GB * 50;
    } else {
        return GB * 150;
    }
}

function getMemberLimit(priceIndex) {
    if (priceIndex == 1) {
        return 3;
    } if (priceIndex == 2) {
        return 30;
    } else {
        return 999999999;
    }
}

export default {
    canCreateProject,
    canInviteMember,
    canUploadFile,
    byteToMb,
    getLeftMB,
    canDowngradeSubscriptionProject,
    canDowngradeSubscriptionMember,
    canDowngradeSubscriptionStorage
}