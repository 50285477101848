import axios from 'axios';

async function put(uri, data, options, fData, fErrors) {
    try {
        const response = await axios.put(uri, data, options);
        fData(response.data);
    } catch (ex) {
        fErrors(ex);
    }
}

export default {
    put
}