import React, { Component } from 'react';

class ForgotPassord extends Component {
    render() {
        return (
            <div className="change-password" onClick={this.props.onClick}>{this.props.title ? this.props.title : 'Change password'}</div>
        );
    }
}

export default ForgotPassord;