import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'enums',
    initialState: {
        actionTypes: [],
        actionStatuses: []
    },
    reducers: {
        actionTypesFetched: (enums, action) => {
            enums.actionTypes = action.payload;
        },
        actionStatusesFetched: (enums, action) => {
            enums.actionStatuses = action.payload;
        }
    }
});

export default slice.reducer;
export const { actionTypesFetched, actionStatusesFetched } = slice.actions;