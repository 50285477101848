import React, { Component } from 'react';
import Layout from '../layout/main/layout';
import CreateProjectPopup from '../layout/main/project/list/popup/createProject';
import authApiService from '../../services/authorizedApiHttpService';
import SearchBy from '../../models/searchBy';
import GridPlaceholder from '../layout/main/project/list/grid/placeholder';
import ProjectDeletePopup from '../layout/main/project/builder/popup/confirmation/projectDeletePopup';
import ProjectsGhosts from '../layout/main/project/list/ghosts/projectsGhosts';
import { connect } from 'react-redux';
import { profileFetched } from '../../models/store/profile/profile';
import Dashboard from '../layout/main/dashboard/dashboard';
import mixpanel from '../../services/mixpanelService';
import {StreamChat} from "stream-chat";
import subscriptionService from "../../services/subscriptionService";

class DashboardPage extends Component {

    constructor() {
        super();
        this.loading = true;
        this.searchFilters = {
            text: null,
            by: SearchBy.creationDate
        };
        this.fetchProjectsCancelToken = null;
    }

    state = {
        showCreateProjectPopup: false,
        showDeleteProjectPopup: false,
        projectToDelete: { id: 0, name: '' },
        projects: [],
        profile: {},
        chatData: {},
        subscriptionData: {}
    }

    async componentDidMount() {
        this.loading = true;
        authApiService.get('/user/profile',
            (profile, headers) => {
                this.state.profile = profile;
                this.props.profileFetched(profile);
                this.getChatData();

                /* Identify user in Mixpanel */
                mixpanel.identifyUser(profile);
            },
            (message, errors) => {
                this.loading = false;
            },
            (cancelToken) => {
                this.fetchProjectsCancelToken = cancelToken;
            });
    }

    render() {
        return (
            <React.Fragment>
                {this.getContentBlocks()}
                <CreateProjectPopup show={this.state.showCreateProjectPopup} onClose={this.handleModalCloseCreateProject} onProjectCreated={this.handleProjectCreated} />
                <ProjectDeletePopup show={this.state.showDeleteProjectPopup} project={this.state.projectToDelete} onConfirm={this.handleProjectDeleteConfirm} onCancel={this.handleProjectDeleteCancel} onClose={this.handleProjecDeleteClose} />
            </React.Fragment>
        );
    }

    getContentBlocks() {
        if (this.loading) {
            /* Show ghosts */
            return <Layout>
                <ProjectsGhosts />
            </Layout>;
        } else if (this.state.projects.length === 0 && this.isEmptySearchString()) {
            return <Layout>
                <GridPlaceholder onClickCreateProject={this.handleNavClickCreateProject} />
            </Layout>;
        } else {
            let projectId = this.props.match.params.projectId;
            if (!projectId && this.state.projects?.length > 0) {
                projectId = this.state.projects[0].id;
            }
            return <Dashboard projectId={projectId} profile={this.state.profile} chatData={this.state.chatData} projects={this.state.projects} subscriptionData={this.state.subscriptionData} handleSubscriptionChange={this.handleSubscriptionChange} onClickCreateProject={this.handleNavClickCreateProject} onClickCancelCreateProject={this.handleClickCancelCreateProject} onSearchTextChange={this.handleSearch} />;
        }
    }

    handleSubscriptionChange = () => {
        this.fetchSubscriptionData();
    }

    isEmptySearchString() {
        return (this.searchFilters.text && 0 === this.searchFilters.text) || !this.searchFilters.text;
    }

    handleSearch = (text) => {
        this.setSeachText(text);
        this.fetchProjects();
    }

    handleSearchTextChange = (text) => {
        this.setSeachText(text);
    }

    setSeachText(text) {
        this.searchFilters.text = text;
        this.fetchProjects();
    }

    handleNavClickCreateProject = () => {
        this.setShowCreateProjectPopup(true);
    }

    handleModalCloseCreateProject = () => {
        this.setShowCreateProjectPopup(false);
    }

    handleClickCancelCreateProject = () => {
        this.setShowCreateProjectPopup(false);
    }

    setShowCreateProjectPopup(show) {
        let { showCreateProjectPopup, searchFilters, projects } = this.state;
        showCreateProjectPopup = show;
        this.setState({ showCreateProjectPopup, searchFilters, projects });
    }

    handleProjectCreated = (project) => {
        this.fetchProjects();
        this.fetchSubscriptionData();
    }

    handleProjectDelete = (project) => {
        let state = { ...this.state };
        state.showDeleteProjectPopup = true;
        state.projectToDelete = project;
        this.setState(state);
    }

    handleProjectDeleteConfirm = (project) => {
        authApiService.delete(`/project/${project.id}`,
            (data, headers) => {
                let state = { ...this.state };
                state.projects = state.projects.filter(p => p.id !== project.id);
                state.showDeleteProjectPopup = false;
                this.setState(state);
                this.showDeleteProjectPopup(false);
            },
            (message, errors) => {
                /* Show popup with error */
                this.showDeleteProjectPopup(false);
            });
    }

    handleProjectDeleteCancel = (e) => {
        this.showDeleteProjectPopup(false);
    }

    handleProjecDeleteClose = (e) => {
        this.showDeleteProjectPopup(false);
    }

    handleSortOptionChange = (searchBy) => {
        this.searchFilters.by = searchBy;
        this.fetchProjects();
    }

    fetchProjects = () => {
        if (this.fetchProjectsCancelToken) {
            this.fetchProjectsCancelToken.cancel();
        }
        authApiService.post('/projects', this.searchFilters,
            (projects, headers) => {
                this.loading = false;
                const state = { ...this.state };
                state.projects = projects;
                this.setState(state);
            },
            (message, errors) => {
                this.loading = false;
            },
            (cancelToken) => {
                this.fetchProjectsCancelToken = cancelToken;
            });
    }

    fetchSubscriptionData = () => {
        authApiService.get(`/subscription-projects-data`,
            (data, headers) => {
                this.setState({subscriptionData: data})
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }

    getChatData = () => {
        this.fetchSubscriptionData();
        //this.fetchProjects(this.searchFilters);
        // TODO: CHAT
        authApiService.get(`/chat/token`,
            (chatData, headers) => {
                this.fetchProjects(this.searchFilters);
                this.setState({chatData: chatData});

                const chatClient = StreamChat.getInstance(chatData.key);
                const userToken = chatData.userToken;

                chatClient.connectUser(
                    {
                        id: chatData.userId,
                        name: this.state.profile.fullName,
                        image: this.props.profile?.profilePictureUrl,
                    },
                    userToken,
                );
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }

    showDeleteProjectPopup(show) {
        let state = { ...this.state };
        state.showDeleteProjectPopup = show;
        this.setState(state);
    }
}


export default connect(
    (state) => ({
        profile: state.entities.profile
    }),
    (dispatch) => ({
        profileFetched: (profile) => dispatch(profileFetched(profile))
    })
)(DashboardPage);