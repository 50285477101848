import React, { Component } from 'react';
import { Modal, ModalFooter, ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import CloseButton from '../button/close';
import DragAndDropArea from './dragandroparea/drugAndDropArea';
import FileItem from './item/fileItem';
import UserLogoUploadProcessor from './model/userLogoUploadProcessor';
import { withRouter } from 'react-router-dom';

class ProfileLogoPopup extends Component {

    constructor() {
        super();
        this.fileProcessor = new UserLogoUploadProcessor();
    }

    render() {
        return (
            <Modal id="add-file-modal" className="details-modal" show={this.props.show} onExited={this.clear}>
                <div className="modal-content">
                    <ModalHeader className="flex-direction-column">
                        <h5 className="modal-title">{this.getTitle()}</h5>
                        <span>Add profile logo</span>
                        <CloseButton onClose={this.props.onClose} />
                    </ModalHeader>

                    <ModalBody className="flex-direction-column">
                        <DragAndDropArea onFileDrop={this.handleFileDrop} />
                        <div className="project-logo-description"><b>Format: </b>PNG, JPEG or SVG.</div>
                        <div className="project-logo-description"><b>Size: </b>300x300 or larger recommended, up to 10MB</div>
                        <br/>
                        <div className="files-wrapper">
                            {this.getUploadFile()}
                        </div>
                    </ModalBody>

                    <ModalFooter className="form-footer flex-start">
                        <button type="button" className="btn btn-outline-grey" data-dismiss="modal" onClick={e => this.props.onClose(e)}>Cancel</button>
                        <button type="button" className={this.fileProcessor.isFileUploaded() ? 'btn' : 'btn disabled'} onClick={e => this.handleAddProjectLogo(e)}>Add file</button>
                    </ModalFooter>
                </div>
            </Modal>
        );
    }

    getUploadFile = () => {
        return this.fileProcessor.uploadFile ?
            <FileItem uploadFile={this.fileProcessor.uploadFile} onUploadStatusUpdate={this.handleUploadStatusUpdate} onCancel={this.handleCancelFileUpload} onDelete={this.handleDeleteFileUpload} onRetry={this.handleRetryFileUpload} />
            : "";
    }

    getTitle = () => {
        return "Upload profile logo";
    }

    onUploadChange = () => {
        this.forceUpdate();
    }

    handleFileDrop = (file) => {
        this.fileProcessor.scheduleUpload(file, this.props.match.params.projectId);
        this.forceUpdate();
    }

    handleUploadStatusUpdate = (uploadFile) => {
        this.forceUpdate();
    }

    handleCancelFileUpload = (uploadFile) => {
        uploadFile.cancelToken.cancel();
        this.removeFile(uploadFile);
    }

    handleDeleteFileUpload = (uploadFile) => {
        this.removeFile(uploadFile);
    }

    handleRetryFileUpload = (uploadFile) => {
        uploadFile.error = false;
        this.fileProcessor.startUpload(uploadFile);
        this.forceUpdate();
    }

    removeFile = (uploadFile) => {
        this.fileProcessor.uploadFile = null;
        this.forceUpdate();
    }

    handleAddProjectLogo = (e) => {
        if (this.fileProcessor.uploadFile?.complete) {
            this.fileProcessor.sendConfirmationAboutUploadFiles(
                this.props.match.params.projectId,
                (userLogo) => {
                    this.props.onAddUserLogo(userLogo);
                },
                (message, errors) => {
                    /* Show error popup */
                });
        }
    }

    clear = () => {
        this.fileProcessor.clear();
    }
}

export default withRouter(ProfileLogoPopup);