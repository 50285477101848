import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import CloseButton from '../button/close';
import { popupClosed, popupExited, popupUpdated } from '../../../../../../../models/store/popup/linkDetails';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import Comments from './comments/Comments';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import LinkPopupHeader from '../link/linkPopupHeader'

class LinkDetailsPopup extends Component {

    constructor(props) {
        super(props);
        this.linkPopupHeader = React.createRef();
    }

    state = {
        description: null,
        form: {
            additionalInstructions: "",
            linkId: null,
            memberIds: []
        },
        errors: {
            form: {
                additionalInstructions: [],
                linkId: [],
                memberIds: []
            },
            summary: ""
        },
        members: []
    }

    render() {
        return (
            <Modal className="request-subject edit-action" show={this.props.popup.show} onExited={this.props.popupExited}>
                <div className="modal-content request-subject edit-action">
                    <Modal.Header>
                        {this.linkHeader()}
                        <CloseButton onClose={this.popupClosed} />
                    </Modal.Header>

                    <Modal.Body className="flex-direction-column action-modal-body">
                        <div className="form-field-wrapper">
                            <div className="form-label">Description</div>
                            <textarea className="description"
                                      name="description"
                                      onChange={e => { this.onDescriptionChange(e.target.value) }}
                                      placeholder="Enter link description"
                                      defaultValue={this.getDescription()}></textarea>
                        </div>
                        <div>
                            <button type="button" className="btn file-detail-button" onClick={e => this.openInNewTab(this.props.popup.link.url)}>
                                <span className="title">Go to link</span>
                            </button>
                        </div>

                        <div>
                            <Tabs className="file-details-tab" defaultActiveKey="comments" transition={false}>
                                <Tab eventKey="comments" title="Comments">
                                    <div className="scrollable-themes-wrapper mt24 mh320">
                                        <Comments link={this.props.popup.link} onCommentsUpdated={this.onCommentsUpdated} />
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="form-footer flex-start">
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }

    openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    onCommentsUpdated = (link) => {
        this.props.onCommentsUpdated(link);
    }

    onLinkGroupChange = (link, group) => {
        this.props.onLinkGroupChange(link, group);
    }

    handleDownload = (e) => {

    }

    linkHeader = () => {
        return this.props.popup.link ? <LinkPopupHeader ref={this.linkPopupHeader} onLinkGroupChange={this.onLinkGroupChange} link={this.props.popup.link} projectId={this.props.match.params.projectId} /> : "";
    }

    popupClosed = (popup, action) => {
        this.linkPopupHeader.current.popupClosed(this.state.description);
        this.props.popupClosed(popup, action);
    }

    getDescription = () => {
        if (this.props.popup.link) {
            this.state.description = this.props.popup.link.description;
            return this.props.popup.link.description;
        }

        return "";
    }

    onDescriptionChange = (description) => {
        this.state.description = description;
    }

    handleCloseLinksPopup = (e) => {
        this.showLinkPopup(false);
    }

    showLinkPopup(show) {
        let state = { ...this.state };
        state.showLinkPopup = show;
        this.setState(state);
    }
}

export default connect(
    (state) => ({
        popup: state.entities.popups.linkDetails
    }),
    (dispatch) => ({
        popupClosed: () => dispatch(popupClosed()),
        popupExited: () => dispatch(popupExited()),
        popupUpdated: (action) => dispatch(popupUpdated(action))
    })
)(withRouter(LinkDetailsPopup));