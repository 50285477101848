import React, {Component} from 'react';
import FieldWrapper from '../../../../shared/field/wrapper';
import Password from './password'

class SignIn extends Component {

    render() {
        return (
            <FieldWrapper>
                <Password onChange={this.props.onChange} errors={this.props.errors}/>
                <div className="forgot-password text-align-right">
                    <a href="/forgot-password" className="change-password">Forgot password?</a>
                </div>
            </FieldWrapper>
        );
    }

}

export default SignIn;