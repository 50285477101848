import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'members',
    initialState: {
        prefetched: false,
        list: {}
    },
    reducers: {
        membersFetched: (members, action) => {
            members = {
                prefetched: false,
                list: {}
            };
            members.list[action.payload.projectId] = action.payload.members;
            members.prefetched = true;
            return members;
        }
    }
});

export default slice.reducer;
export const { membersFetched } = slice.actions;