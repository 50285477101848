import React, { Component } from 'react';
import FieldWrapper from '../../../../../shared/field/wrapper';
import ValidationErrors from '../../../../../shared/field/errors/errors';

class Description extends Component {
    render() {
        const { errors } = this.props;
        return (
            <FieldWrapper>
                <div className="form-label">Description</div>
                <textarea onChange={e => { this.props.onChange(e.target.value) }}
                    className={'project-description ' + (errors && errors.length > 0 ? 'border-error' : '')}
                    name="project-description"
                    placeholder="Add description"
                    value={this.props.value} />
                <ValidationErrors errors={this.props.errors} />
            </FieldWrapper>
        );
    }
}

export default Description;