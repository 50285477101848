import React, { Component } from 'react';
import authApiService from "../../../../services/authorizedApiHttpService";

class DashboardMenuItem extends Component {

    componentDidMount() {
        if (this.props.isActive) {
            this.props.onMenuItemClick(this)
        }
    }

    state = {
        isActive: null
    }

    render() {
        return this.props.visible ?
                (
                    <div className={this.itemClasses()} onClick={this.handleClick}>
                        <span className="dashboard-menu-item-text no-select">{this.props.children}</span>
                        <div className={this.notificationClasses()}></div>
                    </div>
                )
                :
                (<div></div>);
    }

    itemClasses = () => {
        return "dashboard-menu-item no-select" +
            (this.state.isActive ? " dashboard-menu-item-active" : "");
    }

    checkedIconClasses = () => {
        return "dashboard-menu-checked-icon" +
            (this.state.isActive ? "" : " v-hidden");
    }

    notificationClasses = () => {
        return "dashboard-menu-item-notification no-select" +
            (this.props.hasNotification ? "" : " v-hidden");
    }

    handleClick = (e) => {
        this.props.onMenuItemClick(this)
    }

    setActive = (isActive) => {
        this.setState({isActive: isActive});

        if (isActive && this.props.hasNotification) {
            authApiService.post(`/project/${this.props.projectId}/notification/read/${this.props.itemId}`,
                (data, headers) => {
                },
                (message, errors) => {
                    /* Show popup with error */
                });
        }
    }
}

export default DashboardMenuItem;