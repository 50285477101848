import React, { Component } from 'react';
import ValidationErrors from '../../../../../../shared/field/errors/errors';
import Wrapper from '../../../../../../shared/field/wrapper';

class Description extends Component {
    render() {
        const {errors} = this.props;
        return (
            <Wrapper>
                <div className="form-label">Description</div>
                <textarea 
                    className={this.textareaClasses()}
                    name="description" 
                    placeholder="Link description"
                    onChange={e => {this.props.onChange(e.target.value)}}
                    value={this.props.description}/>
                <ValidationErrors errors={errors}/>
            </Wrapper>
        );
    }

    textareaClasses() {
        return (this.props.errors && this.props.errors.length > 0 ? 'border-error' : '') + ' description';
    }
}
 
export default Description;