import React, { Component } from 'react';
import PasswordField from '../../../../login/form/field/password/password';
import FieldWrapper from '../../../../shared/field/wrapper';

class RepeatPassword extends Component {
    render() {
        return (
            <FieldWrapper>
                <PasswordField title="Repeat password *" onChange={this.props.onChange} errors={this.props.errors} />
            </FieldWrapper>
        );
    }
}

export default RepeatPassword;