import React, { Component } from 'react';
import fileService from '../../../../../../../../../../services/fileService';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { requestedForFile } from '../../../../../../../../../../models/store/popup/createAction';
import { openPopup, popupFileUpdated } from '../../../../../../../../../../models/store/popup/detailsAction';
import {withRouter} from 'react-router-dom';
import {Badge} from "react-bootstrap";
import downloadImg from "../../../../../../../../../../markup/img/icon-download-cloud@2x.png"
import commentImg from "../../../../../../../../../../markup/img/icon-chat.png"
import newCommentImg from "../../../../../../../../../../markup/img/icon-chat-new.png"
import authApiService from "../../../../../../../../../../services/authorizedApiHttpService";

class File extends Component {

    constructor() {
        super();
        this.downloadLinkRef = React.createRef();
    }

    render() {
        return (
            <div className="block" onClick={this.handleClick}>
                <img className="icon" src={fileService.fileIcon(this.props.file.name)} width="48" height="48" alt="Icon" />

                <div className="text">
                    <div className="title" style={{wordBreak: "break-all", fontSize: "18px", fontWeight: "600"}}>
                        {this.props.file.name}
                    </div>

                    <div className="file-description">
                        {this.props.file?.description}
                    </div>
                </div>
                {this.subMenu()}
                <div className="card-details">
                    <img className="icon fileDownload" src={downloadImg} onClick={this.handleDownload} alt="download" />
                    <span className="card-download-text" onClick={this.handleDownload}>Download</span>
                    {this.setCommentIcon()}
                    {this.isApproved()}
                </div>
            </div>
        );
    }

    handleDownload = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        authApiService.get(this.props.file.url,
            (download, headers) => {
                fileService.downloadFile(download.signedUrl, download.fileName);
            },
            (message, errors) => {
                /* Show error popup with some message */
            },
            (cancelToken) => { });
    }

    setCommentIcon = () => {
        if (this.props?.file?.hasUnreadComments) {
            return <img className="icon fileComment" src={newCommentImg} alt="comments unread"></img>
        }
        return <img className="icon fileComment" src={commentImg} alt="comments read"></img>
    }

    subMenu = () => {
        const Toggle = React.forwardRef(({ children, onClick }, ref) => (
            <span className="no-outline no-select" ref={ref} onClick={e => { e.preventDefault(); onClick(e); }}>
                {children}
            </span>
        ));

        return this.props.readOnly ? ''
            : <ButtonGroup onClick={e => e.stopPropagation()} className="action-btn">
                <Dropdown className="sub-menu">
                    <Dropdown.Toggle as={Toggle}>
                        <span className="more-icon-wrapper">
                            <span className="more-icon"></span>
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-right">
                        {this.menuItems().map((item, index) =>
                            <Dropdown.Item key={index} className="no-select" href="/#" onClick={e => { e.preventDefault(); item.handler(this.props.file) }}>{item.title}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup >
    }

    menuItems = () => {
        let items = [
            { title: "Delete", handler: this.props.onDelete }
        ];
        /* If Builder page, than show request menu items */
        if (this.props.location.pathname.startsWith('/builder/project/') || this.props.location.pathname.startsWith('/project/')) {
            let requestMenuItems = [
                { title: "Request approval", handler: this.handleRequestApproval },
                { title: "Request feedback", handler: this.handleRequestFeedback }
            ];
            items = requestMenuItems.concat(items);
        }
        return items;
    }

    isApproved = () => {
        if (this.props.file?.isApproved) {
            return <Badge variant="success" className="card-badge">approved</Badge>
        }
        return ""
    }

    handleRequestApproval = (file) => {
        let approval = this.props.actionTypes.filter(type => type.name === "APPROVAL").shift();
        this.props.requestedForFile({ actionType: approval, file: file });
    }

    handleRequestFeedback = (file) => {
        let feedback = this.props.actionTypes.filter(type => type.name === "FEEDBACK").shift();
        this.props.requestedForFile({ actionType: feedback, file: file });
    }

    handleClick = (e) => {
        let fileDetails = this.props.actionTypes.filter(type => type.name === "FILE_DETAILS").shift();
        this.props.openPopup({ actionType: fileDetails, file: this.props.file });
    }
}

export default connect(
    (state) => ({
        createPopup: state.entities.popups.createAction,
        detailsPopup: state.entities.popups.detailsAction,
        actionTypes: state.entities.enums.actionTypes,
        profile: state.entities.profile
    }),
    (dispatch) => ({
        requestedForFile: (file) => dispatch(requestedForFile(file)),
        openPopup: (file) => dispatch(openPopup(file)),
        popupFileUpdated: (file) => dispatch(popupFileUpdated(file)),
    })
)(withRouter(File));