import React, {Component} from 'react';
import Board from 'react-trello'
import authApiService from '../../services/authorizedApiHttpService';
import CardPopup from '../layout/main/dashboard/task/cardPopup';
import NewCardForm from  '../layout/main/dashboard/task/newCardForm'
import NewLaneForm from  '../layout/main/dashboard/task/newLaneForm'
import plus from "../../markup/img/plus_grey.png";
import Card from '../layout/main/dashboard/task/card';

class TaskBoardPage extends Component {

    constructor() {
        super();

        this.laneIdMap = {};
        this.cardIdMap = {};
    }

    state = {
        board: {
            lanes: []
        },
        showCardPopup: false,
        card: {}
    }

    componentDidMount() {
        this.fetchBoard();
    }

    NewLaneSection = ({t, onClick}) => {
        return (
            <React.Fragment>
                <button className="board-lane-add-btn mt5" onClick={onClick}>
                    <img src={plus} onClick={onClick} width="14" height="14" alt="Add list" />
                </button>
                <span onClick={onClick} className="board-add-list-text cursor-pointer">Add list</span>
            </React.Fragment>
        );
    }

    AddCardLink = ({onClick, t, laneId}) => {
        return (
            <React.Fragment>
                <img src={plus} onClick={onClick} width="14" height="14" alt="New item" className="cursor-pointer" />
                <span onClick={onClick} className="board-add-list-text cursor-pointer">New item...</span>
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className="task-board">
                <Board
                    components={{
                        NewCardForm: NewCardForm,
                        NewLaneSection: this.NewLaneSection,
                        NewLaneForm: NewLaneForm,
                        AddCardLink: this.AddCardLink,
                        Card: Card
                    }}

                    data={this.state.board}
                    draggable
                    collapsibleLanes
                    editable

                    id="EditableBoard1"

                    onCardAdd={this.onCardAdd}
                    onCardUpdate={this.onCardUpdate}
                    onCardDelete={this.onCardDelete}
                    onCardMoveAcrossLanes={this.onCardMoveAcrossLanes}
                    onCardClick={this.onCardClick}

                    canAddLanes
                    editLaneTitle

                    onLaneAdd={this.onLaneAdd}
                    onLaneDelete={this.onLaneDelete}
                    onLaneUpdate={this.onLaneUpdate}
                    handleLaneDragEnd={this.handleLaneDragEnd}
                />
                <CardPopup projectId={this.props.match.params.projectId} show={this.state.showCardPopup} updateCardDescription={this.updateCardDescription} card={this.state.card} popupClosed={this.handlePopupClosed}/>
            </div>
        );
    }

    updateCardDescription = (card) => {
        this.setState({card: card});
        this.updateBoard();
    }

    handlePopupClosed = () => {
        this.setState({showCardPopup: false})
    }

    onLaneAdd = (params) => {
        const form = {title: params.title};
        authApiService.post(`/project/${this.props.match.params.projectId}/board/${this.state.board.id}`, form,
            (data, headers) => {
                this.laneIdMap[params.id] = data.id;
                this.updateBoard();
            },
            (message, errors) => {
                // error adding lane
            });
    }

    onLaneUpdate = (laneId, data) => {
        if (this.laneIdMap[laneId]) {
            laneId = this.laneIdMap[laneId];
        }

        const form = {title: data.title};
        authApiService.put(`/project/${this.props.match.params.projectId}/board/${this.state.board.id}/lane/${laneId}`, form,
            (data, headers) => {
                this.updateBoard();
            },
            (message, errors) => {
                // error adding lane
            });
    }

    onLaneDelete = (laneId) => {
        if (this.laneIdMap[laneId]) {
            laneId = this.laneIdMap[laneId];
        }

        authApiService.delete(`/project/${this.props.match.params.projectId}/board/${this.state.board.id}/lane/${laneId}`,
            (data, headers) => {
                this.updateBoard();
            },
            (message, errors) => {
                // error adding lane
            });
    }

    handleLaneDragEnd = (removedIndex, addedIndex, payload) => {
        const form = {
            removedIndex: removedIndex,
            addedIndex: addedIndex
        }
        authApiService.put(`/project/${this.props.match.params.projectId}/board/${this.state.board.id}/lane/${payload.id}/index/${addedIndex}`, form,
            (data, headers) => {
            },
            (message, errors) => {
                // error adding lane
            });
    }

    onCardAdd = (card, laneId) => {
        if (this.laneIdMap[laneId]) {
            laneId = this.laneIdMap[laneId];
        }

        authApiService.post(`/project/${this.props.match.params.projectId}/lane/${laneId}`, card,
            (data, headers) => {
                this.cardIdMap[card.id] = data.id;
                this.updateBoard();
            },
            (message, errors) => {
                // error adding card
            });
    }

    onCardUpdate = (laneId, card) => {
        if (this.laneIdMap[laneId]) {
            laneId = this.laneIdMap[laneId];
        }
        let cardId = card.id;
        if (this.cardIdMap[card.id]) {
            cardId = this.cardIdMap[card.id];
        }

        authApiService.put(`/project/${this.props.match.params.projectId}/lane/${laneId}/card/${cardId}`, card,
            (data, headers) => {
                this.updateBoard();
            },
            (message, errors) => {
                // error adding card
            });
    }

    onCardDelete = (cardId, laneId) => {
        if (this.laneIdMap[laneId]) {
            laneId = this.laneIdMap[laneId];
        }
        if (this.cardIdMap[cardId]) {
            cardId = this.cardIdMap[cardId];
        }

        authApiService.delete(`/project/${this.props.match.params.projectId}/lane/${laneId}/card/${cardId}`,
            (data, headers) => {
                this.updateBoard();
            },
            (message, errors) => {
                // error adding card
            });
    }

    onCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
        const card = {
            fromLaneId: fromLaneId,
            toLaneId: toLaneId,
            cardId: cardId,
            index: index
        }
        authApiService.put(`/project/${this.props.match.params.projectId}/lane/${fromLaneId}/card/${cardId}/index/${index}`, card,
            (data, headers) => {
            },
            (message, errors) => {
                // error adding card
            });
    }

    onCardClick = (cardId, metadata, laneId) => {
        if (this.laneIdMap[laneId]) {
            laneId = this.laneIdMap[laneId];
        }
        if (this.cardIdMap[cardId]) {
            cardId = this.cardIdMap[cardId];
        }

        const lane = this.state.board.lanes.find(x => x.id === laneId);
        const card = lane?.cards.find(x => x.id === cardId);

        this.setState({showCardPopup: !this.state.showCardPopup, card: card})
    }

    updateBoard = () => {
        this.fetchBoard();
    }

    fetchBoard = () => {
        authApiService.get(`/project/${this.props.match.params.projectId}/board`,
            (board, headers) => {
                this.setState({board: board});
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }
}

export default TaskBoardPage;