import React, { Component } from 'react';

class ProjectIconColor extends Component {

    state = {
        isActive: false
    }

    render() {
        return (
            <div className={this.getClasses()}
                 style={{backgroundColor: this.props.color}}
                 onClick={this.handleClick}></div>
        );
    }

    getClasses = () => {
        return "project-icon-color no-select" +
            (this.state.isActive ? " project-icon-color-active" : "");
    }

    handleClick = (e) => {
        this.props.onColorClick(this)
    }

    setActive = (isActive) => {
        this.setState({isActive: isActive});
    }
}

export default ProjectIconColor;