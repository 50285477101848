import React, { Component } from 'react';
import file from "../../../../../markup/img/docs-file.svg";
import link from "../../../../../markup/img/docs-link.svg";

class DocUpload extends Component {

    render() {
        return (
            <div className="dashboard-doc-upload flex-start" onClick={this.props.action}>
                <div className="doc-upload-box ml20-auto">
                    <img src={this.props.type === 'FILE' ? file : link} width="24" height="24" alt="Asc"/>
                </div>

                <div className="ml20-auto">
                    <div className="doc-upload-text">{this.props.text}</div>
                    <div className="doc-upload-desc">{this.props.desc}</div>
                </div>

                <div className="ml20-auto doc-upload-plus">
                    +
                </div>
            </div>
        );
    }
}

export default DocUpload;