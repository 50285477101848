import React, { Component } from 'react';
import search2x from '../../../../../../../markup/img/search@2x.png';
import search3x from '../../../../../../../markup/img/search@3x.png';

class SearchBar extends Component {
    
    state = {
        borderVisible: false
    }

    render() {
        return (
            <div className="navbar-nav mr-auto mt-2 mt-lg-0 search-bar inline-block ">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text search-icon-wrapper" id="basic-addon1">
                            <img className="search-img" src={search2x} srcSet={`${search2x} 2x, ${search3x} 3x`} width="24" height="24" alt="Search"/>
                        </span>
                    </div>
                    <input type="text" onKeyUp={e => this.handleKeyUp(e)} onChange={e => this.props.onSearchTextChange(e.target.value)} onFocus={e => this.handleOnFocus(e)} onBlur={e => this.handleOnBlur(e)} className="form-control search-text-input" placeholder="Search by client or project name" aria-label="Search by client or project name" aria-describedby="basic-addon1"/>
                </div>
                { this.state.borderVisible ? <div className="search-bar-bottom-border"></div> : null }
            </div>
        );
    }

    handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
            this.setState({borderVisible: false});
        }
    }

    handleOnFocus = (e) => {
        this.setState({borderVisible:true});
    }

    handleOnBlur = (e) => {
        this.setState({borderVisible:false});
    }
}

export default SearchBar;