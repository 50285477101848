import React, { Component } from 'react';
import img2x from '../../../../../../../../markup/img/icon-upload@2x.png'
import img3x from '../../../../../../../../markup/img/icon-upload@2x.png'
import fileService from '../../../../../../../../services/fileService';

class DragAndDropArea extends Component {

    constructor() {
        super();
        this.dropArea = null;
    }

    componentDidMount() {
        this.dropArea = document.getElementById('file-popup-drop-area');
        this.dropArea.addEventListener('dragenter', this.handleDragEnter, false);
        this.dropArea.addEventListener('dragleave', this.handleDragLeave, false);
        this.dropArea.addEventListener('dragover', this.handleDragOver, false);
        this.dropArea.addEventListener('drop', this.handleDrop, false);
        ;['dragenter', 'dragleave', 'dragover', 'drop'].forEach(eventName => {
            document.getElementById('file-popup-drop-area').addEventListener(eventName, function (e) {
                e.preventDefault();
                e.stopPropagation();
            }, false)
        });
    }

    render() {
        return (
            <div id="file-popup-drop-area" className="file-drop-area">
                <img className="upload" onDragStart={e => e.preventDefault()} src={img2x} srcSet={`${img2x} 2x, ${img3x} 3x`} width="40" height="40" alt="Upload" />
                <form className="file-popup-drop-area-form">
                    <input type="file" id="file-popup-drop-area-input" multiple onChange={e => this.handleFiles(e.target.files)} />
                    <div className="title">
                        Drag & drop or&nbsp;
                        <label className="browse" htmlFor="file-popup-drop-area-input">Browse</label>
                    </div>
                </form>
            </div>
        );
    }

    handleDragEnter = (e) => {
        this.highlightArea(true);
    }

    handleDragLeave = (e) => {
        this.highlightArea(false);
    }

    handleDragOver = (e) => {
        this.highlightArea(true);
    }

    handleDrop = (e) => {
        this.handleFiles(e.dataTransfer.files);
        this.highlightArea(false);
    }

    handleFiles = (files) => {
        ([...files]).filter(fileService.isFile).forEach(this.props.onFileDrop);
    }

    highlightArea(highlight) {
        if (highlight) {
            this.dropArea.classList.add('files-over');
        } else {
            this.dropArea.classList.remove('files-over');
        }
    }
}

export default DragAndDropArea;