import React, { Component } from 'react';
import iconDelete2x from '../../../../../../../../markup/img/icon-delete@2x.png';
import iconDelete3x from '../../../../../../../../markup/img/icon-delete@3x.png';
import iconClose2x from '../../../../../../../../markup/img/icon-close@2x.png';
import iconClose3x from '../../../../../../../../markup/img/icon-close@3x.png';
import iconRetry2x from '../../../../../../../../markup/img/icon-retry@2x.png';
import fileService from '../../../../../../../../services/fileService';
import { ProgressBar } from 'react-bootstrap';

class FileItem extends Component {

    componentDidMount() {
        this.props.uploadFile.progressUpdateHandler = this.handlerProgressUpdate;
        this.props.uploadFile.uploadStatusHandler = this.props.onUploadStatusUpdate;
    }

    render() {
        return (
            <div className="file">
                <table className="tbl-file-content">
                    <tbody>
                        <tr>
                            <td className="icon">
                                <img className="app-icon" src={fileService.fileIcon(this.props.uploadFile.file.name)} width="32" height="32" alt="Icon" />
                            </td>

                            <td className="body">
                                <div className="title">{this.props.uploadFile.file.name}</div>
                                {this.statusContent()}
                                {this.uploadError()}
                            </td>

                            <td className="control">
                                {this.controlButton()}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    statusContent() {
        if (this.props.uploadFile.loading) {
            return <div>
                <ProgressBar now={this.props.uploadFile.progressPercent} />
                <div className="progress-status-text">
                    <div className="percent">{this.props.uploadFile.progressPercent + '% done'}</div>
                    <div className="load-speed">{this.props.uploadFile.uploadSpeed + ' kbps'}</div>
                </div>
            </div>;
        } else {
            return <div className="size">{fileService.textSize(this.props.uploadFile.file.size)}</div>
        }
    }

    controlButton = () => {
        if (this.props.uploadFile.loading)
            return <img className="control-icon" src={iconClose2x} srcSet={`${iconClose2x} 2x, ${iconClose3x} 3x`} onClick={(e) => this.props.onCancel(this.props.uploadFile)} width="24" height="24" alt="Cancel" />;
        else if (this.props.uploadFile.error)
            return <div className="controll-btn-wrapper">
                <img className="control-icon retry" src={iconRetry2x} onClick={(e) => this.props.onRetry(this.props.uploadFile)} width="24" height="24" alt="Retry" />
                <img className="control-icon delete" src={iconDelete2x} srcSet={`${iconDelete2x} 2x, ${iconDelete3x} 3x`} onClick={(e) => this.props.onDelete(this.props.uploadFile)} width="24" height="24" alt="Delete" />
            </div>;
    }

    uploadError = () => {
        return this.props.uploadFile.error
            ? <div className="error-wrapper">
                <div className="error-text">Upload failed</div>
            </div>
            : '';
    }

    handlerProgressUpdate = () => {
        this.forceUpdate();
    }
}

export default FileItem;