import React, { Component } from 'react';
import img2x from '../../../../../../markup/img/project-index@2x.png';
import img3x from '../../../../../../markup/img/project-index@3x.png';

class Placeholder extends Component {
    render() {
        return (
            <div className="card project-index-panel">
                <div className="table">
                    
                    <div className="card-body table-cell vertical-align-middle">
                        <h5 className="card-title">Welcome to Pantram!</h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                            We aspire to make your communication with your team and clients as smooth as possible.
                            <br/> Let’s get started and see how you can improve your work right now!
                        </h6>
                        <button type="button" className="btn btn-primary btn-basic" onClick={this.props.onClickCreateProject}>Create new project</button>
                    </div>

                    <div className="project-index-img-wrapper table-cell vertical-align-middle">
                        <img src={img2x} srcSet={`${img2x} 2x, ${img3x} 3x`} width="495" height="360" alt="Projects"/>
                    </div>

                </div>
            </div>
        );
    }
}
 
export default Placeholder;