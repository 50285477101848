import React, {Component} from "react";
import GoogleLogin from 'react-google-login';

class GoogleSignIn extends Component {

    render() {
        return (
            <GoogleLogin
                className="google-button hidden"
                clientId="171632844407-vuhq6ju064uvklio4jbt73tduheq5cda.apps.googleusercontent.com"
                buttonText="SignIn"
                onSuccess={this.props.onSuccess}
                onFailure={this.props.onFailure}
                cookiePolicy={'single_host_origin'}
            />
        );
    }
}

export default GoogleSignIn;