import React, { Component } from 'react';
import fileService from "../../../../../../../../services/fileService";
import authApiService from '../../../../../../../../services/authorizedApiHttpService';

class HistoryItem extends Component {

    render() {
        return (
            <div className="comment-item-wrapper" onMouseLeave={this.handleCommentMouseLeave}>
                <div className="comment-text-wrapper file-history">
                    <div className="comment-text-header" onClick={this.handleClick}>
                        <span className="member-name">{this.props.historyItem.originalName}</span>
                        <span className="comment-time">{this.props.historyItem.timeFromCreated + " ago"}</span>
                    </div>
                </div>
            </div>
        );
    }

    handleClick = (e) => {
        authApiService.get(this.props.historyItem.url,
            (download, headers) => {
                fileService.downloadFile(download.signedUrl, download.fileName);
            },
            (message, errors) => {
                /* Show error popup with some message */
            },
            (cancelToken) => { });
    }
}

export default HistoryItem;