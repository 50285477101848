import React, { Component } from 'react';
import SearchBar from './search/searchBar';
import SortByFilter from './filter/sortBy';
import CreateProjectButton from './button/createProject';

class NavigationBar extends Component {
    
    render() { 
        return (
            <nav className="navbar navbar-expand-lg project-list-header">
                <span className="navbar-brand project-list-label">My Projects</span>
                <div className="container-fluid">
                    <SearchBar onSearch={this.props.onSearch} onSearchTextChange={this.props.onSearchTextChange}/>

                    <div className="form-inline">
                        <SortByFilter onOptionChange={this.props.onSortOptionChange}/>
                        <CreateProjectButton onClickCreateProject={this.props.onClickCreateProject}/>
                    </div>
                </div>
            </nav>
        );
    }
}
 
export default NavigationBar;