import jstz from 'jstz';

function wait(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => { resolve(ms) }, ms)
  });
}

function timeSince(timestamp) {
  var date = convert2Timezone(timestamp + "+00:00", jstz.determine().name());
  var seconds = Math.floor((new Date() - date) / 1000);
  var interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " year(s)";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " month(s)";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " day(s)";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hour(s)";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minute(s)";
  }
  let floorSeconds = Math.floor(seconds);
  return (floorSeconds > 0 ? floorSeconds + " second(s)" : " just now");
}

function convert2Timezone(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
}

export default {
  wait,
  timeSince,
  convert2Timezone
}