import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import CloseButton from '../../project/builder/popup/button/close';
import {withRouter} from 'react-router-dom';
import authApiService from "../../../../../services/authorizedApiHttpService";
import arrowDown from "../../../../../markup/img/arrow-down.png";
import mixpanel from "../../../../../services/mixpanelService";
import ValidationErrors from "../../../shared/field/errors/errors";

class InvitePopup extends Component {

    state = {
        roles: {
            'owner': 1,
            'collaborator': 2
        },
        roleName: 'Collaborator',
        showRoleMenu: false,

        form: {
            email: '',
            roleId: 2,
        },
        errors: {
            form: {
                email: []
            },
            summary: ''
        }
    }

    render() {
        return (
            <Modal className="request-subject edit-action" show={this.props.show}>
                <div className="modal-content w650">
                    <Modal.Header>
                        <h5 className="modal-title">Invite</h5>
                        <CloseButton onClose={this.props.onClose} />
                    </Modal.Header>

                    <Modal.Body className="flex-direction-column">
                        <div>
                            <input id="collaborator-email-input" className="invite-input no-outline" onChange={e => { this.handleInvitationEmailEdit(e.target.value) }} type="email" placeholder="Email" />
                            <div className="docs-sort-by no-select mr40 float-right" onClick={this.handleShowRoles}>
                                {this.state.roleName}
                                {this.getRoleMenu()}
                                <img className="ml4" src={arrowDown} alt="Arrow"/>
                            </div>
                        </div>
                        <button type="button" className="btn copy mt24" onClick={this.handleInvite}>Send invite</button>
                        <ValidationErrors errors={this.state.errors.form.email} />
                        <div className="dashboard-line-h mt24"></div>
                    </Modal.Body>

                    <Modal.Footer className="form-footer flex-start">
                        <div className="w100p">
                            <span className="project-role-name">Owner</span>
                            <span className="project-role-desc float-right text-align-right">All features <u>including</u> adding members, erasing project</span>
                        </div>
                        <div className="w100p">
                            <span className="project-role-name">Collaborator</span>
                            <span className="project-role-desc float-right text-align-right">All features <u>except</u> adding members, erasing project</span>
                        </div>
                    </Modal.Footer>
                </div>
            </Modal>
        );
    }

    getRoleName = (roleId) => {
        switch (roleId) {
            case 1: return 'Owner';
            case 2: return 'Collaborator';
            case 3: return 'External';
            default: return '';
        }
    }

    getRoleMenu = () => {
        return this.state.showRoleMenu ?
            (<div className="doc-sort-menu">
                <div type="button" className="doc-details-context-menu-item btn" onClick={e => this.handleRoleChange(this.state.roles.owner)}>
                    <span className="doc-add-menu-text">Owner</span>
                </div>
                <div type="button" className="doc-details-context-menu-item btn" onClick={e => this.handleRoleChange(this.state.roles.collaborator)}>
                    <span className="doc-add-menu-text">Collaborator</span>
                </div>
            </div>)
            :
            ("")
    }

    handleRoleChange = (roleId) => {
        const state = { ...this.state };
        state.form.roleId = roleId;
        state.roleName = this.getRoleName(roleId);
        this.setState(state);
    }

    handleShowRoles = () => {
        this.setState({showRoleMenu: !this.state.showRoleMenu});
    }

    handleInvitationEmailEdit = (email) => {
        const state = { ...this.state };
        state.form.email = email;
        state.errors.form.email = [];
        state.errors.summary = '';
        this.setState(state);
    }

    handleInvite = (e) => {
        authApiService.post(`/project/${this.props.project.id}/invite`, this.state.form,
            (data, headers) => {
                this.props.onClose();
                this.props.projectUsersUpdated();
                mixpanel.track("project.shared", {
                    projectId: this.props.match.params.projectId,
                    userId: this.props.profile.id,
                    toEmail: this.state.form.email,
                    role: this.state.roleName
                });
            },
            (message, errors) => {
                const state = { ...this.state };
                state.errors.form = errors;
                state.errors.summary = (Object.keys(errors).length > 0) ? '' : message;
                this.setState(state);
            });
    }
}

export default withRouter(InvitePopup);