import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

class NewCardForm extends Component {

    handleAdd = () => this.props.onAdd({title: this.titleRef.value, description: this.descRef.value})
    setTitleRef = (ref) => this.titleRef = ref
    setDescRef = (ref) => this.descRef = ref

    render() {
        const {onCancel} = this.props
        return (
            <div className="board-card-new-form">
                <div style={{padding: 5, margin: 5}}>
                    <div>
                        <div style={{marginBottom: 5, fontWeight: 400}}>
                            <input className="form-control board-add-card-input" type="text" ref={this.setTitleRef} placeholder="Title" />
                        </div>
                        <div style={{marginBottom: 5, fontWeight: 400}}>
                            <input className="form-control board-add-card-input-desc" type="text" ref={this.setDescRef} placeholder="Description" />
                        </div>
                    </div>
                    <div className="board-card-new-form-btns">
                        <button className="board-add-card mr8" onClick={this.handleAdd}>Add</button>
                        <button className="board-add-card-cancel" onClick={onCancel}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NewCardForm);