import React, { Component } from 'react';
import FieldWrapper from '../../../shared/field/wrapper';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ValidationErrors from '../../../shared/field/errors/errors';

class Role extends Component {

    state = {
        roles: [
            { id: 1, name: 'Project Manager' },
            { id: 2, name: 'Designer' },
            { id: 3, name: 'Developer' },
            { id: 4, name: 'Client' }
        ]
    }

    render() {
        const RoleToggle = React.forwardRef(({ children, onClick }, ref) => (
            <div ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
                {children}
            </div>
        ));

        return (
            <FieldWrapper>
                <div className="form-label">Role</div>
                <ButtonGroup className="dropdown-wrapper">
                    <Dropdown>
                        <Dropdown.Toggle as={RoleToggle}>
                            <input data-toogle="dropdown" className="role" type="text" name="role" value={this.props.selectedId ? this.roleNameById(this.props.selectedId) : ''} readOnly placeholder="Select member role" />
                            <span className="icon-dropdown"></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-left">
                            {this.state.roles.map(role => (
                                <Dropdown.Item className="no-select" href="#" key={role.id} data-id={role.id} data-name={role.name} onClick={this.handleChange}>{role.name}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </ButtonGroup>
                <ValidationErrors errors={this.props.errors} />
            </FieldWrapper>
        );
    }

    handleChange = (e) => {
        e.preventDefault();
        this.props.onChange(parseInt(e.target.dataset.id));
    }

    roleNameById = (roleId) => {
        return this.state.roles.filter(role => roleId === role.id).shift().name;
    }
}

export default Role;