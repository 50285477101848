import React, { Component } from 'react';
import FieldWrapper from '../../../shared/field/wrapper';
import ValidationErrors from '../../../shared/field/errors/errors';

class FullName extends Component {

    render() {
        const { errors } = this.props;
        return (
            <FieldWrapper>
                <div className="form-label">Full name *</div>
                <input defaultValue={this.props.name ? this.props.name : ''} onChange={e => { this.props.onChange(e.target.value) }} className={(errors && errors.length > 0) ? "border-error" : ""} type="text" name="name" placeholder="Full name" />
                <ValidationErrors errors={this.props.errors} />
            </FieldWrapper>
        );
    }

}

export default FullName;