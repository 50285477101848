import React, { Component } from 'react';

class Ghosts extends Component {
    render() {
        return (
            <div>

            </div>
        );
    }
}
 
export default Ghosts;