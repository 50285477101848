import React, { Component } from 'react';
import fileService from '../../../../../../../services/fileService';
import iconDelete2x from '../../../../../../../markup/img/icon-delete@2x.png';
import iconDelete3x from '../../../../../../../markup/img/icon-delete@3x.png';

class SubjectFile extends Component {
    render() {
        return (
            <div className="block">
                <table>
                    <tbody>
                        <tr>
                            <td className="icon-column">
                                <img className="icon" src={fileService.fileIcon(this.props.file.name)} width="48" height="48" alt="Icon" />
                            </td>
                            <td className="text-column">
                                <div className="title">{this.props.file.name}</div>
                                <div className="meta">
                                    <span className="days">{this.props.file.timeFromCreated}</span>
                                    &nbsp;<span className="bullet">•</span>&nbsp;
                                    <span className="size">{fileService.textSize(this.props.file.size)}</span>
                                </div>
                            </td>
                            {this.deleteButton()}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    deleteButton = () => {
        if (this.props.showDeleteButton) {
            return <td className="control">
                <img className="control-icon" src={iconDelete2x} srcSet={`${iconDelete2x} 2x, ${iconDelete3x} 3x`} onClick={e => this.props.onDelete(this.props.file)} width="24" height="24" alt="Delete" />
            </td>;
        } else {
            return "";
        }
    }
}

export default SubjectFile;