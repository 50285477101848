import React, { Component } from 'react';
import Error from '../error';
import errorIcon2x from '../../../../../markup/img/img-403-error@2x.png';


class ErrorForbidden extends Component {
    render() {
        return (
            <Error img={errorIcon2x} title="Need permission to access" subTitle="Please ask project owner to grant you access rights to this resource" />
        );
    }
}

export default ErrorForbidden;