import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'detailsAction',
    initialState: {
        show: false,
        actionType: {}, //APPROVAL/FEEDBACK/FILE_UPLOAD
        file: null,
        link: null
    },
    reducers: {
        openPopup: (popup, action) => {
            popup.actionType = action.payload.actionType;
            popup.file = action.payload.file;
            popup.link = null;
            popup.show = true;
            return popup;
        },
        popupShown: (popup, action) => {
            popup.show = true;
            popup.action = action.payload;
            return popup;
        },
        popupClosed: (popup, action) => {
            popup.show = false;
            return popup;
        },
        popupExited: (popup, action) => {
            popup.action = null;
            return popup;
        },
        popupFileUpdated: (popup, action) => {
            popup.file = action.payload;
            return popup;
        }
    }
});

export default slice.reducer;
export const { openPopup, popupShown, popupClosed, popupExited, popupFileUpdated } = slice.actions;