import React, { Component } from 'react';
import { Modal, ModalFooter, ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import CloseButton from '../button/close';
import DragAndDropArea from './dragandroparea/drugAndDropArea';
import FileItem from './item/fileItem';
import UploadFileProcessor from './model/uploadFileProcessor';
import { withRouter } from 'react-router-dom';

class FilePopup extends Component {

    constructor() {
        super();
        this.fileProcessor = new UploadFileProcessor();
    }

    render() {
        return (
            <Modal id="add-file-modal" className="details-modal" show={this.props.show} onExited={this.clear}>
                <div className="modal-content">
                    <ModalHeader className="flex-direction-column">
                        <h5 className="modal-title">{this.getTitle()}</h5>
                        <CloseButton onClose={this.props.onClose} />
                    </ModalHeader>

                    <ModalBody className="flex-direction-column">
                        <DragAndDropArea onFileDrop={this.handleFileDrop} />
                        <div className="files-wrapper">
                            {this.fileProcessor.uploadFiles.map((file, index) => {
                                return <FileItem key={index} uploadFile={file} onUploadStatusUpdate={this.handleUploadStatusUpdate} onCancel={this.handleCancelFileUpload} onDelete={this.handleDeleteFileUpload} onRetry={this.handleRetryFileUpload} />
                            })}
                        </div>
                    </ModalBody>

                    <ModalFooter className="form-footer flex-start">
                        <button type="button" className="btn btn-outline-grey" data-dismiss="modal" onClick={e => this.props.onClose(e)}>Cancel</button>
                        <button type="button" className={this.fileProcessor.isAllFilesUploaded() ? 'btn' : 'btn disabled'} onClick={e => this.handleAddFiles(e)}>Add files</button>
                    </ModalFooter>
                </div>
            </Modal>
        );
    }

    getTitle = () => {
        return this.props.fileId ? "Replace file" : "Add new file";
    }

    handleFileDrop = (file) => {
        this.fileProcessor.scheduleUpload(file, this.props.project.id);
        this.forceUpdate();
    }

    handleUploadStatusUpdate = (uploadFile) => {
        this.forceUpdate();
    }

    handleCancelFileUpload = (uploadFile) => {
        uploadFile.cancelToken.cancel();
        this.removeFile(uploadFile);
    }

    handleDeleteFileUpload = (uploadFile) => {
        this.removeFile(uploadFile);
    }

    handleRetryFileUpload = (uploadFile) => {
        uploadFile.error = false;
        this.fileProcessor.startUpload(uploadFile);
        this.forceUpdate();
    }

    removeFile = (uploadFile) => {
        this.fileProcessor.uploadFiles = this.fileProcessor.uploadFiles.filter(file => !file.equals(uploadFile));
        this.forceUpdate();
    }

    handleAddFiles = (e) => {
        this.fileProcessor.sendConfirmationAboutUploadFiles(
            this.props.project.id,
            this.props.fileId,
            (projectFiles, file) => {
                this.props.onAddFiles(projectFiles, file);
            },
            (message, errors) => {
                /* Show error popup */
            });
    }

    clear = () => {
        this.fileProcessor.clear();
    }
}

export default withRouter(FilePopup);