import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import CloseButton from '../button/close';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { popupClosed, popupExited, popupActionUpdated } from '../../../../../../../models/store/popup/editAction';
import actionsService from '../../../../../../../services/actionService';
import MemberSearch from '../shared/memberSearch';
import Members from './members';
import Textarea from 'react-textarea-autosize';
import ValidationErrors from '../../../../../shared/field/errors/errors';
import authApiService from '../../../../../../../services/authorizedApiHttpService';
import { actionUpdated, actionArchived } from '../../../../../../../models/store/actions/actions';
import actionService from '../../../../../../../services/actionService';
import { produce } from 'immer';
import RequestSubject from '../shared/requestSubject';
import SubjectFile from '../shared/subjectFile';
import DragAndDropForSingleFile from '../../../../project/builder/popup/file/draganddropsinglefile/DragAndDropForSingleFile';
import UploadFileProcessor from '../file/model/uploadFileProcessor';
import FileItem from '../file/item/fileItem';
import authApiHttpService from '../../../../../../../services/authorizedApiHttpService';
import Comments from './comments/Comments';

class EditActionPopup extends Component {

    constructor() {
        super();
        this.descriptionTextarea = React.createRef();
    }

    state = {
        errors: {
            form: {
                additionalInstructions: [],
                file: [],
                memberIds: []
            },
            summary: ""
        },
        uploadProcessor: new UploadFileProcessor(),
        oneFileUploaded: false,
        fileLoading: false
    }

    render() {
        return (
            <Modal className="request-subject edit-action" show={this.props.popup.show} onExited={this.props.popupExited}>
                <Modal.Header className="header-btn-wrapper">
                    <h5 className="modal-title">Need {actionsService.typeName(this.props.popup.action?.typeId)}</h5>
                    <button type="button" className={this.statusButtonClasses()} onClick={this.handleSetComplete}>{this.statusButtonTitle()}</button>
                    <CloseButton onClose={this.props.popupClosed} />
                </Modal.Header>

                <Modal.Body className="flex-direction-column action-modal-body">
                    <div className="description">
                        <Textarea className="noshadow"
                            maxLength="255"
                            data-gramm_editor="false"
                            onKeyDown={e => this.handleDescriptionKeyDown}
                            onKeyUp={this.handleDescriptionKeyUp}
                            spellCheck="false"
                            minRows={1}
                            maxRows={5}
                            ref={this.descriptionTextarea}
                            onBlur={e => this.handleDescriptionBlur(e.target.value)}
                            defaultValue={this.props.popup.action?.additionalInstructions}
                            readOnly={false} />
                        <ValidationErrors errors={this.state.errors.form.additionalInstructions} />
                    </div>

                    <MemberSearch members={this.members()} label="Members" placeholder="Type member name to search" onSelect={this.handleOnMemberSelect} />
                    <Members members={this.members()} onDelete={this.handleOnMemberDelete} />

                    {this.subject()}

                    {this.fileFropDownArea()}

                    {this.uploadedFiles()}

                    <Comments action={this.props.popup.action} />

                </Modal.Body>
            </Modal>
        );
    }

    subject = () => {
        return (this.props.popup.action?.file)
            ? <RequestSubject label="Subject">
                <SubjectFile file={this.props.popup.action.file} showDeleteButton={true} onDelete={this.handleUploadedFileDelete} />
            </RequestSubject>
            : "";
    }

    handleUploadedFileDelete = (file) => {
        authApiService.delete(`/project/${this.props.match.params.projectId}/action/${this.props.popup.action.id}/file/${file.id}`,
            (action, headers) => {
                this.actionUpdated(action);
            },
            (message, errors) => {
                /* Show error popup */
            },
            (cancelToken) => { });
    }

    fileFropDownArea = () => {
        if (this.isFileUpload() && !this.props.popup.action?.file) {
            return <DragAndDropForSingleFile onFileDrop={this.handleFileDrop} />;
        }
        return "";
    }

    uploadedFiles = () => {
        return <div className="files-wrapper">
            {this.state.uploadProcessor.uploadFiles.map((file, index) => {
                return <FileItem key={index} uploadFile={file} onUploadStatusUpdate={this.handleUploadStatusUpdate} onCancel={this.handleCancelFileUpload} onDelete={this.handleDeleteFileUpload} onRetry={this.handleRetryFileUpload} />
            })}
        </div>;
    }

    members = () => {
        return this.props.popup.action ? this.props.popup.action.members : [];
    }

    statusButtonClasses = () => {
        return "btn" +
            (actionService.isComplete(this.props.popup.action?.statusId)
                ? " disabled"
                : ""
            );
    }

    statusButtonTitle = () => {
        if (!this.props.popup.action) return "";
        let typeName = this.props.actionTypes
            .filter(type => type.id === this.props.popup.action.typeId).shift().name;

        switch (typeName) {
            case "APPROVAL": return "Approve";
            case "FEEDBACK": return "Feedback provided";
            case "FILE_UPLOAD": return "File uploaded";
            default: return "";
        }
    }

    isFileUpload = () => {
        if (!this.props.popup.action) return false;
        return this.props.actionTypes
            .filter(type => type.id === this.props.popup.action.typeId).shift().name === "FILE_UPLOAD";
    }

    handleSetComplete = (e) => {
        if (this.props.popup.action && !actionService.isComplete(this.props.popup.action.statusId)) {
            let statusComplete = actionService.statusId("COMPLETE");
            authApiService.put(`/project/${this.props.match.params.projectId}/action/${this.props.popup.action.id}/status/${statusComplete}`, null,
                (action, headers) => {
                    if (actionService.statusName(action.statusId) === "Open") {
                        this.actionUpdated(action);
                    } else {
                        this.actionArchived(action);
                    }
                },
                (message, errors) => {
                    /* Show error popup */
                },
                (cancelToken) => { });
        }
    }

    handleDescriptionKeyDown = (e) => {
        /* Prevent to enter Enter key */
        if (e.which === 13) {
            e.preventDefault();
        }
    }

    handleDescriptionKeyUp = (e) => {
        this.clearDescriptionErrors();
    }

    handleDescriptionBlur = (description) => {
        if (!description || description.length === 0) {
            this.descriptionTextarea.current.focus();
        }
        /* No sense to send to server existing value */
        else if (description !== this.props.popup.action.description) {
            authApiService.put(`/project/${this.props.match.params.projectId}/action/${this.props.popup.action.id}/description`, { description: description },
                (action, headers) => {
                    this.actionUpdated(action);
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        }
    }

    clearDescriptionErrors = () => {
        this.setState(produce(this.state, draftState => {
            draftState.errors.form.additionalInstructions = [];
        }));
    }

    handleOnMemberDelete = (member) => {
        authApiService.delete(`/project/${this.props.match.params.projectId}/action/${this.props.popup.action.id}/member/${member.id}`,
            (action, headers) => {
                this.actionUpdated(action);
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }

    handleOnMemberSelect = (member) => {
        authApiService.post(`/project/${this.props.match.params.projectId}/action/${this.props.popup.action.id}/member/${member.id}`, null,
            (action, headers) => {
                this.actionUpdated(action);
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }

    actionUpdated = (action) => {
        this.props.actionUpdated(action);
        this.props.popupActionUpdated(action);
    }

    actionArchived = (action) => {
        this.props.actionArchived(action);
        this.props.popupActionUpdated(action);
    }

    handleFileDrop = (file) => {
        if (!this.state.fileLoading) {
            this.state.uploadProcessor.scheduleUpload(file, this.props.match.params.projectId);
            this.forceUpdate();
        }
    }

    handleUploadStatusUpdate = (uploadFile) => {
        if (uploadFile.progressPercent === 100 && uploadFile.complete) {
            authApiHttpService.post(`/project/${this.props.match.params.projectId}/action/${this.props.popup.action.id}/file`, { signedUrl: uploadFile.signedUrl },
                (action, headers) => {
                    this.clear();
                    this.actionUpdated(action);
                },
                (message, errors) => {
                    /* Show error popup */
                },
                (cancelToken) => { });

            const state = { ...this.state };
            state.fileLoading = true;
            this.setState(state);
        }
        this.forceUpdate();
    }

    handleCancelFileUpload = (uploadFile) => {
        uploadFile.complete = false;
        uploadFile.cancelToken.cancel();
        this.removeFile(uploadFile);
    }

    handleDeleteFileUpload = (uploadFile) => {
        this.clear();
        this.forceUpdate();
    }

    handleRetryFileUpload = (uploadFile) => {
        uploadFile.error = false;
        this.state.uploadProcessor.startUpload(uploadFile);
        this.forceUpdate();
    }

    removeFile = (uploadFile) => {
        /* Remove file in popup */
        let popupAction = { ...this.props.popup.action };
        popupAction.file = null;
        this.actionUpdated(popupAction);

        const state = { ...this.state };
        state.uploadProcessor.uploadFiles = state.uploadProcessor
            .uploadFiles
            .filter(file => !file.equals(uploadFile));
        state.fileLoading = false;
        this.setState(state);
    }

    clear = () => {
        this.state.uploadProcessor.clear();
    }
}

export default connect(
    (state) => ({
        popup: state.entities.popups.editAction,
        actionTypes: state.entities.enums.actionTypes
    }),
    (dispatch) => ({
        popupClosed: () => dispatch(popupClosed()),
        popupExited: () => dispatch(popupExited()),
        actionUpdated: (action) => dispatch(actionUpdated(action)),
        actionArchived: (action) => dispatch(actionArchived(action)),
        popupActionUpdated: (action) => dispatch(popupActionUpdated(action))
    })
)(withRouter(EditActionPopup));