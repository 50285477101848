import React, { Component } from 'react';
import Header from './header/header';
import Link from './link/link';
import EmptyLinksBlock from '../empty/empty';

class SubCategory extends Component {
    render() {
        return (
            <div className="group-wrapper">
                <Header subcategory={this.props.subcategory} readOnly={this.props.readOnly} onDelete={this.props.onSubCategotyDelete} />
                <div className="links">
                    {this.links()}
                </div>
            </div>
        );
    }

    links() {
        if (this.props.subcategory.links.length) {
            return this.props.subcategory.links.map(link => (
                <Link link={link}
                    group={this.props.subcategory}
                    key={link.id}
                    readOnly={this.props.readOnly}
                    onEdit={this.props.onLinkEdit}
                    onDelete={this.props.onLinkDelete} />
            ));
        } else {
            return <EmptyLinksBlock />;
        }
    }
}

export default SubCategory;