import React, { Component } from 'react';

class Action extends Component {

    render() {
        return (
            <button onClick={e => this.props.onPress(e)} type="button" className={this.classes()}>{this.props.title}</button>
        );
    }

    classes = () => {
        let classes = 'action btn';
        if (this.props.className)
            classes += ' ' + this.props.className;
        return classes;
    }
}

export default Action;