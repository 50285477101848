import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'projects',
    initialState: {
        prefetched: false,
        list: {}
    },
    reducers: {
        projectsFetched: (projects, action) => {
            projects = {};
            action.payload.forEach(project => projects.list[project.id] = project);
            projects.prefetched = true;
            return projects;
        },
        projectFetched: (projects, action) => {
            projects.list[action.payload.id] = action.payload;
            return projects;
        },
        projectCreated: (projects, action) => {
            projects.list[action.payload.id] = action.payload;
            return projects;
        }
    }
});

export default slice.reducer;
export const { projectsFetched, projectCreated, projectFetched } = slice.actions;