import React, { Component } from 'react';
import Logo from '../../shared/header/logo';
import ProfileImage from './button/profileImage';
import { withRouter } from 'react-router-dom';
import userProfileService from '../../../../services/userProfileService';

class Header extends Component {

    render() {
        return (
            <div className="page-header">
                <nav className="navbar navbar-light bg-white justify-content-between">
                    <Logo projectLogo={this.props.projectLogo} showPantramLogo={true} />
                    {this.rightMenuItems()}
                </nav>
            </div>
        );
    }

    rightMenuItems = () => {
        return (userProfileService.isAuthenticated())
            ? <div className="form-inline">
                {this.getCornerComponent()}
            </div>
            : "";
    }

    getCornerComponent() {
        return window.location.pathname.startsWith('/profile')
            ? <button type="button" className="btn logout-btn" onClick={this.handleLogoutButtonClick}>Logout</button>
            : <ProfileImage />;
    }

    handleLogoutButtonClick = (e) => {
        userProfileService.removeAuthToken();
        this.props.history.push('/signin');
    }
}

export default withRouter(Header);