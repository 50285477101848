import React, { Component } from 'react';
import PasswordField from '../../../../login/form/field/password/password';
import FieldWrapper from '../../../../shared/field/wrapper';

class NewPassword extends Component {
    render() {
        return (
            <FieldWrapper>
                <PasswordField title="New password *" onChange={this.props.onChange} errors={this.props.errors} />
                {!(this.props.errors && this.props.errors.lenght) &&
                    <div className="field-info-wrapper">
                        <div className="field-info">Must contain at least 6 symbols including uppercase and numbers</div>
                    </div>
                }
            </FieldWrapper>
        );
    }
}

export default NewPassword;