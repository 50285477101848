import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Wrapper from './wrapper';
import Title from './title/title';
import PasswordField from './field/password/password';
import FieldWrapper from '../../shared/field/wrapper';
import Footer from './footer/footer';
import CancelButton from './button/cancel';
import Button from './button/action';
import apiService from '../../../../services/apiHttpService';


class ResetPassword extends Component {

    state = {
        form: {
            password: "",
            repeatPassword: "",
        },
        errors: {
            form: {
                password: [],
                repeatPassword: []
            }
        }
    }

    render() {
        return (
            <Wrapper>
                <Title title="New password" />
                <FieldWrapper>
                    <PasswordField onChange={this.handleEditPassword} errors={this.state.errors.form.password} />
                </FieldWrapper>
                <FieldWrapper>
                    <PasswordField title="Repeat password *" onChange={this.handleEditRepeatPassword} errors={this.state.errors.form.repeatPassword} />
                </FieldWrapper>
                <Footer>
                    <CancelButton onCancel={this.handleCancel} />
                    <Button className="action" title="Reset password" onPress={this.handleResetPassword} />
                </Footer>
            </Wrapper>
        );
    }

    handleCancel = (e) => {
        this.props.history.push("/");
    }

    handleResetPassword = (e) => {
        apiService.put("/user/password", this.state.form,
            (data, headers) => {

            },
            (message, errors) => {
                const { form, errors: formErrors } = this.state;
                formErrors.form = errors;
                formErrors.summary = (Object.keys(errors).length > 0) ? "" : message;
                this.setState({ form: form, errors: formErrors });
            });
    }

    handleEditPassword = (password) => {
        const { form, errors } = this.state;
        form.password = password;
        errors.form.password = [];
        this.setState({ form: form, errors: errors });
    }

    handleEditRepeatPassword = (password) => {
        const { form, errors } = this.state;
        form.repeatPassword = password;
        errors.form.repeatPassword = [];
        this.setState({ form: form, errors: errors });
    }

}

export default withRouter(ResetPassword);