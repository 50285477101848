import React, { Component } from 'react';
import Layout from '../layout/main/layout';
import Sidebar from '../layout/main/project/builder/sidebar/sidebar';
import Content from '../layout/main/project/builder/content/content';
import SlugPopup from '../layout/main/project/builder/popup/slug/slug';
import SharePopup from '../layout/main/project/builder/popup/share/share';
import LinkPopup from '../layout/main/project/builder/popup/link/link';
import authApiService from '../../services/authorizedApiHttpService';
import LinkState from '../../models/link/link';
import FilePopup from '../layout/main/project/builder/popup/file/filePopup';
import ProjectLogoPopup from '../layout/main/project/builder/popup/file/projectLogoPopup';
import axios from 'axios';
import Project from '../../models/project/project';
import LinksCategory from '../../models/project/linksCategory';
import FilesCategory from '../../models/project/filesCategory';
import Status from '../../models/project/status';
import ContentGhosts from '../layout/main/project/builder/content/ghosts/contentGhosts';
import GroupDeletePopup from '../layout/main/project/builder/popup/confirmation/groupDeletePopup';
import ProjectDeletePopup from '../layout/main/project/builder/popup/confirmation/projectDeletePopup';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { profileFetched } from '../../models/store/profile/profile';
import { actionTypesFetched, actionStatusesFetched } from '../../models/store/enums/enums';
import CreateActionPopup from '../layout/main/project/builder/popup/action/CreateActionPopup';
import DetailsActionPopup from '../layout/main/project/builder/popup/action/DetailsActionPopup';
import LinkDetailsPopup from '../layout/main/project/builder/popup/action/LinkDetailsPopup';
import { projectFetched } from '../../models/store/projects/projects';
import { actionsFetched, actionUpdated } from '../../models/store/actions/actions';
import EditActionPopup from '../layout/main/project/builder/popup/action/EditActionPopup';
import { membersFetched } from '../../models/store/members/members';
import mixpanel from '../../services/mixpanelService';

class BuilderPage extends Component {

    constructor() {
        super();
        this.loading = true;
        this.groupsCancelToken = null;
        this.currentLink = new LinkState();
        this.linkGroupToDelete = { id: 0, name: '', links: [] };
        this.fileGroupToDelete = { id: 0, name: '', files: [] };
    }

    state = {
        showSharePopup: false,
        showSlugPopup: false,
        showLinkPopup: false,
        showFilePopup: false,
        showProjectLogoPopup: false,
        showDeleteLinkGroupPopup: false,
        showDeleteFileGroupPopup: false,
        showDeleteProjectPopup: false,
        showFilesCategory: true,
        showLinksCategory: true,
        projectLogo: null,
        project: new Project()
    }

    async componentDidMount() {
        let config = { headers: authApiService.authHeaders() };
        const [profileResponse, projectLogoResponse, projectSettingResponse, actionTypesResponse, actionStatusesResponse, projectResponse, projectLinkGroupsResponse, projectFileGroupsResponse, actionsResponse, members] = await Promise.all([
            axios.get(`${authApiService.apiURI}/user/profile`, config),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/logo`, config),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/setting`, config),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/action/types`, config),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/action/statuses`, config),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}`, config),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/links/grouped`, config),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/files/grouped`, config),
            axios.get(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/actions`, config),
            axios.post(`${authApiService.apiURI}/project/${this.props.match.params.projectId}/members`, { name: "", excludeIds: [] }, config)
        ]);

        this.props.actionTypesFetched(actionTypesResponse.data.data);
        this.props.actionStatusesFetched(actionStatusesResponse.data.data);
        this.props.actionsFetched(actionsResponse.data.data);
        this.props.membersFetched({
            projectId: this.props.match.params.projectId,
            members: members.data.data
        });

        this.setState({
            showFilesCategory: projectSettingResponse.data.data.showFiles,
            showLinksCategory: projectSettingResponse.data.data.showLinks
        });

        if (projectLogoResponse.data.data?.signedUrl) {
            this.setState({projectLogo: projectLogoResponse.data.data.signedUrl})
        }

        let fileApproveActions = {}
        let fileApproves = {}
        actionsResponse.data.data.forEach(action => {
            if (action?.file?.id && action?.typeId === 1) {
                fileApproveActions[action.file.id] = true;
                if (action.statusId === 1) {
                    if (!fileApproves[action.file.id]) {
                        fileApproves[action.file.id] = [];
                    }
                    fileApproves[action.file.id].push(action);
                }
            }
        });

        let linksCategory = new LinksCategory();
        linksCategory.groups = projectLinkGroupsResponse.data.data;
        let linkGroups = [];
        linksCategory.groups.map(group => linkGroups.push({id: group.id, name: group.name}));
        linksCategory.groups.map(group =>
            group.links.forEach(link => {
                link.group = {id: group.id, name: group.name}
                link.groups = linkGroups;
            })
        );

        let filesCategory = new FilesCategory();
        filesCategory.groups = projectFileGroupsResponse.data.data;
        filesCategory.groups.map(group =>
            group.files.forEach(file => {
                file.isApproved = fileApproveActions[file.id] && (!fileApproves[file.id] || fileApproves[file.id].length === 0);
                if (fileApproves[file.id]) {
                    file.approves = fileApproves[file.id];
                }
            })
        );

        const state = { ...this.state };
        state.project = new Project(
            projectResponse.data.data.id,
            projectResponse.data.data.name,
            projectResponse.data.data.description,
            new Status(projectResponse.data.data.status.id, projectResponse.data.data.status.status),
            projectResponse.data.data.privacy,
            projectResponse.data.data.timeCreated,
            linksCategory,
            filesCategory
        );

        this.props.projectFetched({
            id: projectResponse.data.data.id,
            name: projectResponse.data.data.name,
            description: projectResponse.data.data.description,
            status: {
                id: projectResponse.data.data.status.id,
                status: projectResponse.data.data.status.status
            },
            ownerId: projectResponse.data.data.ownerId,
            privacy: projectResponse.data.data.privacy,
            timeCreated: projectResponse.data.data.timeCreated
        });

        this.loading = false;
        this.setState(state);

        this.props.profileFetched(profileResponse.data.data);

        /* Identify user in Mixpanel */
        mixpanel.identifyUser(profileResponse.data.data);
    }

    render() {
        return (
            <React.Fragment>
                <Layout projectLogo={this.state.projectLogo}>
                    <div className="project-wrapper">
                        <Sidebar project={this.state.project} onActionToggleChange={this.actionsToggle} showFilesCategory={this.state.showFilesCategory} showLinksCategory={this.state.showLinksCategory} />
                        {this.content()}
                    </div>
                </Layout>
                <SlugPopup show={this.state.showSlugPopup} project={this.state.project} onClose={this.handleSlugPopupClose} />
                <SharePopup show={this.state.showSharePopup} project={this.state.project} onClose={this.handleSharePopupClose} />
                <LinkPopup show={this.state.showLinkPopup} link={this.currentLink} onClose={this.handleLinkPopupClose} onAddLink={this.handleOnAddLink} onEditLink={this.handleOnEditLink} />
                <FilePopup show={this.state.showFilePopup} project={this.state.project} onClose={this.handleCloseFilesPopup} onAddFiles={this.handleAddFiles} />
                <ProjectLogoPopup show={this.state.showProjectLogoPopup} project={this.state.project} onClose={this.handleCloseProjectLogoPopup} onAddProjectLogo={this.handleAddProjectLogo} />
                <GroupDeletePopup show={this.state.showDeleteLinkGroupPopup} group={this.linkGroupToDelete} onConfirm={this.handleLinkGroupDeleteConfirm} onCancel={this.handleLinkGroupDeleteCancel} onClose={this.handleLinkGroupDeleteClose} />
                <GroupDeletePopup show={this.state.showDeleteFileGroupPopup} group={this.fileGroupToDelete} onConfirm={this.handleFileGroupDeleteConfirm} onCancel={this.handleFileGroupDeleteCancel} onClose={this.handleFileGroupDeleteClose} />
                <ProjectDeletePopup show={this.state.showDeleteProjectPopup} project={this.state.project} onConfirm={this.handleProjectDeleteConfirm} onCancel={this.handleProjectDeleteCancel} onClose={this.handleProjecDeleteClose} />
                <CreateActionPopup onActionCreate={this.onActionCreate} />
                <EditActionPopup />
                <DetailsActionPopup project={this.state.project} onAddFiles={this.handleAddFiles} onApprove={this.handleApprove} onCommentsUpdated={this.onCommentsUpdated} onFileGroupChange={this.onFileGroupChange} />
                <LinkDetailsPopup project={this.state.project} onCommentsUpdated={this.onCommentsUpdated} onLinkGroupChange={this.onLinkGroupChange} />
            </React.Fragment>
        );
    }

    content = () => {
        return this.loading
            ? <ContentGhosts />
            : <Content
                project={this.state.project}
                onStatusChange={this.handleStatusChange}
                onShare={this.handleShare}
                onProjectDelete={this.handleProjectDelete}
                onClickAddElement={this.handleClickAddElement}
                onLinksSubCategotyDelete={this.handleLinkGroupDelete}
                onFilesSubCategoryDelete={this.handleFileGroupDelete}
                onLinkEdit={this.handleLinkEdit}
                onLinkDelete={this.handleLinkDelete}
                onFileDelete={this.handleFileDelete}
                onAddProjectLogo={this.handleClickAddProjectLogo}
                showFilesCategory={this.state.showFilesCategory}
                showLinksCategory={this.state.showLinksCategory}/>
    }

    actionsToggle = (e) => {
        this.showCategories(e);
    }

    handleStatusChange = (status) => {
        authApiService.put(`/project/${this.props.match.params.projectId}/status/${status.id}`, null,
            (data, headers) => {
                const state = { ...this.state };
                state.project.status = status;
                this.setState(state);
            },
            (message, errors) => {
                /* Show popup with error */
            });
    }

    handleShare = (e) => {
        this.showSharePopup(true);
    }

    handleSharePopupClose = (e) => {
        this.showSharePopup(false);
    }

    handleOnAddLink = (link, group) => {
        this.showLinkPopup(false);
        let state = { ...this.state };
        let foundGroups = state.project.links.groups.filter(g => g.id === link.groupId);
        if (foundGroups.length) {
            foundGroups.shift().links.push(link);
        } else {
            group.links.push(link);
            state.project.links.groups.push(group);
        }
        this.setState(state);
    }

    handleOnEditLink = (link, group) => {
        this.showLinkPopup(false);
        let state = { ...this.state };

        /* Find the current link group */
        let oldGroup = state.project.links.groups.filter(g => g.links.filter(l => l.id === link.id).length).shift();
        if (oldGroup.id === link.groupId) {
            let oldLink = oldGroup.links.filter(l => l.id === link.id).shift();
            Object.assign(oldLink, link);
        } else {
            /* Remove link from old group */
            oldGroup.links = oldGroup.links.filter(l => l.id !== link.id);
            /* Check new link such group exists */
            let foundGroups = state.project.links.groups.filter(g => g.id === link.groupId);
            if (foundGroups.length) {
                /* Add link to different group */
                foundGroups.shift().links.push(link);
            } else {
                /* Add link to new group, add group to group list */
                group.links.push(link);
                state.project.links.groups.push(group);
            }
        }

        this.setState(state);
    }

    handleLinkPopupClose = (e) => {
        this.showLinkPopup(false);
    }

    handleLinkGroupDelete = (linkGroup) => {
        this.linkGroupToDelete = linkGroup;
        this.showDeleteLinkGroupPopup(true);
    }

    handleLinkGroupDeleteConfirm = (linkGroup) => {
        authApiService.delete(`/project/${this.props.match.params.projectId}/link/group/${linkGroup.id}`,
            (data, headers) => {
                const state = { ...this.state };
                let filteredGroups = state.project.links.groups.filter(g => g.id !== linkGroup.id);
                state.project.links.groups = filteredGroups;
                this.setState(state);
                this.showDeleteLinkGroupPopup(false);
            },
            (message, errors) => {
                /* Show popup with error */
                this.showDeleteLinkGroupPopup(false);
            });
    }

    handleLinkGroupDeleteCancel = (e) => {
        this.showDeleteLinkGroupPopup(false);
    }

    handleLinkGroupDeleteClose = (e) => {
        this.showDeleteLinkGroupPopup(false);
    }

    handleFileGroupDelete = (fileGroup) => {
        this.fileGroupToDelete = fileGroup;
        this.showDeleteFileGroupPopup(true);
    }

    handleFileGroupDeleteConfirm = (fileGroup) => {
        authApiService.delete(`/project/${this.props.match.params.projectId}/file/group/${fileGroup.id}`,
            (data, headers) => {
                const state = { ...this.state };
                let filteredGroups = state.project.files.groups.filter(g => g.id !== fileGroup.id);
                state.project.files.groups = filteredGroups;
                this.setState(state);
                this.showDeleteFileGroupPopup(false);
            },
            (message, errors) => {
                /* Show popup with error */
                this.showDeleteFileGroupPopup(false);
            });
    }

    handleFileGroupDeleteCancel = (e) => {
        this.showDeleteFileGroupPopup(false);
    }

    handleFileGroupDeleteClose = (e) => {
        this.showDeleteFileGroupPopup(false);
    }


    /* Project Delete Popup */
    handleProjectDelete = (project) => {
        this.showDeleteProjectPopup(true);
    }

    handleProjectDeleteConfirm = (project) => {
        authApiService.delete(`/project/${this.props.match.params.projectId}`,
            (data, headers) => {
                /* Redirect to /projects */
                this.props.history.push('/projects');
            },
            (message, errors) => {
                /* Show popup with error */
                this.showDeleteProjectPopup(false);
            });
    }

    handleProjectDeleteCancel = (e) => {
        this.showDeleteProjectPopup(false);
    }

    handleProjecDeleteClose = (e) => {
        this.showDeleteProjectPopup(false);
    }

    handleClickAddProjectLogo = () => {
        this.showProjectLogoPopup(true);
    }

    handleClickAddElement = (e, categoryId) => {
        switch (categoryId) {
            case 'links': this.showLinkPopup(true); break;
            case 'files': this.showFilePopup(true); break;
            default: ;
        }
    }

    handleLinkEdit = (link, group) => {
        this.currentLink.clear();
        this.currentLink.form.id = link.id;
        this.currentLink.form.name = link.name;
        this.currentLink.form.url = link.url;
        this.currentLink.form.group.id = group.id;
        this.currentLink.form.group.name = group.name;
        this.currentLink.form.description = link.description;
        this.showLinkPopup(true);
    }

    handleLinkDelete = (link, group) => {
        authApiService.delete('/project/' + this.props.match.params.projectId + '/link/' + link.id,
            (data, headers) => {
                let state = { ...this.state };
                group.links = group.links.filter(l => l.id !== link.id);
                this.setState(state);
            },
            (message, errors) => {
                /* Show popup with error */
            });
    }

    handleFileDelete = (file) => {
        authApiService.delete('/project/' + this.props.match.params.projectId + '/file/' + file.id,
            (data) => {
                const state = { ...this.state };
                state.project.files.groups.forEach(group => {
                    if (group.id === file.group.id) {
                        group.files = group.files.filter(f => !(f.id === file.id));
                    }
                });
                this.setState(state);
            },
            (errors) => {
                /* Show popup with error */
            });
    }

    handleSlugPopupClose = (e) => {
        this.showSlugPopup(false);
    }

    handleApprove = (action) => {

        if (action?.file?.id) {
            let state = { ...this.state };

            this.props.actionUpdated(action);

            let index = this.getGroupAndFileIndex(state, action.file);
            if (index.groupId !== undefined) {
                let file = state.project.files.groups[index.groupId].files[index.fileId];
                file = this.newFile(file, true, []);
                state.project.files.groups[index.groupId].files[index.fileId] = file;
            }

            this.setState(state);
        }
    }

    onCommentsUpdated = (file) => {

        if (file?.hasUnreadComments) {
            const state = { ...this.state };

            let index = this.getGroupAndFileIndex(state, file);

            if (index.groupId !== undefined) {
                let f = state.project.files.groups[index.groupId].files[index.fileId];

                f = this.newFile(file, file.isApproved, file.approves);
                f.hasUnreadComments = false;

                state.project.files.groups[index.groupId].files[index.fileId] = f;
            }

            this.setState(state);
        }
    }

    onLinkGroupChange = (link, group) => {
        const state = { ...this.state };

        let index = this.getGroupAndLinkIndex(state, link);

        if (index.groupId !== undefined) {
            let l = state.project.links.groups[index.groupId].links[index.linkId];
            l = this.newLink(l);
            l.description = link.description;

            let groupId = link.group ? link.group.id : -1;
            let linkGroupId = state.project.links.groups.map(group => group.id).indexOf(groupId);
            if (index.groupId === linkGroupId) {
                state.project.links.groups[index.groupId].links[index.linkId] = l;
            } else if (state.project.links.groups[linkGroupId]) {
                state.project.links.groups[index.groupId].links.splice(index.linkId, 1);
                state.project.links.groups[linkGroupId].links.push(l);
            } else if (group?.id) {
                state.project.links.groups[index.groupId].links.splice(index.linkId, 1);
                group.links = [];
                group.links.push(l);
                state.project.links.groups.push(group);
            }
        }

        this.setState(state);
    }

    onFileGroupChange = (file, group) => {
        const state = { ...this.state };

        let index = this.getGroupAndFileIndex(state, file);

        if (index.groupId !== undefined) {
            let f = state.project.files.groups[index.groupId].files[index.fileId];
            f = this.newFile(f, f.isApproved, f.approves);
            f.description = file.description;

            let groupId = file.group ? file.group.id : -1;
            let fileGroupId = state.project.files.groups.map(group => group.id).indexOf(groupId);

            if (index.groupId === fileGroupId) {
                state.project.files.groups[index.groupId].files[index.fileId] = f;
            } else if (state.project.files.groups[fileGroupId]) {
                state.project.files.groups[index.groupId].files.splice(index.fileId, 1);
                state.project.files.groups[fileGroupId].files.push(f);
            } else if (group?.id) {
                state.project.files.groups[index.groupId].files.splice(index.fileId, 1);
                group.files = [];
                group.files.push(f);
                state.project.files.groups.push(group);
            }
        }

        this.setState(state);
    }

    handleAddFiles = (projectFilesGroup, fileGroup) => {
        this.showFilePopup(false);
        const state = { ...this.state };
        if (fileGroup) {
            state.project.files.groups.forEach(group => {
                if (group.id === fileGroup.id) {
                    let index = group.files.map(file => file.id).indexOf(fileGroup.files[0].id);
                    group.files[index] = fileGroup.files[0];
                }
            });
        } else {
            let index = state.project.files.groups.forEach(group => group.id).indexOf(projectFilesGroup.id);
            if (index >= 0) {
                projectFilesGroup.files.forEach(file => state.project.files.groups[index].files.push(file) );
            } else {
                state.project.files.groups.push(projectFilesGroup);
            }
        }
        this.setState(state);
    }

    handleAddProjectLogo = (projectLogo) => {
        this.setState({projectLogo: projectLogo.signedUrl})
        this.showProjectLogoPopup(false);
    }

    handleCloseFilesPopup = (e) => {
        this.showFilePopup(false);
    }

    handleCloseProjectLogoPopup = (e) => {
        this.showProjectLogoPopup(false);
    }

    showLinkPopup(show) {
        let state = { ...this.state };
        state.showLinkPopup = show;
        this.setState(state);
    }

    showFilePopup(show) {
        let state = { ...this.state };
        state.showFilePopup = show;
        this.setState(state);
    }

    showProjectLogoPopup(show) {
        let state = { ...this.state };
        state.showProjectLogoPopup = show;
        this.setState(state);
    }

    showSharePopup(show) {
        let state = { ...this.state };
        state.showSharePopup = show;
        this.setState(state);
    }

    showSlugPopup(show) {
        let state = { ...this.state };
        state.showSlugPopup = show;
        this.setState(state);
    }

    showDeleteLinkGroupPopup(show) {
        let state = { ...this.state };
        state.showDeleteLinkGroupPopup = show;
        this.setState(state);
    }

    showDeleteFileGroupPopup(show) {
        let state = { ...this.state };
        state.showDeleteFileGroupPopup = show;
        this.setState(state);
    }

    showDeleteProjectPopup(show) {
        let state = { ...this.state };
        state.showDeleteProjectPopup = show;
        this.setState(state);
    }

    newFile(file, isApproved, approves) {
        return {
            id: file.id,
            name: file.name,
            description: file.description,
            group: file.group,
            signedUrlId: file.signedUrlId,
            size: file.size,
            url: file.url,
            timeFromCreated: file.timeFromCreated,
            hasUnreadComments: file.hasUnreadComments,
            isApproved: isApproved,
            approves: approves
        };
    }

    newLink(link) {
        return {
            id: link.id,
            name: link.name,
            url: link.url,
            description: link.description,
            group: link.group,
            groups: link.groups,
            timeCreated: link.timeCreated,
            hasUnreadComments: link.hasUnreadComments,
        };
    }

    getGroupAndFileIndex(state, file) {
        let fileId;
        let groupId;
        state.project.files.groups.forEach(function (group, i) {
            group.files.forEach(function (f, j) {
                if (f.id === file?.id) {
                    groupId = i;
                    fileId = j;
                }
            })
        });

        return {
            groupId: groupId,
            fileId: fileId
        };
    }

    getGroupAndLinkIndex(state, link) {
        let linkId;
        let groupId;
        state.project.links.groups.forEach(function (group, i) {
            group.links.forEach(function (l, j) {
                if (l.id === link?.id) {
                    groupId = i;
                    linkId = j;
                }
            })
        });

        return {
            groupId: groupId,
            linkId: linkId
        };
    }

    onActionCreate = (action) => {
        const state = { ...this.state };

        let index = this.getGroupAndFileIndex(state, action.file);

        if (index.groupId !== undefined) {
            let file = state.project.files.groups[index.groupId].files[index.fileId];
            let approves = file.approves ? {...file.approves} : [];

            file = this.newFile(file, false, []);
            file.approves.push(action);
            file.approves = file.approves.concat(approves);

            state.project.files.groups[index.groupId].files[index.fileId] = file;
        }

        this.setState(state);
    }

    showCategories(toggleName) {
        let state = { ...this.state };
        switch (toggleName) {
            case 'linksToggle':
                state.showLinksCategory = !state.showLinksCategory;
                break;
            case 'attachmentsToggle':
                state.showFilesCategory = !state.showFilesCategory;
                break;
            default:
                break;
        }
        this.setState(state);

        let data = {
            showFiles: state.showFilesCategory,
            showLinks: state.showLinksCategory,
        }
        authApiService.put('/project/' + this.props.match.params.projectId + '/setting', data,
            (data, headers) => {

                state.showLinksCategory = data.showLinks;
                state.showFilesCategory = data.showFiles;
                this.setState(state);
            },
            (message, errors) => {
            });
    }
}


export default connect(
    (state) => ({
        profile: state.entities.profile,
        actionTypes: state.entities.enums.actionTypes,
        actionStatuses: state.entities.enums.actionStatues
    }),
    (dispatch) => ({
        profileFetched: (profile) => dispatch(profileFetched(profile)),
        actionTypesFetched: (actionTypes) => dispatch(actionTypesFetched(actionTypes)),
        actionStatusesFetched: (actionStatuses) => dispatch(actionStatusesFetched(actionStatuses)),
        projectFetched: (project) => dispatch(projectFetched(project)),
        actionsFetched: (actions) => dispatch(actionsFetched(actions)),
        actionUpdated: (action) => dispatch(actionUpdated(action)),
        membersFetched: (members) => dispatch(membersFetched(members))
    })
)(withRouter(BuilderPage));