import React, { Component } from 'react';
import ValidationErrors from '../../../../../../shared/field/errors/errors';
import Wrapper from '../../../../../../shared/field/wrapper';

class Link extends Component {
    render() {
        const {errors} = this.props;
        return (
            <Wrapper>
                <div className="form-label">Link *</div>
                <input type="text" 
                    name="link" 
                    placeholder="Link" 
                    value={this.props.link} 
                    className={(errors && errors.length > 0) ? "border-error" : ""} 
                    onChange={e => {this.props.onChange(e.target.value)}}/>
                <ValidationErrors errors={errors}/>
            </Wrapper>
        );
    }
}
 
export default Link;