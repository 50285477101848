import React, { Component } from 'react';
import timeService from "../../../../../services/timeService";
import jstz from 'jstz';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import {produce} from "immer";
import authApiService from "../../../../../services/authorizedApiHttpService";

class DocComment extends Component {

    state = {
        showMenu: false,
        showEdit: false,
        commentText: this.props.comment.comment
    }

    render() {
        return (
            <div className="doc-comment-box mt16" onMouseLeave={this.handleCommentMouseLeave}>
                <div className="dashboard-docs-text-grey">{this.props.comment.user.fullName} {this.getDate(this.props.comment.timeUpdated)}</div>
                {
                    this.state.showEdit ?
                        (<input className="doc-comment-edit"
                                type="text"
                                value={this.state.commentText}
                                onKeyDown={this.handleCommentEdit}
                                onChange={e => this.handleCommentChange(e.target.value)}></input>)
                        :
                        (<div>{this.state.commentText}</div>)
                }

                {this.subMenu()}
            </div>
        );
    }

    handleCommentEdit = (e) => {
        if (e.key === 'Enter') {
            this.setState({showEdit: false});

            let commentForm = {comment: this.state.commentText};
            authApiService.put(`/project/${this.props.projectId}/file/${this.props.comment.fileId}/comment/${this.props.comment.id}`, commentForm,
                (data, headers) => {
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        }
    }

    handleCommentChange = (value) => {
        this.setState({commentText: value})
    }

    handleCommentMouseLeave = (e) => {
        this.setState(produce(this.state, draftState => {
            draftState.showMenu = false;
        }));
    }

    subMenu = () => {
        const Toggle = React.forwardRef(({ children, onClick }, ref) => (
            <span className="no-outline no-select" ref={ref} onClick={e => { e.preventDefault(); onClick(e); }}>
                {children}
            </span>
        ));

        return this.props.readOnly ? ''
            : <ButtonGroup onClick={e => e.stopPropagation()} className="action-btn">
                <Dropdown className="sub-menu">
                    <Dropdown.Toggle as={Toggle}>
                        <span className="more-icon-wrpapper">
                            <span className="more-icon"></span>
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-right" show={this.state.showMenu}>
                        {this.menuItems().map((item, index) =>
                            <Dropdown.Item key={index} className="no-select" href="/#" onClick={e => { e.preventDefault(); item.handler(this.props.comment) }}>{item.title}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup >
    }

    menuItems = () => {
        return [
            { title: "Edit", handler: this.onEdit },
            { title: "Delete", handler: this.onDelete }
        ];
    }

    getDate = (timestamp) => {
        var date = timeService.convert2Timezone(timestamp + "+00:00", jstz.determine().name());
        var seconds = Math.floor((new Date() - date) / 1000);

        // above 1 month
        if (seconds > 2592000) {
            var curDate = new Date(timestamp);
            const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit' });
            const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(curDate);
            return `${month}/${day}/${year}`;
        }

        var interval = Math.floor(seconds / 86400);
        if (interval === 1) {
            return '1 day ago'
        }
        if (interval > 1) {
            return interval + " days ago";
        }

        interval = Math.floor(seconds / 3600);
        if (interval === 1) {
            return '1 hr ago'
        }
        if (interval > 1) {
            return interval + " hrs ago";
        }

        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " min ago";
        }

        return "1 min ago"
    }

    onEdit = (comment) => {
        this.setState({showEdit: !this.state.showEdit})
    }

    onDelete = (comment) => {
        authApiService.delete(`/project/${this.props.projectId}/file/${comment.fileId}/comment/${comment.id}`,
            (data, headers) => {
                this.props.commentDeleted(comment);
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }
}

export default DocComment;