import React, { Component } from 'react';
import userProfileService from '../../../../../../../../services/userProfileService';
import timeService from '../../../../../../../../services/timeService';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { produce } from 'immer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { commentUpdated, commentDeleted } from '../../../../../../../../models/store/comments/comments';
import authApiService from '../../../../../../../../services/authorizedApiHttpService';

class CommentItem extends Component {

    state = {
        showMenu: false
    }

    render() {
        return (
            <div className="comment-item-wrapper" onMouseLeave={this.handleCommentMouseLeave}>
                <div className="member-icon">
                    <span className="profile-img">
                        <span className="initials">{userProfileService.userInitials(this.props.comment.user.fullName)}</span>
                    </span>
                </div>

                <div className="comment-text-wrapper">
                    <div className="comment-text-header">
                        <span className="member-name">{this.props.comment.user.fullName}</span>
                        <span className="comment-time">{timeService.timeSince(this.props.comment.timeCreated) + " ago"}</span>
                    </div>
                    <div className="comment-text-content">{this.props.comment.comment}</div>
                </div>

                {this.subMenu()}
            </div>
        );
    }

    subMenu = () => {
        const Toggle = React.forwardRef(({ children, onClick }, ref) => (
            <span className="no-outline no-select" ref={ref} onClick={e => { e.preventDefault(); onClick(e); }}>
                {children}
            </span>
        ));

        return this.props.readOnly ? ''
            : <ButtonGroup onClick={e => e.stopPropagation()} className="action-btn">
                <Dropdown className="sub-menu">
                    <Dropdown.Toggle as={Toggle}>
                        <span className="more-icon-wrpapper">
                            <span className="more-icon"></span>
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-right" show={this.state.showMenu}>
                        {this.menuItems().map((item, index) =>
                            <Dropdown.Item key={index} className="no-select" href="/#" onClick={e => { e.preventDefault(); item.handler(this.props.comment) }}>{item.title}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup >
    }

    menuItems = () => {
        return [
            { title: "Edit", handler: this.onEdit },
            { title: "Delete", handler: this.onDelete }
        ];
    }

    handleCommentMouseLeave = (e) => {
        this.setState(produce(this.state, draftState => {
            draftState.showMenu = false;
        }));
    }

    onEdit = (comment) => {

    }

    onDelete = (comment) => {
        if (comment.actionId) {
            authApiService.delete(`/project/${this.props.match.params.projectId}/action/${comment.actionId}/comment/${comment.id}`,
                (data, headers) => {
                    this.props.commentDeleted({actionId: comment.actionId, comment: comment});
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        } else {
            authApiService.delete(`/project/${this.props.match.params.projectId}/file/${comment.fileId}/comment/${comment.id}`,
                (data, headers) => {
                    this.props.commentDeleted({actionId: comment.fileId, comment: comment});
                },
                (message, errors) => {
                    /* Show error popup here */
                });
        }
    }
}

export default connect(
    (state) => ({
        comments: state.entities.comments
    }),
    (dispatch) => ({
        commentUpdated: (comment) => dispatch(commentUpdated(comment)),
        commentDeleted: (comment) => dispatch(commentDeleted(comment))
    })
)(withRouter(CommentItem));