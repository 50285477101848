import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import CloseButton from '../button/close';
import urlService from '../../../../../../../services/urlService';
import ValidationErrors from '../../../../../shared/field/errors/errors';
import authApiService from '../../../../../../../services/authorizedApiHttpService';
import { withRouter } from 'react-router-dom';
import mixpanel from '../../../../../../../services/mixpanelService';
import { connect } from 'react-redux';

class Share extends Component {

    state = {
        form: {
            email: ''
        },
        errors: {
            form: {
                email: []
            },
            summary: ''
        }
    }

    render() {
        return (
            <Modal show={this.props.show}>
                <div className="modal-content share">
                    <Modal.Header>
                        <h5 className="modal-title">Share Project</h5>
                        <CloseButton onClose={this.props.onClose} />
                    </Modal.Header>

                    <Modal.Body className="flex-direction-column">
                        <label className="sub-title" htmlFor="project-link-input-readonly">Project link</label>

                        <div className="project-link-input-readonly-wrapper">
                            <table className="project-link-input-readonly-tbl">
                                <tbody>
                                    <tr>
                                        <td className="td-project-link-input">
                                            <input id="project-link-input-readonly" className="project-link no-outline" type="text" readOnly value={this.projectUrl()} />
                                        </td>
                                        <td className="td-control-btn">
                                            <button type="button" className="btn copy" onClick={this.handleCopy}>Copy</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <br />

                        <label className="sub-title" htmlFor="collaborator-email-input">Invite people to project</label>

                        <div className="project-link-input-readonly-wrapper">
                            <table className="project-link-input-readonly-tbl">
                                <tbody>
                                    <tr>
                                        <td className="td-project-link-input">
                                            <input id="collaborator-email-input" className="collaborator-email no-outline" onChange={e => { this.handleInvitationEmailEdit(e.target.value) }} type="email" placeholder="Email" />
                                        </td>
                                        <td className="td-control-btn">
                                            <button type="button" className="btn copy" onClick={this.handleInvite}>Invite</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ValidationErrors errors={this.state.errors.form.email} />
                        </div>
                    </Modal.Body>

                </div>
            </Modal>
        );
    }

    projectUrl = () => {
        return urlService.projectUrl(this.props.project.id);
    }

    handleCopy = (e) => {
        navigator.clipboard.writeText(this.projectUrl());
    }

    handleInvitationEmailEdit = (email) => {
        const state = { ...this.state };
        state.form.email = email;
        state.errors.form.email = [];
        state.errors.summary = '';
        this.setState(state);
    }

    handleInvite = (e) => {
        authApiService.post(`/project/${this.props.match.params.projectId}/collaborator/invite`, this.state.form,
            (data, headers) => {
                mixpanel.track("project.shared", {
                    projectId: this.props.match.params.projectId,
                    userId: this.props.profile.id,
                    toEmail: this.state.form.email
                });
                this.props.onClose();
            },
            (message, errors) => {
                const state = { ...this.state };
                state.errors.form = errors;
                state.errors.summary = (Object.keys(errors).length > 0) ? '' : message;
                this.setState(state);
            });
    }
}

export default connect(
    (state) => ({
        profile: state.entities.profile
    }),
    (dispatch) => ({})
)(withRouter(Share));