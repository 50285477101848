import React, {Component} from 'react';

class CardActivity extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="flex-start align-items-center">
                    <div className="board-card-popup-comment-user">{this.props.activity?.user?.fullName}</div>
                    <div className="board-card-popup-comment-time ml8"> {this.props.activity?.activity?.toLowerCase()} this card to {this.props.activity?.boardLaneName}</div>
                </div>
                <div className="board-card-popup-text">
                    {this.getDate(this.props.activity?.timeCreated)}
                </div>
                <br/>
            </React.Fragment>
        );
    }

    getDate = (timestamp) => {
        var date = new Date(timestamp);
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: false });
        const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }] = dateTimeFormat.formatToParts(date);
        return `${day} ${month} ${year}, ${hour}:${minute}`;
    }

}

export default CardActivity;