import React, { Component } from 'react';
import Block from '../../../../shared/ghost/block';

class NavigationGhosts extends Component {
    render() { 
        return (
            <nav className="navbar navbar-expand-lg project-list-header">
                <span className="navbar-brand project-list-label">My Projects</span>
                <div className="collapse navbar-collapse">

                    <div className="navbar-nav mr-auto mt-2 mt-lg-0 search-bar inline-block ">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text search-icon-wrapper" id="basic-addon1">
                                    <Block className="w24 h24"/>
                                </span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="form-inline">
                        <Block className="w111 h18 mr79"/>
                        <Block className="w111 h18 mr93"/>
                        <Block className="w146 h40"/>
                    </div>
                </div>
            </nav>
        );
    }
}
 
export default NavigationGhosts;