class LinkGroup {
    
    constructor(id=null, name=null, links=[]) {
        this.id = id;
        this.name = name;
        this.links = links;
    }

    assign(obj) {
        Object.assign(this, obj);
        return this;
    }
}

export default LinkGroup;