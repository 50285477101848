import React, { Component } from 'react';
import layoutList from "../../../../../markup/img/layout_list.png";
import layoutListActive from "../../../../../markup/img/layout_list_active.png";
import layoutGrid from "../../../../../markup/img/layout_grid.png";
import layoutGridActive from "../../../../../markup/img/layout_grid_active.png";
import commentIcon from "../../../../../markup/img/comment.png";
import arrowUp from "../../../../../markup/img/arrow-up.png";
import arrowDown from "../../../../../markup/img/arrow-down.png";
import plusIcon from "../../../../../markup/img/plus.png";
import fileService from "../../../../../services/fileService";
import authApiService from "../../../../../services/authorizedApiHttpService";
import linkService from "../../../../../services/linkService";
import Doc from "./doc"
import emptyDocs from "../../../../../markup/img/empty_docs.svg";
import docFile from "../../../../../markup/img/docs-file.svg";
import docLink from "../../../../../markup/img/docs-link.svg";
import FilePopup from "../../../../layout/main/project/builder/popup/file/filePopup";
import LinkPopup from "../../../../layout/main/project/builder/popup/link/linkPopup";
import LinkState from "../../../../../models/link/link";
import CloseButton from "../../project/builder/popup/button/close";
import DocUpload from "./docUpload";
import Info from "../../../shared/popup/info";
import subscriptionService from "../../../../../services/subscriptionService";

class Docs extends Component {

    constructor(props) {
        super(props);
        this.currentLink = new LinkState();

        this.notificationIds = new Set(this.props?.notification?.docIdList);
    }

    state = {
        mode: "LIST",
        docs: [],
        doc: null,
        sortBy: "",
        isSortAsc: false,
        showAddDoc: false,
        showSortMenu: false,
        showFilePopup: false,
        showLinkPopup: false,
        showStorageInfo: false,
        usedStorage: {}
    }

    render() {
        return this.state.doc === null ?
            (<React.Fragment>
                <div className="dashboard-docs-line"></div>
                <div className="dashboard-docs-top mt16">
                    <div className="dashboard-docs-header">
                        <div className="float-left">
                            <div className="dashboard-docs-header-text">Documents & links</div>
                            {this.state.usedStorage.priceIndex ?
                                <div className="dashboard-docs-header-desc">
                                    <span className="mr27">Memory usage: {subscriptionService.byteToMb(this.state.usedStorage.projectUsedStorageSize)} MB</span>
                                    <span className="mr27">Remaining memory {subscriptionService.getLeftMB(this.state.usedStorage)} MB</span>
                                    <span className="text-color-link cursor-pointer">Increase capacity</span>
                                </div>
                                :
                                <div></div>
                            }
                        </div>
                        {
                            this.state.docs.length === 0 ?
                                (<div></div>)
                                :
                                (
                                    <div className="float-right flex-start align-items-center mt8">
                                        <div className="docs-sort-by no-select" onClick={this.handleShowSortMenu}>
                                            Sort by: {this.state.sortBy + ' '}
                                            {this.getSortMenu()}
                                            <img src={this.state.isSortAsc ? arrowDown : arrowUp} alt="Asc"/>
                                        </div>
                                        {this.getDocsViewModeIcon()}
                                    </div>
                                )
                        }
                    </div>
                    <div className="dashboard-docs-line mt16"></div>

                    <div className="flex-start mt16 mb16">
                        <DocUpload action={this.handleShowFilePopup} type="FILE" text="File upload" desc="Document and file" />
                        <div className="ml16"></div>
                        <DocUpload action={this.handleShowLinkPopup} type="LINK" text="Link upload" desc="External reference" />
                    </div>

                    <Info show={this.state.showStorageInfo}
                          title="Storage Limit"
                          description="Please upgrade subscription to upload more files"
                          onOk={this.onInfoClose}
                          onClose={this.onInfoClose}
                          okButtonTitle="Ok" />
                </div>

                {this.state.docs.length === 0 ? this.getEmptyDocs() : this.getDocsAndLinks() }
                <FilePopup show={this.state.showFilePopup} project={this.props.project} onClose={this.handleCloseFilesPopup} onAddFiles={this.handleAddFiles} />
                <LinkPopup show={this.state.showLinkPopup} project={this.props.project} link={this.currentLink} onClose={this.handleLinkPopupClose} onAddLink={this.handleOnAddLink} />
            </React.Fragment>)
            :
            (<Doc doc={this.state.doc}
                  project={this.props.project}
                  handleBackToDocs={this.handleBackToDocs}
                  docDeleted={this.docDeleted}
                  getIcon={this.getIcon}
                  getKind={this.getKind}
                  getDate={this.getDate}
                  isExternal={this.props.isExternal} />);
    }

    async componentDidMount() {
        this.fetchStorageSize();
        this.fetchDocs();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.project.id !== this.props.project.id) {
            this.state.doc = null;
            this.fetchDocs();
            this.fetchStorageSize();
        }
    }

    handleShowSortMenu = () => {
        this.setState({showSortMenu: !this.state.showSortMenu});
    }

    getSortMenu = () => {
        return this.state.showSortMenu ?
            (<div className="doc-sort-menu">
                <button type="button" className="doc-context-menu-item btn" onClick={e => this.handleSortDocs('Name', true)}>
                    <span className="doc-add-menu-text">Name asc</span>
                </button>
                <button type="button" className="doc-context-menu-item btn" onClick={e => this.handleSortDocs('Name', false)}>
                    <span className="doc-add-menu-text">Name desc</span>
                </button>
                <button type="button" className="doc-context-menu-item btn" onClick={e => this.handleSortDocs('Date', true)}>
                    <span className="doc-add-menu-text">Date asc</span>
                </button>
                <button type="button" className="doc-context-menu-item btn" onClick={e => this.handleSortDocs('Date', false)}>
                    <span className="doc-add-menu-text">Date desc</span>
                </button>
            </div>)
            :
            ("")
    }

    handleSortDocs = (sortBy, isSortAsc) => {
        this.state.sortBy = sortBy;
        this.state.isSortAsc = isSortAsc;

        let docs = [...this.state.docs];
        if (sortBy === 'Name') {
            if (isSortAsc) {
                docs.sort((a, b) => (a.name > b.name) ? 1 : -1);
            } else {
                docs.sort((a, b) => (a.name < b.name) ? 1 : -1);
            }
        } else if (sortBy === 'Date') {
            if (isSortAsc) {
                docs.sort((a, b) => (a.timeUpdated > b.timeUpdated) ? 1 : -1);
            } else {
                docs.sort((a, b) => (a.timeUpdated < b.timeUpdated) ? 1 : -1);
            }
        }

        this.setState({docs: docs});
    }

    getAddButton = () => {
        return this.props.isExternal ? "" :
            this.state.showAddDoc ?
            (<div className="doc-add-menu">
                <div className="profile-close">
                    <CloseButton onClose={this.closeAddDoc} />
                </div>
                <button type="button" className="btn" onClick={this.handleShowFilePopup}>
                    <img className="doc-add-menu-icon mr8" src={docFile} width="20" height="20" alt="Download"/>
                    <span className="doc-add-menu-text">File upload</span>
                </button>
                <button type="button" className="btn" onClick={this.handleShowLinkPopup}>
                    <img className="doc-add-menu-icon mr8" src={docLink} width="20" height="20" alt="Download"/>
                    <span className="doc-add-menu-text">Link upload</span>
                </button>
            </div>)
            :
            (<div className="plus-btn no-select" onClick={this.handleAddDoc}>
                <img className="margin-auto no select" onClick={this.handleAddDoc} src={plusIcon} alt="Add"/>
            </div>)
    }

    onInfoClose = () => {
        this.setState({showStorageInfo: false});
    }

    closeAddDoc = () => {
        this.setState({showAddDoc: false})
    }

    handleShowFilePopup = () => {
        if (subscriptionService.canUploadFile(this.props.subscriptionData)) {
            this.showFilePopup(true);
        } else {
            this.setState({showStorageInfo: true});
        }
    }

    handleShowLinkPopup = () => {
        this.showLinkPopup(true);
    }

    handleLinkPopupClose = (e) => {
        this.showLinkPopup(false);
    }

    handleOnAddLink = (link, group) => {
        this.showLinkPopup(false);
        this.fetchDocs();
    }

    handleCloseFilesPopup = (e) => {
        this.showFilePopup(false);
    }

    handleAddFiles = (projectFilesGroup, fileGroup) => {
        this.showFilePopup(false);
        this.fetchDocs();
        this.fetchStorageSize();
    }

    showFilePopup(show) {
        this.setState({showFilePopup: show, showAddDoc: false})
    }

    showLinkPopup(show) {
        this.setState({showLinkPopup: show, showAddDoc: false})
    }

    handleAddDoc = () => {
        this.setState({showAddDoc: true})
    }

    getDocsViewModeIcon = () => {
        return this.state.mode === "LIST" ?
            (<React.Fragment>
                <img className="mr20" src={layoutListActive} width="24" height="24" alt="List active"/>
                <img className="cursor-pointer" width="24" height="24" onClick={this.handleDocViewClick} src={layoutGrid} alt="Grid"/>
            </React.Fragment>)
            :
            (<React.Fragment>
                <img className="cursor-pointer mr20" width="24" height="24" onClick={this.handleDocViewClick} src={layoutList} alt="List"/>
                <img src={layoutGridActive} width="24" height="24" alt="Grid active"/>
            </React.Fragment>);
    }

    handleDocViewClick = (e) => {
        this.setState({mode: this.state.mode === 'LIST' ? 'THUMBNAILS' : 'LIST'})
    }

    onDocumentClick = (doc) => {
        this.setState({doc: doc});
    }

    handleBackToDocs = () => {
        this.setState({doc: null});
    }

    docDeleted = (docId) => {
        let docs = [];
        for (let i = 0; i < Object.keys(this.state.docs).length; i++) {
            if (this.state.docs[i].id !== docId) {
                docs.push(this.state.docs[i]);
            }
        }

        this.setState({doc: null, docs: docs});
    }

    getEmptyDocs = () => {
        return (
            <div className="docs-empty">
                <img className="docs-empty-img" src={emptyDocs} alt="Empty"/>
                <div className="docs-empty-text">You don't have any files yet. Add files and Links using the buttons</div>
            </div>
        );
    }

    getDocsAndLinks = () => {
        return this.state.mode === "LIST" ?
            (<div className="dashboard-docs-list">
                <table className="w100p dashboard-docs">
                    <thead>
                    <tr className="docs-list-header">
                        <th className="w40"></th>
                        <th className="w30p">Name</th>
                        <th className="w30p">Description</th>
                        <th className="w20p">Date</th>
                        <th>Size</th>
                    </tr>
                    </thead>
                    <tbody className="dashboard-docs-tbody">

                    {this.state.docs.map(doc => (
                        <tr className="no-select" key={doc.id + doc.type}>
                            <td>
                                <div className={this.getDocNotificationClasses(doc)}></div>
                            </td>
                            <td className="dashboard-docs-text">
                                <img className="dashboard-docs-file cursor-pointer" onClick={() => this.onDocumentClick(doc)} src={this.getIcon(doc)} alt="Document"/>
                                <span className="ml10 cursor-pointer" onClick={() => this.onDocumentClick(doc)}>{doc.name}</span>
                            </td>
                            <td className="dashboard-docs-text-grey">{doc.description}</td>
                            <td className="dashboard-docs-text-grey">{this.getDate(doc.timeCreated)}</td>
                            <td className="dashboard-docs-text">{fileService.textSize(doc.originalSize)}</td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>)
            :
            (<div className="invis-scrollbar ml24 mr24">
                <div className="docs-text-grey-bold">Files</div>
                <div className="dashboard-doc-thumbnails mt12">
                    {this.state.docs.filter(doc => doc.type === 'FILE').map(doc => (
                        <div key={doc.id + doc.type} onClick={() => this.onDocumentClick(doc)} className="dashboard-doc-thumbnail-item no-select">
                            <div className="dashboard-doc-thumbnail-img-wrapper">
                                <img className="dashboard-doc-thumbnail-img" src={this.getIcon(doc)} alt="Card"/>
                            </div>
                            <div className="dashboard-doc-thumbnail-body">
                                <span className="doc-thumbnail-text">{doc.name}</span>
                                <div className="mr16">
                                    <img src={commentIcon} alt="Comments"/>
                                    <div className="docs-grid-comment-text">{doc.commentCount}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="docs-text-grey-bold mt44">Links</div>
                <div className="dashboard-doc-thumbnails mt12">
                    {this.state.docs.filter(doc => doc.type === 'LINK').map(doc => (
                        <div key={doc.id + doc.type} onClick={() => this.onDocumentClick(doc)} className="dashboard-doc-thumbnail-item no-select">
                            <div className="dashboard-doc-thumbnail-img-wrapper">
                                <img className="dashboard-doc-thumbnail-img" src={this.getIcon(doc)} alt="Card"/>
                            </div>
                            <div className="dashboard-doc-thumbnail-body">
                                <span className="doc-thumbnail-text">{doc.name}</span>
                                <div className="mr16">
                                    <img src={commentIcon} alt="Comments"/>
                                    <div className="docs-grid-comment-text">{doc.commentCount}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>);
    }

    getDocNotificationClasses = (doc) => {
        return this.notificationIds.has(doc.id) ? "notification-big no-select" : "no-select";
    }

    getDate = (timestamp) => {
        var date = new Date(timestamp);
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: false });
        const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }] = dateTimeFormat.formatToParts(date);
        return `${day} ${month} ${year}, ${hour}:${minute}`;
    }

    getIcon = (doc) => {
        if (doc.type === 'FILE') {
            if (/(\.jpg|\.jpeg|\.png|\.svg)$/.test(doc.name)) {
                return doc.url;
            }

            return fileService.fileIcon(doc.name);
        } else {
            return doc.image ? doc.image : linkService.getIcon(doc.url);
        }
    }

    getKind = (doc) => {
        return doc.type === 'FILE' ? doc.contentType : 'link';
    }

    fetchDocs = () => {
        authApiService.get(`/project/${this.props.project.id}/docs?external=${this.props.isExternal}`,
            (docs, headers) => {
                this.setState({docs: docs});
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }

    fetchStorageSize = () => {
        authApiService.get(`/project/${this.props.project.id}/used-storage`,
            (data, headers) => {
                this.setState({usedStorage: data});
            },
            (message, errors) => {
                /* Show error popup here */
            });
    }
}

export default Docs;