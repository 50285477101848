import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import CloseButton from '../../project/builder/popup/button/close';
import {withRouter} from 'react-router-dom';
import xClose from "../../../../../markup/img/x_close.png";
import authApiService from "../../../../../services/authorizedApiHttpService";
import CardActivity from './cardActivity'
import jstz from 'jstz';
import timeService from "../../../../../services/timeService";

class CardPopup extends Component {

    constructor() {
        super();
        this.textarea = React.createRef();
    }

    async componentDidUpdate() {
        if (this.state.cardId != this.props.card.id) {
            this.setState({cardId: this.props.card.id, description: this.props.card?.description})
            this.fetchCardComments();
            this.fetchCardActivity();
        }
    }

    state = {
        cardId: null,
        activity: [],
        comments: [],
        description: this.props?.card?.description,
        comment: '',
        editDescription: false
    }

    render() {
        return (
            <Modal className="request-subject edit-action" show={this.props.show}>
                <Modal.Header>
                    <div className="board-card-popup-header">{this.props.card?.title}</div>
                    <CloseButton onClose={this.props.popupClosed} />
                </Modal.Header>

                <Modal.Body className="flex-direction-column action-modal-body">

                    <div className="flex-start">
                        <div className="board-card-popup-text-grey">Create</div>
                        <div className="board-card-popup-text ml16">{this.getDate(this.props.card.timeCreated)}</div>
                    </div>
                    <div className="flex-start">
                        <div className="board-card-popup-text-grey">Author</div>
                        <div className="board-card-popup-text ml16">{this.props.card.user?.fullName}</div>
                    </div>
                    <br/>

                    <div>
                        <div className="board-card-popup-description-header">Description</div>
                        <br/>
                        {this.state.editDescription ?
                            <div className="board-card-popup-description-text">
                               <textarea className="board-card-detail-textarea"
                                      name="description"
                                      onChange={e => {
                                          this.onDescriptionChange(e.target.value)
                                      }}
                                      placeholder="Add a more detailed description..."
                                      defaultValue={this.props.card.description}></textarea>
                                <button type="button" className="btn" onClick={e => this.onSaveDescription()}>Save</button>
                                <img className="ml10 cursor-pointer"
                                     onClick={this.onCloseDescription}
                                     src={xClose}
                                     width="15" height="15" alt="Close" />
                            </div>
                            :
                            <div className={this.getDescriptionClasses()} onClick={this.onEditDescription}>{this.props.card.description ? this.props.card.description : "Edit description"}</div>
                        }
                    </div>

                    <br/>

                    <div className="board-card-popup-input-box"></div>
                    <input className="form-control board-add-card-input-desc"
                           type="text"
                           ref={this.textarea}
                           onChange={e => this.handleCommentTextChange(e.target.value)}
                           placeholder="+ Add a comment..."
                           onKeyDown={this.handleCommentSend}
                    />
                    <div className="board-card-popup-input-box"></div>

                    <br/>
                    {this.state.comments.map(commentItem => (
                        <div key={commentItem.id}>
                            <div className="flex-start align-items-center">
                                <div className="board-card-popup-comment-user">{commentItem.user.fullName}</div>
                                <div className="board-card-popup-comment-time ml8">{timeService.timeSince(commentItem.timeCreated) + " ago"}</div>
                            </div>
                            <div className="board-card-popup-text">{commentItem.comment}</div>
                            <br/>
                        </div>
                    ))}

                    <br/>

                    <div className="board-card-popup-description-header">Activity</div>
                    <div className="board-card-popup-input-box"></div>
                    {this.state.activity.map(activityItem => (
                        <CardActivity key={activityItem.id} activity={activityItem} />
                    ))}

                </Modal.Body>
            </Modal>
        );
    }

    getDescriptionClasses = () => {
        return "cursor-pointer board-card-popup-description-text " +
            (this.props.card.description?.length === 0
                    ? " docs-text-grey-bold"
                    : ""
            );
    }

    handleCommentTextChange = (comment) => {
        this.setState({comment: comment})
    }

    onDescriptionChange = (description) => {
        this.setState({description: description});
    }

    clearCommentTextarea = () => {
        this.setState({comment: ''});
        this.textarea.current.value = "";
    }

    handleCommentSend = (e) => {
        if (e.key === 'Enter') {
            authApiService.post(`/comment/CARD/${this.props.card.id}`, {comment: this.state.comment},
                (data, headers) => {
                    let comments = [data, ...this.state.comments];
                    this.setState({comments: comments});

                    this.clearCommentTextarea();
                },
                (message, errors) => {
                });
        }
    }

    onCloseDescription = () => {
        this.setState({editDescription: false});
    }

    onEditDescription = () => {
        this.setState({editDescription: true});
    }

    onSaveDescription = () => {
        let card = { ...this.props.card };
        card.description = this.state.description;

        authApiService.put(`/project/${this.props.projectId}/lane/${this.props.card.laneId}/card/${this.props.card.id}`, card,
            (data, headers) => {
                this.setState({editDescription: false});
                this.props.updateCardDescription(card);
            },
            (message, errors) => {
            });
    }

    fetchCardComments = () => {
        authApiService.get(`/comment/CARD/${this.props.card.id}`,
            (comments, headers) => {
                this.setState({comments: comments});
            },
            (message, errors) => {
            });
    }

    fetchCardActivity = () => {
        authApiService.get(`/card/${this.props.card.id}/activity`,
            (activity, headers) => {
                this.setState({activity: activity});
            },
            (message, errors) => {
            });
    }

    getDate = (timestamp) => {
        if (timestamp) {
            const date = new Date(timestamp);
            const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: false });
            const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }] = dateTimeFormat.formatToParts(date);
            return `${day} ${month} ${year}, ${hour}:${minute}`;
        }
    }
}

export default withRouter(CardPopup);