import React, { Component } from 'react';
import Content from '../../../../../../shared/wrapper/content';
import Header from './header';

class Category extends Component {
    render() {
        return (
            <Content>
                <Header category={this.props.category} readOnly={this.props.readOnly} onClickAddElement={this.props.onClickAddElement} />
                {this.props.children}
            </Content>
        );
    }
}

export default Category;