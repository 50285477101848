import { combineReducers } from 'redux';
import createActionReducer from './popup/createAction';
import editActionReducer from './popup/editAction';
import detailsActionReducer from './popup/detailsAction';
import linkDetailsReducer from './popup/linkDetails';

export default combineReducers({
    createAction: createActionReducer,
    editAction: editActionReducer,
    detailsAction: detailsActionReducer,
    linkDetails: linkDetailsReducer
});