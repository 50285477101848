import React, { Component } from 'react';
import authApiService from "../../../../../../../services/authorizedApiHttpService";
import GroupField from '../shared/group';
import LinkGroup from '../../../../../../../models/link/group/linkGroup';
import linkService from "../../../../../../../services/linkService";

class LinkPopupHeader extends Component {

    state = {
        group: {
            id: this.props.link.group.id,
            name: this.props.link.group.name,
            focused: false,
            list: this.props.link.groups
        },
        form: {}
    }

    render() {
        return (
            <table className="w100p">
                <tbody>
                <tr>
                    <td className="file-subject-td">
                        <div className="icon-column file-meta-center">
                            <img src={linkService.getIcon(this.props.link.url)} width="48" height="48" alt="Logo" />
                        </div>
                        <div className="meta">
                            <div className="days file-meta file-meta-center">{this.props.link.timeFromCreated}</div>
                        </div>
                    </td>
                    <td className="file-subject-td">
                        <div className="file-subject-name mb20">
                            <div className="modal-title" style={{wordBreak: "break-all"}}>{this.props.link.name}</div>
                            <div className="modal-title file-category">
                                <GroupField group={this.state.group}
                                            focused={this.state.group.focused}
                                            list={this.state.group.list}
                                            onGroupSelect={this.handleGroupSelect}
                                            onSearchByName={this.handleGroupSearchByName}
                                            onBecomeFocused={this.handleGroupBecomeFocused}
                                            onDidMount={this.handleGroupMounted}
                                            errors={[]} />
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }

    popupClosed = (description) => {
        let link = {
            id: this.props.link.id,
            url: this.props.link.url,
            name: this.props.link.name,
            group: {
                id: this.state.group.id,
                name: this.state.group.name
            },
            description: description
        }

        if (link.id === this.props.link.id &&
            link.url === this.props.link.url &&
            link.name === this.props.link.name &&
            link.description === this.props.link.description &&
            link.group.id === this.props.link.group.id &&
            link.group.name === this.props.link.group.name) {

            return;
        }

        authApiService.put('/project/' + this.props.projectId + '/link/' + this.props.link.id, link,
            (group, headers) => {
                this.props.onLinkGroupChange(link, group);
            },
            (message, errors) => {
            });
    }

    /* Handle Group changes */
    handleGroupMounted = () => {
        this.requestLinkGroups('');
    }

    handleGroupBecomeFocused = (focused) => {
        let state = { ...this.state };
        state.group.focused = focused;
        this.setState(state);
    }

    handleGroupSelect = (group) => {
        let state = { ...this.state };
        state.group.id = group.id;
        state.group.name = group.name;
        this.setState(state);
        this.requestLinkGroups(group.name);
    }

    handleGroupSearchByName = (searchName) => {
        let state = { ...this.state };
        state.group.id = null;
        state.group.name = searchName;
        this.setState(state);
        this.requestLinkGroups(searchName);
    }

    requestLinkGroups(searchName) {
        if (this.groupsSearchCancelToken) {
            this.groupsSearchCancelToken.cancel();
        }

        authApiService.get('/project/' + this.props.projectId + '/link/groups?s=' + (searchName ? searchName : ''),
            (groups, headers) => {
                let state = { ...this.state };
                state.group.list = groups.map(group => new LinkGroup().assign(group));
                this.setState(state);
            },
            (message, errors) => {
            },
            (cancelToken) => {
                this.groupsSearchCancelToken = cancelToken;
            });
    }
}

export default LinkPopupHeader;