import React, { Component } from 'react';
import Block from '../../../../shared/ghost/block';

class ProjectCardGhost extends Component {
    render() { 
        return (
            <div className="card project-card">
                <div className="profile-status table ghost"/>
                
                <Block className="w296 h120 r0"/>
                
                <div className="card-body ghost">
                    <h5 className="project-title">
                        <Block className="w100 h16"/>
                    </h5>

                    <Block className="w61 h8 mt12"/>
                    <Block className="w197 h8 mt24"/>
                    <Block className="w76 h8 mt8"/>
                    <br/>
                    <Block className="w76 h8 mt32"/>
                </div>
            </div>
        );
    }
}
 
export default ProjectCardGhost;