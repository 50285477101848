function getStatusClass(status) {
    if (!status) return 'not-started';
    switch (status.id) {
        case 1: return 'not-started';
        case 2: return 'in-progress';
        case 3: return 'on-hold';
        case 4: return 'complete';
        default: return 'not-started';
    }
}

function backgroundGradientClass(projectId) {
    if (projectId % 6 === 0) return "project-gradient-6";
    if (projectId % 5 === 0) return "project-gradient-5";
    if (projectId % 4 === 0) return "project-gradient-4";
    if (projectId % 3 === 0) return "project-gradient-3";
    if (projectId % 2 === 0) return "project-gradient-2";
    if (projectId % 1 === 0) return "project-gradient-1";
}

export default {
    getStatusClass,
    backgroundGradientClass
}