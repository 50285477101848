import pinterest from '../markup/img/link-icon-pinterest@2x.png';
import box from '../markup/img/link-icon-box@2x.png';
import miro from '../markup/img/link-icon-miro@2x.png';
import linkedIn from '../markup/img/link-icon-linkedin@2x.png';
import invision from '../markup/img/link-icon-invision@2x.png';
import dropbox from '../markup/img/link-icon-dropbox@2x.png';
import figma from '../markup/img/link-icon-figma@2x.png';
import coda from '../markup/img/link-icon-coda@2x.png';
import icloud from '../markup/img/link-icon-icloud@2x.png';
import onedrive from '../markup/img/link-icon-onedrive@2x.png';
import calendly from '../markup/img/link-icon-calendly@2x.png';
import googleSheet from '../markup/img/link-icon-google-sheet@2x.png';
import notion from '../markup/img/link-icon-notion@2x.png';
import slack from '../markup/img/link-icon-slack@2x.png';
import googleDoc from '../markup/img/link-icon-google-doc@2x.png';
import googleDrive from '../markup/img/link-icon-google-drive@2x.png';
import airTable from '../markup/img/link-icon-airtable@2x.png';
import unknown from '../markup/img/link-icon-unknown@2x.png';

// Notion   https://www.notion.so/
// Trello	https://trello.com/
// Invision	https://projects.invisionapp.com
// Figma	https://www.figma.com/
// Miro	https://miro.com/
// Notion	https://www.notion.so
// Coda	https://coda.io
// Airtable	https://airtable.com/
// Google Doc	https://docs.google.com/document/
// Google Sheet	https://docs.google.com/spreadsheets/
// Pinterest	https://www.pinterest.com
// Dropbox	https://www.dropbox.com
// Box	https://www.box.com/

function getIcon(url) {
    if (/pinterest\.com/.test(url))
        return pinterest;
    if (/miro\.com/.test(url))
        return miro;
    if (/linkedin\.com/.test(url))
        return linkedIn;
    if (/invisionapp\.com/.test(url))
        return invision;
    if (/dropbox\.com/.test(url))
        return dropbox;
    if (/box\.com/.test(url))
        return box;
    if (/figma\.com/.test(url))
        return figma;
    if (/coda\.com/.test(url))
        return coda;
    if (/icloud\.com/.test(url))
        return icloud;
    if (/onedrive\.live\.com/.test(url))
        return onedrive;
    if (/calendly\.com/.test(url))
        return calendly;
    if (/docs\.google\.com\/spreadsheets/.test(url))
        return googleSheet;
    if (/notion\.so/.test(url))
        return notion;
    if (/slack\.com/.test(url))
        return slack;
    if (/docs\.google\.com\/document/.test(url))
        return googleDoc;
    if (/drive\.google\.com/.test(url))
        return googleDrive;
    if (/airtable\.com/.test(url))
        return airTable;
    return unknown;
}

export default {
    getIcon
}