import React, { Component } from 'react';
import Error from '../error';
import errorIcon2x from '../../../../../markup/img/img-500-error@2x.png';

class ErrorServer extends Component {
    render() {
        return (
            <Error img={errorIcon2x} title="Server error" subTitle="Something went wrong, please try again later or contact support" />
        );
    }
}

export default ErrorServer;