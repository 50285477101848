import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import CloseButton from '../button/close';
import { popupClosed, popupExited } from '../../../../../../../models/store/popup/createAction';
import { connect } from 'react-redux';
import TextAreaField from '../shared/textAreaField';
import RequestSubject from '../shared/requestSubject';
import SubjectFile from '../shared/subjectFile';
import MemberSearch from '../shared/memberSearch';
import Members from './members';
import { produce } from 'immer';
import authApiService from '../../../../../../../services/authorizedApiHttpService';
import { withRouter } from 'react-router-dom';
import { actionCreated } from '../../../../../../../models/store/actions/actions';
import mixpanel from '../../../../../../../services/mixpanelService';

class CreateActionPopup extends Component {

    state = {
        form: {
            additionalInstructions: "",
            fileId: null,
            memberIds: []
        },
        errors: {
            form: {
                additionalInstructions: [],
                fileId: [],
                memberIds: []
            },
            summary: ""
        },
        members: []
    }

    render() {
        return (
            <Modal show={this.props.popup.show} onExited={this.props.popupExited}>
                <div className="modal-content request-subject">
                    <Modal.Header>
                        <h5 className="modal-title">Request {this.actionTypeName()}</h5>
                        <CloseButton onClose={this.props.popupClosed} />
                    </Modal.Header>

                    <Modal.Body className="flex-direction-column">
                        <TextAreaField name="additional-instructions"
                            label="Additional instructions"
                            placeholder="Your comments"
                            text=""
                            required={true}
                            onChange={this.handleAdditionalInstructionsChange}
                            errors={this.state.errors.form.additionalInstructions} />

                        <MemberSearch members={this.state.members} label="Members" placeholder="Type member name to search" onSelect={this.handleOnMemberSelect} />
                        <Members members={this.state.members} onDelete={this.handleOnMemberDelete} />

                        {this.subject()}
                    </Modal.Body>

                    <Modal.Footer className="form-footer flex-start">
                        <button type="button" className="btn" onClick={this.handleSentRequest}>Send request</button>
                        <button type="button" className="btn btn-outline-grey" data-dismiss="modal" onClick={this.props.popupClosed}>Cancel</button>
                    </Modal.Footer>
                </div>
            </Modal >
        );
    }

    actionTypeName = () => {
        switch (this.props.popup.actionType.name) {
            case 'APPROVAL': return 'approval';
            case 'FEEDBACK': return 'feedback';
            case 'FILE_UPLOAD': return 'file upload';
            default: return '';
        }
    }

    subject = () => {
        return this.props.popup.file
            ? <RequestSubject label={this.requestSubjectLabel()}>
                <SubjectFile file={this.props.popup.file} />
            </RequestSubject>
            : "";
    }

    requestSubjectLabel = () => {
        return 'Subject ' + (this.props.popup.link ? 'link' : 'file');
    }

    handleSentRequest = () => {
        let form = { ...this.state.form };
        form.typeId = this.props.popup.actionType.id;
        form.fileId = this.props.popup.file ? this.props.popup.file.id : null;
        authApiService.post(`/project/${this.props.match.params.projectId}/action`, form,
            (action, headers) => {
                mixpanel.track(this.props.popup.actionType.name.toLowerCase() + ".action.created", {
                    actionId: action.id,
                    projectId: action.projectId,
                    userId: action.userId,
                    typeId: action.typeId,
                    timeCreated: action.timeCreated,
                    fileId: (action.file ? action.file.id : null)
                });
                this.props.onActionCreate(action);
                this.props.actionCreated(action);
                this.props.popupClosed();
            },
            (message, errors) => {
                this.setState(produce(this.state, draftState => {
                    draftState.errors.form = errors;
                    draftState.errors.form.summary = (Object.keys(errors).length > 0) ? "" : message;
                }));
            },
            (cancelToken) => {
                this.cancelToken = cancelToken;
            });
    }

    handleAdditionalInstructionsChange = (text) => {
        this.setState(produce(this.state, draftState => {
            draftState.form.additionalInstructions = text;
            draftState.errors.form.additionalInstructions = [];
            draftState.errors.form.summary = "";
        }));
    }

    handleOnMemberDelete = (member) => {
        this.setState(produce(this.state, draftState => {
            draftState.members = this.state.members.filter(m => m.id !== member.id);
            draftState.form.memberIds = draftState.members.map(m => m.id);
        }));
    }

    handleOnMemberSelect = (member) => {
        this.setState(produce(this.state, draftState => {
            draftState.members.push(member);
            draftState.form.memberIds = draftState.members.map(m => m.id);
        }));
    }
}

export default connect(
    (state) => ({
        popup: state.entities.popups.createAction
    }),
    (dispatch) => ({
        popupClosed: () => dispatch(popupClosed()),
        popupExited: () => dispatch(popupExited()),
        actionCreated: (action) => dispatch(actionCreated(action))
    })
)(withRouter(CreateActionPopup));