import React, { Component } from 'react';
import Category from '../category';
import { connect } from 'react-redux';
import Action from './action';
import { withRouter } from 'react-router-dom';
import objectService from '../../../../../../../../../services/objectService';

class Actions extends Component {

    render() {
        let category = {
            id: "actions",
            title: "Actions",
            description: "These are pending actions (approval or feedback) regarding specific files. These are added on each file settings."
        };

        return (
            <Category category={category} readOnly={this.props.readOnly} onClickAddElement={this.props.onClickAddElement}>
                <div className="actions">
                    {this.actions()}
                </div>
            </Category>
        );
    }

    actions = () => {
        return this.props.actions.length
            ? this.props.actions.map(action =>
                <Action action={action} key={action.id} readOnly={this.props.readOnly} />
            )
            : "";
    }
}

export default connect(
    (state) => ({
        actions: objectService.idObjectToArray(state.entities.actions)
    }),
    (dispatch) => ({

    })
)(withRouter(Actions));