import React, {Component} from 'react';
import logo2x from "../../../../markup/img/logo@2x.png";
import logo3x from "../../../../markup/img/logo@3x.png";

class Logo extends Component {

    render() {
        return (
            <a className="wrap-link" href="/">
                {this.getProjectLogo()}
            </a>
        );
    }

    getProjectLogo = () => {
        return this.props.projectLogo ?
            <img className="logo" src={this.props.projectLogo} width="132" height="44" alt="Pantram"/>
            :
            this.props.showPantramLogo ? <img className="logo" src={logo2x}
                 srcSet={`${logo2x} 2x, ${logo3x} 3x`} width="132" height="44" alt="Pantram"/> : '';
    }
}

export default Logo;