import React, { Component } from 'react';

class Close extends Component {
    render() {
        return (
            <button type="button" className="close" onClick={e => this.props.onClose(e)}>
                <span aria-hidden="true">&times;</span>
            </button>
        );
    }
}
 
export default Close;