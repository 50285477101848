
class SignedUrl {

    constructor(name = null, size = 0, contentType = null) {
        this.name = name;
        this.size = size;
        this.contentType = contentType;
    }
}

export default SignedUrl;