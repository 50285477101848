import store from '../models/store/store';

function statusName(statusId) {
    let statusName = store.getState().entities.enums.actionStatuses
        .filter(status => status.id === statusId).shift().name;

    switch (statusName) {
        case "OPEN": return "Open";
        case "COMPLETE": return "Complete";
        case "ARCHIVED": return "Archived";
        default: return "";
    }
}

function statusId(statusName) {
    let statusId = store.getState().entities.enums.actionStatuses
        .filter(status => status.name === statusName).shift().id;
    return statusId;
}

function typeName(typeId) {
    if (!typeId) return "";

    let typeName = store.getState().entities.enums.actionTypes
        .filter(type => type.id === typeId).shift().name;

    switch (typeName) {
        case "APPROVAL": return "Approval";
        case "FEEDBACK": return "Feedback";
        case "FILE_UPLOAD": return "File upload";
        default: return "";
    }
}

function isComplete(statusId) {
    if (!statusId) return false;
    return store.getState().entities.enums.actionStatuses
        .filter(status => status.id === statusId).shift().name === "COMPLETE";
}

export default {
    statusName,
    statusId,
    typeName,
    isComplete
};