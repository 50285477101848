import React, { Component } from 'react';

class Header extends Component {
    render() {
        return (
            <div className={this.classNames()}>
                <h4 className="title">{this.props.subcategory.name}</h4>
                <div className="controls-wrapper flex-direction-row">
                    <span className="delete active" onClick={e => this.props.onDelete(this.props.subcategory)}></span>
                </div>
            </div>
        );
    }

    classNames = () => {
        let classes = ['group-header-wrapper', 'flex-space-between'];
        if (this.props.readOnly)
            classes.push('read-only');
        return classes.join(' ');
    }
}

export default Header;