import React, {Component} from 'react';
import logo2x from '../../../../markup/img/logo-footer@2x.png'
import logo3x from '../../../../markup/img/logo-footer@3x.png'

class Footer extends Component {

    render() {
        return (
            <div className="page-footer">
                <div className="footer-centered">
                    <span className="powered-by">Powered by:</span>
                    <a className="wrap-link" href="/">
                        <img className="logo-footer" src={logo2x}
                             srcSet={`${logo2x} 2x, ${logo3x} 3x`} width="72" height="24" alt="Pantram"/>
                    </a>
                </div>
            </div>
        );
    }
}

export default Footer;