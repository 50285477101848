import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'profile',
    initialState: {},
    reducers: {
        profileFetched: (profile, action) => {
            return Object.assign(profile, action.payload);
        }
    }
});

export default slice.reducer;
export const { profileFetched } = slice.actions;