import React, {Component} from 'react';
import FieldWrapper from '../../../shared/field/wrapper';

class Title extends Component {

    render() {
        return (
            <FieldWrapper>
                <h3 className="form-title">{this.props.title}</h3>
            </FieldWrapper>
        );
    }
}

export default Title;