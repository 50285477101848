import React, { Component } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import downloadIcon2x from '../../../../../../../../../markup/img/icon-small-download@2x.png';
import downloadIcon3x from '../../../../../../../../../markup/img/icon-small-download@3x.png';
import actionService from '../../../../../../../../../services/actionService';
import { withRouter } from 'react-router-dom';
import authApiService from '../../../../../../../../../services/authorizedApiHttpService';
import fileService from '../../../../../../../../../services/fileService';
import { connect } from 'react-redux';
import { actionArchived } from '../../../../../../../../../models/store/actions/actions';
import { popupShown as editPopupShown } from '../../../../../../../../../models/store/popup/editAction';

class Action extends Component {

    render() {
        return (
            <div className={this.actionClasses(this.props.action.statusId)} key={this.props.action.id}>
                <div className="status" />
                <div className="title">{this.actionTitle(this.props.action.typeId)}</div>
                <div className="description">{this.props.action.additionalInstructions}</div>

                <div className="action-footer">
                    {this.downloaLinkContent(this.props.action.file)}
                    {this.actionButtons()}
                </div>

                {this.subMenu()}

            </div>
        );
    }

    actionButtons = () => {
        if (this.props.readOnly) {
            return <div className="action-buttons-wrapper flex-end" />;
        } else {
            return <div className="action-buttons-wrapper flex-end">
                <button type="button" className={this.commentsButtonClasses()} data-dismiss="modal" onClick={e => this.hadleClickCommentsButton(e, this.props.action)}>Comments</button>
                <button type="button" className={this.actionButtonClasses()} onClick={e => this.hadleClickActionButton(e, this.props.action)}>{this.actionButtonName(this.props.action.typeId)}</button>
            </div>;
        }
    }

    hadleClickCommentsButton = (e, action) => {
        if (!e.target.classList.contains("disabled")) {
            this.props.editPopupShown(action);
        }
    }

    hadleClickActionButton = (e, action) => {
        if (!e.target.classList.contains("disabled")) {
            this.props.editPopupShown(action);
        }
    }

    actionButtonClasses = () => {
        return "btn action" +
            (actionService.isComplete(this.props.action.statusId)
                ? " disabled"
                : ""
            );
    }

    commentsButtonClasses = () => {
        return "btn action bordered" +
            (actionService.isComplete(this.props.action.statusId)
                ? " disabled"
                : ""
            );
    }

    subMenu = () => {
        const Toggle = React.forwardRef(({ children, onClick }, ref) => (
            <span className="no-outline no-select" ref={ref} onClick={e => { e.preventDefault(); onClick(e); }}>
                {children}
            </span>
        ));

        return this.props.readOnly ? ''
            : <ButtonGroup onClick={e => e.stopPropagation()} className="action-btn">
                <Dropdown className="sub-menu">
                    <Dropdown.Toggle as={Toggle}>
                        <span className="more-icon"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-right">
                        {this.subMenuItems().map((item, index) =>
                            <Dropdown.Item key={index} className="no-select" href="/#" onClick={e => { e.preventDefault(); item.handler(this.props.action) }}>{item.title}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup >
    }

    subMenuItems = () => {
        let items = [
            { title: "Edit", handler: this.handleEdit }
        ];

        /* If Builder/View page, than show request menu items */
        if (this.props.location.pathname.startsWith('/builder/project/') || this.props.location.pathname.startsWith('/project/')) {
            let project = this.props.projects[this.props.match.params.projectId];
            if (project && project.ownerId === this.props.profile.id) {
                items.push({ title: "Move to archive", handler: this.handleMoveToArchive });
            }
        }
        return items;
    }

    handleEdit = (action) => {
        this.props.editPopupShown(action);
    }

    handleMoveToArchive = (action) => {
        let statusId = actionService.statusId("ARCHIVED");
        authApiService.put(`/project/${this.props.match.params.projectId}/action/${action.id}/status/${statusId}`, null,
            (response, headers) => {
                this.props.actionArchived(action);
            },
            (message, errors) => {
                /* Show error popup with some message */
            });
    }

    downloaLinkContent = (file) => {
        if (file) {
            return <div className="download-link-wrapper flex-start" onClick={e => this.handleFileDownload(file)}>
                <span className="download">
                    <img className="download-img" src={downloadIcon2x} srcSet={`${downloadIcon2x} 2x, ${downloadIcon3x} 3x`} width="16" height="16" alt="Download" />
                    <span className="file-name">{file.name}</span>
                </span>
            </div>;
        } else {
            return <div className="download-link-wrapper flex-start" />;
        }
    }

    actionClasses = (statusId) => {
        return "action-item " + (actionService.statusName(statusId) === "Open" ? "green" : "orange");
    }

    actionButtonName = (typeId) => {
        switch (actionService.typeName(typeId)) {
            case "Approval": return "Approve";
            case "Feedback": return "Provide feedback";
            case "File upload": return "Upload file";
            default: return "";
        }
    }

    actionTitle = (typeId) => {
        return "Needs " + actionService.typeName(typeId);
    }

    handleFileDownload = (file) => {
        authApiService.get(file.url,
            (download, headers) => {
                fileService.downloadFile(download.signedUrl, download.fileName);
            },
            (message, errors) => {
                /* Show error popup with some message */
            },
            (cancelToken) => { });
    }
}

export default connect(
    (state) => ({
        profile: state.entities.profile,
        projects: state.entities.projects,
        editActionPopup: state.entities.popups.editAction
    }),
    (dispatch) => ({
        editPopupShown: (action) => dispatch(editPopupShown(action)),
        actionArchived: (action) => dispatch(actionArchived(action))
    })
)(withRouter(Action));