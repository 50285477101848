import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'linkDetails',
    initialState: {
        show: false,
        link: null
    },
    reducers: {
        openPopup: (popup, action) => {
            popup.link = action.payload.link;
            popup.show = true;
            return popup;
        },
        popupShown: (popup, action) => {
            popup.show = true;
            popup.link = action.payload;
            return popup;
        },
        popupClosed: (popup, action) => {
            popup.show = false;
            return popup;
        },
        popupExited: (popup, action) => {
            popup.link = null;
            return popup;
        },
        popupUpdated: (popup, action) => {
            popup.link = action.payload;
            return popup;
        }
    }
});

export default slice.reducer;
export const { openPopup, popupShown, popupClosed, popupExited, popupUpdated } = slice.actions;