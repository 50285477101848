import React, { Component } from 'react';
import Layout from '../layout/main/layout';
import NavigationBar from '../layout/main/project/list/navigation/navigationBar';
import CreateProjectPopup from '../layout/main/project/list/popup/createProject';
import ProjectGrid from '../layout/main/project/list/grid/grid';
import authApiService from '../../services/authorizedApiHttpService';
import SearchBy from '../../models/searchBy';
import GridPlaceholder from '../layout/main/project/list/grid/placeholder';
import ProjectDeletePopup from '../layout/main/project/builder/popup/confirmation/projectDeletePopup';
import ProjectsGhosts from '../layout/main/project/list/ghosts/projectsGhosts';
import { connect } from 'react-redux';
import { profileFetched } from '../../models/store/profile/profile';
import mixpanel from '../../services/mixpanelService';
// import Dashboard from '../layout/main/dashboard/dashboard';

class ProjectsPage extends Component {

    constructor() {
        super();
        this.loading = true;
        this.searchFilters = {
            text: null,
            by: SearchBy.creationDate
        };
        this.fetchProjectsCancelToken = null;
    }

    state = {
        showCreateProjectPopup: false,
        showDeleteProjectPopup: false,
        projectToDelete: { id: 0, name: '' },
        projects: []
    }

    async componentDidMount() {
        this.loading = true;
        authApiService.get('/user/profile',
            (profile, headers) => {
                this.props.profileFetched(profile);
                this.fetchProjects(this.searchFilters);

                /* Identify user in Mixpanel */
                mixpanel.identifyUser(profile);
            },
            (message, errors) => {
                this.loading = false;
            },
            (cancelToken) => {
                this.fetchProjectsCancelToken = cancelToken;
            });
    }

    render() {
        return (
            <React.Fragment>
                {this.getContentBlocks()}
                <CreateProjectPopup show={this.state.showCreateProjectPopup} onClose={this.handleModalCloseCreateProject} onProjectCreated={this.handleProjectCreated} />
                <ProjectDeletePopup show={this.state.showDeleteProjectPopup} project={this.state.projectToDelete} onConfirm={this.handleProjectDeleteConfirm} onCancel={this.handleProjectDeleteCancel} onClose={this.handleProjecDeleteClose} />
            </React.Fragment>
        );
    }

    getContentBlocks() {
        if (this.loading) {
            /* Show ghosts */
            return <Layout>
                <ProjectsGhosts />
            </Layout>;
        } else if (this.state.projects.length === 0 && this.isEmptySearchString()) {
            return <Layout>
                <GridPlaceholder onClickCreateProject={this.handleNavClickCreateProject} />
            </Layout>;
        } else {
            return <Layout>
                <NavigationBar onSearch={this.handleSearch} onSearchTextChange={this.handleSearchTextChange} onSortOptionChange={this.handleSortOptionChange} onClickCreateProject={this.handleNavClickCreateProject} onClickCancelCreateProject={this.handleClickCancelCreateProject} />
                <ProjectGrid projects={this.state.projects} onDelete={this.handleProjectDelete} />
            </Layout>;
            // return <Dashboard projects={this.state.projects} onClickCreateProject={this.handleNavClickCreateProject} onClickCancelCreateProject={this.handleClickCancelCreateProject} />;
        }
    }

    isEmptySearchString() {
        return (this.searchFilters.text && 0 === this.searchFilters.text) || !this.searchFilters.text;
    }

    handleSearch = (text) => {
        this.setSeachText(text);
        this.fetchProjects();
    }

    handleSearchTextChange = (text) => {
        this.setSeachText(text);
    }

    setSeachText(text) {
        this.searchFilters.text = text;
        this.fetchProjects();
    }

    handleNavClickCreateProject = () => {
        this.setShowCreateProjectPopup(true);
    }

    handleModalCloseCreateProject = () => {
        this.setShowCreateProjectPopup(false);
    }

    handleClickCancelCreateProject = () => {
        this.setShowCreateProjectPopup(false);
    }

    setShowCreateProjectPopup(show) {
        let { showCreateProjectPopup, searchFilters, projects } = this.state;
        showCreateProjectPopup = show;
        this.setState({ showCreateProjectPopup, searchFilters, projects });
    }

    handleProjectCreated = (project) => {
        this.fetchProjects();
    }

    handleProjectDelete = (project) => {
        let state = { ...this.state };
        state.showDeleteProjectPopup = true;
        state.projectToDelete = project;
        this.setState(state);
    }

    handleProjectDeleteConfirm = (project) => {
        authApiService.delete(`/project/${project.id}`,
            (data, headers) => {
                let state = { ...this.state };
                state.projects = state.projects.filter(p => p.id !== project.id);
                state.showDeleteProjectPopup = false;
                this.setState(state);
                this.showDeleteProjectPopup(false);
            },
            (message, errors) => {
                /* Show popup with error */
                this.showDeleteProjectPopup(false);
            });
    }

    handleProjectDeleteCancel = (e) => {
        this.showDeleteProjectPopup(false);
    }

    handleProjecDeleteClose = (e) => {
        this.showDeleteProjectPopup(false);
    }

    handleSortOptionChange = (searchBy) => {
        this.searchFilters.by = searchBy;
        this.fetchProjects();
    }

    fetchProjects = () => {
        if (this.fetchProjectsCancelToken) {
            this.fetchProjectsCancelToken.cancel();
        }
        authApiService.post('/projects', this.searchFilters,
            (projects, headers) => {
                this.loading = false;
                const state = { ...this.state };
                state.projects = projects;
                this.setState(state);
            },
            (message, errors) => {
                this.loading = false;
            },
            (cancelToken) => {
                this.fetchProjectsCancelToken = cancelToken;
            });
    }

    showDeleteProjectPopup(show) {
        let state = { ...this.state };
        state.showDeleteProjectPopup = show;
        this.setState(state);
    }
}


export default connect(
    (state) => ({
        profile: state.entities.profile
    }),
    (dispatch) => ({
        profileFetched: (profile) => dispatch(profileFetched(profile))
    })
)(ProjectsPage);