import React, { Component } from 'react';

class Error extends Component {
    render() {
        return (
            <div className="server-error-wrapper">
                <img className="error-img" src={this.props.img} width="447" height="306" alt="Forbidden" />
                <div className="title">{this.props.title}</div>
                <div className="sub-title">{this.props.subTitle}</div>
            </div>
        );
    }
}

export default Error;